export const URL = 'https://www.crafterspro.net/cricut/api';
export const DOWNLOAD_URL = 'https://www.crafterspro.net/downloads';
export const STRIPE_TEST_KEY = 'pk_live_51I9GygGm0GHBGwjayEaIrR0MHraXWypxBzTd7dToRl2RcGntZJknHXPrudRCr1U99jM74cV9VOQG3ir1Z4cxtbuO00WHJNN8XG';
export const STRIPE_TEST_KEY_LIVEWORKSHOP = 'pk_live_51MjRF1HPrzGTzyPYfLy1ZGp6Nief0pNFnZuZEwAl8oF4Z9yIyMj16d0ouAcchcjVFHqhPCL2i290QMQNMHqBdSMq00IXm6NdGv';
//export const STRIPE_TEST_KEY_LIVEWORKSHOP = 'pk_test_51MjRF1HPrzGTzyPYiyNVUTpRIu8yiM2uDW9yiGfOlLeXTxwgp5skRBfqBjYqrdwXKzFqzBuyeTGX8txTl8rtQTIN00AyGqRdmm';
//export const STRIPE_TEST_KEY_EBOOK = 'pk_test_51NE39xKL7Q2I0W0Mtj5xqXxu1e7OaShSmvj4hyi0XzmHHSab5YA9AEfcsFu1CkZ2ww635o9pRmb2AsUa46l6O5ef00izFdlXVd';
export const STRIPE_TEST_KEY_EBOOK = 'pk_live_51NE39xKL7Q2I0W0Md5hDnEv3bxoPdJYiOOxP2yKIcCvaXIkyflJiYP2WHy057EbvDhbgp5gS2H3Ayacw4MtSAO3n000pnKEDJF';

// export const URL = 'http://206.189.236.226/cricut/api';
// export const DOWNLOAD_URL = 'http://206.189.236.226/downloads';
// export const STRIPE_TEST_KEY = 'pk_test_51I9GygGm0GHBGwja2T85dlcavOakZxZQf18jPijIQxSS6ENeIxySCFT03m4VZm98RgdVu16Hts5j2IXcri8ZLl5200aWbhRh7L';

// export const PAYPAL_CLIENT_ID_ONE = 'AdPZN7kze-sOfMSAuE2RA9yhva7ki_2GXEwPtUy78o2_gM6L1XOezADQHbFMigj0vnvDcdPesWkTiwQS';
// export const PAYPAL_CLIENT_ID_SUB = 'AdPZN7kze-sOfMSAuE2RA9yhva7ki_2GXEwPtUy78o2_gM6L1XOezADQHbFMigj0vnvDcdPesWkTiwQS';
// export const PAYPAL_PLAN_ID = 'P-5K060137RD844273VMDTM64Q';

export const PAYPAL_CLIENT_ID_ONE = "AY4GTIcnEszB9Kufn_KxMZKX7NEN4nQJ-cY9pSzYFlKzphXzvckLs1g5W8tGsvr_0g7RcyFv1BfDEeMo";
export const PAYPAL_CLIENT_ID_SUB = "AX-rjc2wCh9OCAmWwr6jRn10YnseYk68RCZsvr9YW944Df1gGVmEuUnG0ADUY_Ff3N9P0lxtoeUf9rMT";
export const PAYPAL_PLAN_ID = 'P-169517206M788112NMDW5JYY';
export const META_PIXEL_ID = '1732144310339339';