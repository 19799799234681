import { combineReducers, createStore } from 'redux';
import { appReducer } from './redux/reducers/index';

const reducer = combineReducers({
  app: appReducer
});

const store = createStore(reducer);

export default store;
