import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Modal } from 'antd';

import Layout from './Layout';

const Tutorial = () => {
    const [visible, setVisible] = useState(false);

    const openModal = () => {
        setVisible(true);
    }

    return (
        <Layout>
			<div role="main" className="main tutorial" style={{ paddingTop: 140 }}>
                <div className="container">
                    <div className="row mb-5">
                        <h2 className="font-weight-bold text-9 center primary-color font-primary-bold">Tutorial</h2>
                    </div>
                    <div className="row" style={{ backgroundImage: 'url(/assets/img/tutorial-back.webp)'}}>
                        <div className="col-6 left-container">
                            <div>
                                <img src="/assets/img/text.webp" className="img1" />
                            </div>
                            <div>
                                <img src="/assets/img/watchnow.png" className="img2" />
                            </div>
                        </div>
                        <div className="col-6">
                            <img src="/assets/img/video-thumb.webp" className="img3" onClick={openModal} />
                        </div>
                    </div>
                </div>
                <Modal
                    visible={visible}
                    footer={null}
                    onCancel={() => { setVisible(false) }} 
                    centered
                    width={'80%'}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <ReactPlayer className="Video-player1" url={"https://crafterspro.net/cricut/tutorials/tutorial_new.m4v"} width="100%" height="100%" loop={true} controls={true}/>
                </Modal>
            </div>
        </Layout>
    );
}

export default Tutorial;