import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Layout from './Layout';
import { STRIPE_TEST_KEY } from '../config/api';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SplitFormPayment from "./SplitFormPayment";
import axios from 'axios';
import { URL, META_PIXEL_ID } from '../config/api';
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import { showLoginLiveWorkshop, showLogin } from '../redux/actions/appActions';
import ReactPixel from 'react-facebook-pixel';

const { useRef } = React;
const stripePromise = loadStripe(STRIPE_TEST_KEY);
const fonts = [{ cssSrc: "https://fonts.googleapis.com/css?family=Podkova:400" }]

const PaymentDetail = (props) => {
	const dispatch = useDispatch();
	const childRef = useRef();
    const loggedIn = localStorage.getItem('auth');
	const premium = localStorage.getItem('premium');
	const history = useHistory();
    const params = useParams();

	const dispatchLogin = () => {
        dispatch(showLogin());
    }

    useEffect(() => {
        if(premium == 1) {
            history.push('/projects');
            return;
        }

        initMetaPixel();
	}, [])

    const initMetaPixel = () => {
        const advancedMatching = { em: 'info@crafterspro.net' };
        const options = {
            autoConfig: true,
            debug: true,
        };
        ReactPixel.init(META_PIXEL_ID, advancedMatching, options);
    }

	const second = new Date();
	const third = new Date();
	second.setDate(second.getDate() + 30);
	third.setDate(third.getDate() + 60);
	const secondDay = second.toLocaleDateString('en-us', {  year:"numeric", month:"short", day:"numeric"});
	const thirdDay = third.toLocaleDateString('en-us', {  year:"numeric", month:"short", day:"numeric"});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirm, setConfirm] = useState(true);
    const [loading, setLoading] = useState(false);
    const [emailExist, setEmailExist] = useState(false);

	const onSubmitOrder = async () => {
		if(firstName == "") {
			alert("Please input first name.");
			return;
		}
		if(lastName == "") {
			alert("Please input last name.");
			return;
		}
        if(email == "") {
            alert("Please input email.");
            return;
        }
        if(password == "") {
            alert("Please input password.")
            return;
        }
        if(password != confirmPassword) {
            alert("Please confirm password.")
            return;
        }

        if(!confirm) {
			alert("You must check all boxes to purchase!")
			return;
		}

		setLoading(true);

		if (!premium) {
			const bodyFormData = new FormData();
			bodyFormData.append('Email', email);		
			const res = await axios.post(`${URL}/login/validate_email.php`, bodyFormData);
			if(res.data.Exist) {
                setLoading(false);

                const message = "The email " + email +  " is already exists. Please login and try again.";
                var result = window.confirm(message);
                if (result) {
                    handleExistLogin();
                }
	
				return;
			}
		}

		childRef.current.handleSubmitOrder()
	}

	const showLoading = (loading) => {
        setLoading(loading);
    }

	const handleEmailChange = async (value) => {
		setEmail(value);
		const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if (regex.test(value)) {
			const timeOutId = setTimeout(() => validateEmail(value), 500);
			return () => clearTimeout(timeOutId);
		} else {
            setEmailExist(false);
        }
	}

	const validateEmail = async(value) => {
		const bodyFormData = new FormData();
		bodyFormData.append('Email', value);		
		const res = await axios.post(`${URL}/login/validate_email.php`, bodyFormData);
		setEmailExist(res.data.Exist);
	}

	const handleExistLogin = () => {
		dispatchLogin();
	}

	const handleAutoLogin = async (email, password) => {
		const bodyFormData = new FormData();
		bodyFormData.append('Email', email);
		bodyFormData.append('Password', password);
  
		const res = await axios.post(`${URL}/login/login.php`, bodyFormData);
  
		if(res.data.Success) {
			localStorage.setItem('auth', 1);
			localStorage.setItem('id', res.data.Data.Id);
			localStorage.setItem('token', res.data.Data.Token);
			localStorage.setItem('email', res.data.Data.Email);
			localStorage.setItem('next_billing', res.data.Data.NextBilling);
			localStorage.setItem('premium', res.data.Data.Premium);
			localStorage.setItem('customer_id', res.data.Data.CustomerId);
			localStorage.setItem('type', res.data.Data.Type);
			localStorage.setItem('elite', res.data.Data.Elite);
  
			window.location.href = '/projects'
		} else {
			alert(res.data.Message);
		}
	}

    const handleConfirmOrder = async (payload) => {
        if(payload.error) {
            showLoading(false);
            alert("Please input valid card number");
        } else {
            createSubscription(payload.paymentMethod.id, email, password);
        }
    }

    const createSubscription = async(paymentMethodId, email, password) => {
        const formData = new FormData();
        if(loggedIn != 1) {
            formData.append('Login', 0);
            formData.append('Email', email);
            formData.append('Password', password);
        } else {
            formData.append('Login', 1);
            formData.append('UserId', localStorage.getItem('id'));
        }
    
        formData.append('PaymentType', 1);
        formData.append('LinkId', 0);
        formData.append('ReceiveEmail', 1);
        formData.append('PaymentMethodId', paymentMethodId);
        formData.append('HelpUkraine', 0);
        formData.append('FirstName', firstName);
        formData.append('LastName', lastName);

        const res = await axios.post(`${URL}/stripe/create_subscription.php`, formData);
        if(res.data.Success) {
            showLoading(false);
            if(res.data.Data.status == 'active') {
                var alertMessage = "Subscription payment is created successfully. Please login to access full features.";
                var result = window.confirm(alertMessage);
                if (result) {
                    ReactPixel.track('Purchase');
                    handleAutoLogin(email, password);
                }
            } else {
                alert("Payment failed and try again.");
            }
        } else {
            console.log("AAA_SAME_EMAIL");
            showLoading(false);
            alert(res.data.Message);
        }
    }

    const registerUser = async(paymentIntentId, customerId) => {    
        const formData = new FormData();
        formData.append('CustomerId', customerId);
        formData.append('PaymentIntentId', paymentIntentId);
        formData.append('Email', email);
        formData.append('Password', password);
        formData.append('ReceiveEmail', 1);
        formData.append('PaywallType', 7);
        formData.append('CommercialAccess', 0);
        formData.append('HelpUkraine', 0);
		formData.append('FirstName', firstName);
        formData.append('LastName', lastName);
    
        const res = await axios.post(`${URL}/stripe/create_payment.php`, formData);
        if(res.data.Success) {
          showLoading(false);
    
          var alertMessage = "Your payment is created successfully. Please login to access full features.";
          var result = window.confirm(alertMessage);
          if (result) {
            ReactPixel.track('Purchase');
            handleAutoLogin(email, password);
          }
        } else {
          showLoading(false);
          alert(res.data.Message);
        }
      }
    

	return (
		<Layout workshopPayment>
			<div role='main' className='main'>
				<section className='workshop-section section section-height-2 bg-light section-12 workshop-top'>
					<div className='container text-center'>
						<h2 className='font-primary-bold primary-color text-align-left mb-0'>CraftersPro Advantage</h2>
						<div className='row'>
							<div className='col-md-7'>
								<p
									className='font-primary-bold mb-0 workshop-payment-section text-align-left'
									style={{ fontSize: 26 }}>
									Account Information
								</p>
								<form>
									<input className="form-input-liveworkshop font-primary-regular" type="text" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)}/>
									<input className="form-input-liveworkshop font-primary-regular" type="text" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)}/>
									<input className="form-input-liveworkshop font-primary-regular" type="email" placeholder="Email Address" onChange={(e) => handleEmailChange(e.target.value)}/>

                                    {emailExist && <p className="text-align-left width-max-500 red">
										It looks like that email already is a CraftersPro account. Please login or use a  new email :)
										<a	className="blue"
											onClick={() => handleExistLogin()}>
											{" login"}
										</a>
									</p>}
									<input className="form-input-liveworkshop font-primary-regular" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                                    <input className="form-input-liveworkshop font-primary-regular" type="password" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)}/>															
								</form>

								<p
									className='font-primary-bold mb-0 workshop-payment-section text-align-left mt-48'
									style={{ fontSize: 26 }}>
									Billing Information
								</p>
								<Elements stripe={stripePromise} fonts={{fonts}}>
									<SplitFormPayment
                                        paymentType={params.type}
										ref={childRef}
										registerUser = {registerUser}
                                        handleConfirmOrder = {handleConfirmOrder}
										showLoading={showLoading}
										email={email}
									/>
								</Elements>
							</div>
	
							<div className='col-md-5 mt-60'>
								<p
									className='font-primary-bold workshop-payment-section text-align-left price-box'
									style={{ fontSize: 18 }}>
									{params.type == 1 ? '3 Days Free, then $29.99/year' : 
									<>Total Price: <span className="line-through">$97</span> $24</>
									}
								</p>
	
								{/* <label className="form-label-liveworkshop font-primary-regular mt-60" >
                                    <div>
                                        <input className="checkbox-liveworkshop" type="checkbox" defaultChecked={false} onChange={(e) => setConfirm(e.target.checked)}/>
                                    </div>
                                    <div className="font-primary-regular text-align-left" style={{marginLeft: "12px", fontSize: 15}}>Please check the box to indicate you have read and agree to the Terms of Use</div>
                                </label> */}

								<div className="center-horizontal">
									<ClipLoader color="#25a66a" loading={loading} size={24} />
									<a hidden={loading}
										className='btn btn-primary btn-primary-override font-primary-regular text-color-light button fs-20 mt-20 submit-order'
										
										onClick={() => onSubmitOrder()}>
										Submit Order
									</a>
								</div>

                                <img className='mt-32' src="/assets/img/zero.png" width="100%" height="auto" />

                                <img className='mt-12' src="/assets/img/commerical.png" width="100%" height="auto" />

							</div>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default PaymentDetail;
