import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Layout from './Layout';
import { STRIPE_TEST_KEY } from '../config/api';
import { loadStripe } from "@stripe/stripe-js";
import firebase from "firebase/compat/app";
import { META_PIXEL_ID } from '../config/api';
import { useDispatch } from 'react-redux';
import { showLogin } from '../redux/actions/appActions';
import ReactPixel from 'react-facebook-pixel';
import PromoBox from "./common/PromoBox";
import RefundGuarantee from "./common/RefundGuarantee";
import {isMobile} from 'react-device-detect';

const stripePromise = loadStripe(STRIPE_TEST_KEY);
const fonts = [{ cssSrc: "https://fonts.googleapis.com/css?family=Podkova:400" }]

const PaywallStandard = (props) => {
    const dispatch = useDispatch();
  
    const premium = localStorage.getItem('premium');  
    const history = useHistory();

    useEffect(() => {
        if(premium == 1) {
            history.push('/projects');
            return;
        }
        window.scrollTo(0, 0);
    
        firebase.analytics().logEvent('payment_page_loaded');
        initMetaPixel();
        console.log("AAA_MOBILE=====", isMobile);
    }, [])

    const initMetaPixel = () => {
        const advancedMatching = { em: 'info@crafterspro.net' };
        const options = {
            autoConfig: true,
            debug: true,
        };
        ReactPixel.init(META_PIXEL_ID, advancedMatching, options);
    }

    const handleStart = (type) => {
        window.open('/payment-detail/' + type, "_self");
    }

    const handleTopMenuItemClick = (index) => {
        var viewId = "faq-section";
        if (index == 0) {
            viewId = "payment-section"
        } else if(index == 1) {
            viewId = "review-section"
        } else if(index == 2) {
            viewId = "refund-section";
        } else if(index == 3) {
            viewId = "meet-section";
        }
        var indexView = document.getElementById(viewId);
        indexView.scrollIntoView({behavior: "smooth", block: "start"});
    }

    const onHandleJoin = () => {
        var indexView = document.getElementById("payment-top");
        indexView.scrollIntoView({behavior: "smooth", block: "start"});
    }
    
    return (
        <Layout>
            <div role="main" className="main paywall">
                {!isMobile && <div className="top-image">
                    <div className="font-primary-bold color-white text-center paywall-top-text">
                        The CraftersPro Advantage
                    </div>
                    <div className="row center-horizontal mt-20">
                        <div className="col-6 col-lg-2 text-center">
                            <a className="font-primary-bold color-white text-3"
                                onClick={() => handleTopMenuItemClick(0)}>
                                Overview
                            </a>
                        </div>
                        <div className="col-6 col-lg-2 text-center">
                            <a className="font-primary-bold color-white text-3"
                                onClick={() => handleTopMenuItemClick(1)}>
                                Testomonies
                            </a>
                        </div>
                        <div className="col-6 col-lg-2 text-center">
                            <a className="font-primary-bold color-white text-3"
                                onClick={() => handleTopMenuItemClick(2)}>
                                Refund Guarantee
                            </a>
                        </div>
                        <div className="col-6 col-lg-2 text-center">
                            <a className="font-primary-bold color-white text-3"
                                onClick={() => handleTopMenuItemClick(3)}>
                                Meet the Team
                            </a>
                        </div>
                        <div className="col-12 col-lg-2 text-center">
                            <a className="font-primary-bold color-white text-3"
                                onClick={() => handleTopMenuItemClick(4)}>
                                FAQ's
                            </a>
                        </div>
                    </div>
                </div>}

                {isMobile && <section id="start" className="section-1" style={{ marginTop: '10rem' }}>
                    <img src="https://static.wixstatic.com/media/f1f906_84df04afa53a4d889d2d7df3ebc07847~mv2.png/v1/fill/w_333,h_143,al_c,q_85,usm_0.66_1.00_0.01/Group%201.webp" width="100%" height="auto" className="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="400" style={{ animationDuration: '2000ms'}} />
                    <h2 className="font-weight-bold center" style={{ fontSize: '20px', whiteSpace: 'nowrap', marginTop: '0.5em', color: '#000', fontFamily: 'Oswald-Medium' }}>Crafters Pro Perks</h2>
                    <p style={{ fontSize: '16px',  lineHeight: 1.6, fontFamily: 'Nimbus-Sans', maxWidth: 232, color: '#000', margin: '0.5rem auto' }}>400+ Fonts, Endless Projects, and Stickers<br/> Start 3 Day Free Trial</p>
                </section>}

                {isMobile &&
                <><div id="payment-section" className="text-center" style={{ fontFamily: 'Poppins-Semi-Bold', fontSize: 22, marginTop: '2rem' }}>Choose the membership you want!</div>
                <section id="payment" className="section-2">
                    <div className="row" style={{ justifyContent: 'center' }}>
                    <div className="trialback">
                            <img src="assets/img/best_deal.png" />
                            <h2 style={{ marginBottom: 0, color: '#38eca6' }}>Lifetime Pro</h2>
                            <div style={{ lineHeight: 1, fontSize: 20, margin: 10, display: 'flex', justifyContent: 'center' }}>
                                $<span style={{ fontWeight: 700, color: '#38eca6', fontSize: 45, marginTop: -3 }}>
                                    <span className="line-through">97</span> $24
                                </span>
                            </div>

                            <div style={{ fontWeight: 900 }}>No Monthly Payments!</div>
                            <div>One time Fee</div>
                            <a className="tp-caption btn btn-green font-weight-semibold"
                                style={{ color: "#fff", width: "100%", fontSize: 14, marginTop: "12px", justifyContent: "center" }}
                                onClick={() => handleStart(2)}>
                                Purchase Now
                            </a>
                            <hr style={{ marginLeft: '-2rem', marginRight: '-2rem', borderTop: '2px solid rgba(0,0,0,.1)' }} />
                            <div className="item">Unlimited use of all projects</div>
                            <div className="item">Speedy Support</div>
                            <div className="item">Access forever. Never pay monthly or yearly</div>
                        </div>
                        <div className="trialback active">
                            <h2 style={{ marginBottom: 0 }}>Free Trial / Yearly</h2>
                            <div style={{ fontWeight: 900, fontSize: 11 }}>3 DAY FREE TRIAL THEN</div>
                            <div style={{ lineHeight: 1, fontSize: 20, display: 'flex', justifyContent: 'center' }}>$<span style={{ fontWeight: 700, fontSize: 45, marginTop: -3 }}>29.99</span></div>
                            <div style={{ fontWeight: 900 }}>Every Year</div>
                            <div>Perfect For All Crafting Needs!</div>
                            <a className="tp-caption btn btn-blue font-weight-semibold" style={{ color: "#fff", width: "100%", fontSize: 14, marginTop: "12px", justifyContent: "center" }} onClick={() => handleStart(1)}>Start Free Trial</a>

                            <hr style={{ marginLeft: '-2rem', marginRight: '-2rem', borderTop: '2px solid rgba(0,0,0,.1)' }} />
                            <div className="item">Unlimited use of all projects</div>
                            <div className="item">Speedy Support</div>
                            <div className="item">Free 3 Day Trial</div>
                        </div>
                    </div>
                </section></>}

                {!isMobile && <section id="start" className="section-1">
                    <img src="https://static.wixstatic.com/media/f1f906_84df04afa53a4d889d2d7df3ebc07847~mv2.png/v1/fill/w_333,h_143,al_c,q_85,usm_0.66_1.00_0.01/Group%201.webp" width="100%" height="auto" className="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="400" style={{ animationDuration: '2000ms'}} />
                    <h2 className="font-weight-bold center" style={{ fontSize: '20px', whiteSpace: 'nowrap', marginTop: '0.5em', color: '#000', fontFamily: 'Oswald-Medium' }}>Crafters Pro Perks</h2>
                    <p style={{ fontSize: '16px',  lineHeight: 1.6, fontFamily: 'Nimbus-Sans', maxWidth: 232, color: '#000', margin: '0.5rem auto' }}>400+ Fonts, Endless Projects, and Stickers<br/> Start 3 Day Free Trial</p>
                </section>}

                <section className="section-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-4">
                                <img src="/assets/img/tons.png" 
                                className="appear-animation"
                                data-appear-animation="fadeInLeft"
                                data-appear-animation-delay="800" 
                                style={{ animationDuration: '2000ms'}} />
                                <h5 style={{marginTop: "8px"}}>Tons of Projects</h5>
                                <p>Projects ready for<br/>any occasion</p>
                            </div>
                            <div className="col-12 col-sm-4">
                                <img src="/assets/img/stickers.png" 
                                className="appear-animation"
                                data-appear-animation="fadeInUp"
                                data-appear-animation-delay="1200" style={{ animationDuration: '2000ms'}}/>
                                <h5 style={{marginTop: "8px"}}>Cut Files</h5>
                                <p>Hand Crafted & Royalty Free</p>
                            </div>
                            <div className="col-12 col-sm-4">
                                <img src="/assets/img/fonts.png" 
                                className="appear-animation"
                                data-appear-animation="fadeInRight"
                                data-appear-animation-delay="1600" style={{ animationDuration: '2000ms'}}/>
                                <h5 style={{marginTop: "8px"}}>Tons of Fonts</h5>
                                <p>400+ Designer<br/>Fonts </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-2">
                    <div className="container">
                        <div className='row center-horizontal'>
                            <div className='col-10 col-lg-5 mb-2'>
                                <img src="/assets/img/zero.png" width="100%" height="auto" className="appear-animation" data-appear-animation="fadeInLeft" data-appear-animation-delay="400" style={{ animationDuration: '2000ms'}} />
                            </div>

                            <div id="payment-top" className='col-10 col-lg-5'>
                                <img src="/assets/img/commerical.png" width="100%" height="auto" className="appear-animation" data-appear-animation="fadeInRight" data-appear-animation-delay="600" style={{ animationDuration: '2000ms'}} />
                            </div>
                        </div>
                    </div>
                </section>

                {!isMobile &&
                <><div id="payment-section" className="text-center" style={{ fontFamily: 'Poppins-Semi-Bold', fontSize: 22, marginTop: '2rem' }}>Choose the membership you want!</div>
                <section id="payment" className="section-2">
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <div className="trialback active">
                            <h2 style={{ marginBottom: 0 }}>Free Trial / Yearly</h2>
                            <div style={{ fontWeight: 900, fontSize: 11 }}>3 DAY FREE TRIAL THEN</div>
                            <div style={{ lineHeight: 1, fontSize: 20, display: 'flex', justifyContent: 'center' }}>$<span style={{ fontWeight: 700, fontSize: 45, marginTop: -3 }}>29.99</span></div>
                            <div style={{ fontWeight: 900 }}>Every Year</div>
                            <div>Perfect For All Crafting Needs!</div>
                            <a className="tp-caption btn btn-blue font-weight-semibold" style={{ color: "#fff", width: "100%", fontSize: 14, marginTop: "12px", justifyContent: "center" }} onClick={() => handleStart(1)}>Start Free Trial</a>

                            <hr style={{ marginLeft: '-2rem', marginRight: '-2rem', borderTop: '2px solid rgba(0,0,0,.1)' }} />
                            <div className="item">Unlimited use of all projects</div>
                            <div className="item">Speedy Support</div>
                            <div className="item">Free 3 Day Trial</div>
                        </div>
                        <div className="trialback">
                            <img src="assets/img/best_deal.png" />
                            <h2 style={{ marginBottom: 0, color: '#38eca6' }}>Lifetime Pro</h2>
                            <div style={{ lineHeight: 1, fontSize: 20, margin: 10, display: 'flex', justifyContent: 'center' }}>
                                $<span style={{ fontWeight: 700, color: '#38eca6', fontSize: 45, marginTop: -3 }}>
                                    <span className="line-through">97</span> $24
                                </span>
                            </div>

                            <div style={{ fontWeight: 900 }}>No Monthly Payments!</div>
                            <div>One time Fee</div>
                            <a className="tp-caption btn btn-green font-weight-semibold"
                                style={{ color: "#fff", width: "100%", fontSize: 14, marginTop: "12px", justifyContent: "center" }}
                                onClick={() => handleStart(2)}>
                                Purchase Now
                            </a>
                            <hr style={{ marginLeft: '-2rem', marginRight: '-2rem', borderTop: '2px solid rgba(0,0,0,.1)' }} />
                            <div className="item">Unlimited use of all projects</div>
                            <div className="item">Speedy Support</div>
                            <div className="item">Access forever. Never pay monthly or yearly</div>
                        </div>
                    </div>
                </section></>}
 
                <div style={{marginTop: "16px"}}>
                    <div className="BarView">
                        <div className="Bar" />
                    </div>
                </div>

                <div id="review-section" className="font-primary-bold primary-color text-center mt-20 text-5 mb-24">Testimonies</div>
                <section id="review" className="section-15 container">
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div className="center-horizontal appear-animation" 
                                data-appear-animation="fadeInUp"
                                data-appear-animation-delay="200" 
                                style={{ animationDuration: '2000ms'}}>
                                <img className="tm-item mt-20" src="/assets/img/stock1.png" />
                                <div className="font-primary-bold primary-color text-center tm-name">Andrew Davis</div>
                                <div className="font-primary-regular text-center tm-text">“Ever since I started using CraftersPro, I’ve saved hours searching for cut files and fonts”</div>
                                <img className="mt-12" src="/assets/img/five_star.png" />
                            </div>
                        </div>

                        <div className="col-12 col-lg-4">
                            <div className="center-horizontal appear-animation"
                                data-appear-animation="fadeInUp"
                                data-appear-animation-delay="400" 
                                style={{ animationDuration: '2000ms'}}>
                                <img className="tm-item mt-20" src="/assets/img/stock2.jpg" />
                                <div className="font-primary-bold primary-color text-center tm-name">Susan Harding</div>
                                <div className="font-primary-regular text-center tm-text">“CraftersPro changed the game. Amazing projects and designs.”</div>
                                <img className="mt-12" src="/assets/img/five_star.png" />
                            </div>
                        </div>

                        <div className="col-12 col-lg-4">
                            <div className="center-horizontal appear-animation"
                                data-appear-animation="fadeInUp"
                                data-appear-animation-delay="600" 
                                style={{ animationDuration: '2000ms'}}>
                                <img className="tm-item mt-20" src="/assets/img/stock3.jpeg" />
                                <div className="font-primary-bold primary-color text-center tm-name">Misty Mueller</div>
                                <div className="font-primary-regular text-center tm-text">“So glad I purchased the lifetime membership, for $24 you can’t beat the value.”</div>
                                <img className="mt-12" src="/assets/img/five_star.png" />
                            </div>
                        </div>
                    </div> 
                </section>


                <section className="section-2">
                    <div className="container">
                        <RefundGuarantee />

                        <div id="meet-section" className="mb-32 mt-20">
                            <div className='font-primary-bold text-center primary-color text-6'>
                                Meet The Team
                            </div>

                            <div className='font-primary-bold text-center text-5 mt-20'>
                                A <span className='primary-color'>Collection</span> of Outstanding Talent
                            </div>

                            <div className='font-primary-regular text-center text-3'>
                                CraftersPro is fortunate to have a group of competent and caring professionals, <br/> working together to provide services to our CraftersPro community
                            </div>
                        </div>

                        <div className="row mb-32">
                            <div className='col-4 col-lg-4 text-left'>
                                <div className="payment-member-container appear-animation"
                                    data-appear-animation="fadeInLeft"
                                    data-appear-animation-delay="200" 
                                    style={{ animationDuration: '2000ms'}}>
                                    <img src={'/assets/img/about_avatar_1.png'} width='100%' />
                                    <div
                                        className='text-1 mt-20 text-left ml-16'
                                        style={{ lineHeight: '14px' }}>
                                        Project Creator
                                    </div>
                                    <div className='font-weight-bold text-3 center font-primary-bold mt-8 ml-16 text-left'>
                                        Kendall Dobbins
                                    </div>
                                </div>
                            </div>

                            <div className='col-4 col-lg-4 text-left'>
                                <div className="payment-member-container appear-animation"
                                    data-appear-animation="fadeInUp"
                                    data-appear-animation-delay="400" 
                                    style={{ animationDuration: '2000ms'}}>
                                    <img src={'/assets/img/about_avatar_2.png'} width='100%' />
                                    <div
                                        className='text-1 mt-20 text-left ml-16'
                                        style={{ lineHeight: '14px' }}>
                                        Cut File Creator
                                    </div>
                                    <div className='font-weight-bold text-3 center font-primary-bold mt-8 ml-16 text-left'>
                                        Natalija
                                    </div>
                                </div>
                            </div>

                            <div className='col-4 col-lg-4 text-left'>
                                <div className="payment-member-container appear-animation"
                                    data-appear-animation="fadeInRight"
                                    data-appear-animation-delay="600" 
                                    style={{ animationDuration: '2000ms'}}>
                                    <img src={'/assets/img/about_avatar_3.png'} width='100%' />
                                    <div
                                        className='text-1 mt-20 text-left ml-16'
                                        style={{ lineHeight: '14px' }}>
                                        Customer Service Pro
                                    </div>
                                    <div className='font-weight-bold text-3 center font-primary-bold mt-8 ml-16 text-left'>
                                        Veronica Santos
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex promo align-items-center'>
                            <div className='promo-text'>
                                ​Become a CraftersPro Member
                            </div>
                            <a className="btn btn-white font-weight-semibold primary-color join" onClick={()=>{onHandleJoin()}} target="_blank">
                                Join
                            </a>
                        </div>
                    </div>
                </section>

                <section id="faq-section" className='workshop-section section section-height-2 bg-light section-12'>
					<div className='container'>
						<h2 className='font-primary-bold mb-0 text-center'>Frequently Asked</h2>
						<p
							className='font-primary-bold primary-color text-center'
							style={{ fontSize: 26 }}>
							Questions
						</p>
						<div className='row'>
							<div className='col-md-6 right-border'>
								<h6
									className='font-primary-bold primary-color text-center'
									style={{ fontSize: 20 }}>
									What is included in my membership? 
								</h6>
								<p
									className='font-primary-extra-light'
									style={{ fontWeight: 'bold', fontSize: 14 }}>
                                    You will get access to all of our projects (some with video tutorials, all with instructions), over 400 fonts, and over 2,000 cut files. This membership will either renew yearly, or be lifetime access through one payment depending on your selection.
								</p>
							</div>

                            <div className='col-md-6'>
								<h6
									className='font-primary-bold primary-color text-center'
									style={{ fontSize: 20 }}>
									What Cricut machines does this work with?
								</h6>
								<p
									className='font-primary-extra-light'
									style={{ fontWeight: 'bold', fontSize: 14 }}>
                                    CraftersPro works with ALL Cricut machines. You simply import the fonts or cut files into design space and start crafting.
                                </p>
							</div>

                            <div className='col-md-6 right-border'>
								<h6
									className='font-primary-bold primary-color text-center'
									style={{ fontSize: 20 }}>
									I’m a beginner. Is this for me?
								</h6>
								<p
									className='font-primary-extra-light'
									style={{ fontWeight: 'bold', fontSize: 14 }}>
                                    Yes! Absolutely! We designed CraftersPro to be the all-in-one solution for EVERYTHING Cricut. We have over 100 projects, all with step by step instructions specifically designed and written for beginners.
								</p>
							</div>

                            <div className='col-md-6'>
								<h6
									className='font-primary-bold primary-color text-center'
									style={{ fontSize: 20 }}>
									Is the lifetime membership really for life? 
								</h6>
								<p
									className='font-primary-extra-light'
									style={{ fontWeight: 'bold', fontSize: 14 }}>
                                    It’s really for life. 1 payment and never pay monthly or yearly. 
                                </p>
							</div>

                            <div className='col-md-6 right-border'>
								<h6
									className='font-primary-bold primary-color text-center'
									style={{ fontSize: 20 }}>
									Do you add new projects, stickers, and fonts regularly? 
								</h6>
								<p
									className='font-primary-extra-light'
									style={{ fontWeight: 'bold', fontSize: 14 }}>
                                    Yes!! We add in new projects, stickers, and fonts weekly. Be sure to use the “sort by newest” to check out the latest designs. 
								</p>
							</div>

                            <div className='col-md-6'>
								<h6
									className='font-primary-bold primary-color text-center'
									style={{ fontSize: 20 }}>
									Can I use the assets here commercially? 
								</h6>
								<p
									className='font-primary-extra-light'
									style={{ fontWeight: 'bold', fontSize: 14 }}>
                                    You may sell physical products made with any assets from CraftersPro.net, but never sell any digital products such as the cut files or fonts. 
                                </p>
							</div>

						</div>
					</div>
				</section>
            </div>

        </Layout>
    );
}

export default PaywallStandard;