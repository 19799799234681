import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { showLogin } from '../redux/actions/appActions';

const Header = ({workshop, workshopPayment}) => {
	const [activeNav, setActiveNav] = useState('home');
	const dispatch = useDispatch();
	const location = useLocation();
	const pathname = location.pathname;
	const [loggedIn, setLoggedIn] = useState(localStorage.getItem('auth'));
	const type = localStorage.getItem('type');

	const dispatchLogin = () => {
		dispatch(showLogin());
	};

	const handleLogInAndOut = () => {
		if (loggedIn == 1) {
			setLoggedIn(0);
			localStorage.clear();
			window.location.href = "/";
		} else {
			dispatchLogin();
		}
	};

	const handleWorkshopItem = (index) => {
		var elementId = "hosted-by";
		if (index == 1) {
			elementId = "workshop-details";
		} else if(index == 2) {
			elementId = "materials";
		} else if(index == 3) {
			elementId = "purchase-view";
		}
		var view = document.getElementById(elementId);
		view.scrollIntoView({behavior: "smooth", block: "start"});
	}

	return (
		<header
			id='header'
			className='header-floating header-effect-reveal'
			data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyStartAt': 250}">
			<div id='header-body' className='header-body bg-light-5 fixed-top'>
				<div className='header-container container'>
					<div className='header-row'>
						<div className='header-column justify-content-start'>
							<div className='header-logo'>
								<a href='/'>
									<img
										alt='EZ'
										width='62'
										height='62'
										src='/assets/img/white-logo.svg'
									/>
									<span
										style={{
											color: '#fff',
											fontSize: 14,
											fontWeight: 700,
											marginLeft: 10,
											fontFamily: 'Poppins-Semi-Bold',
										}}>
										CRAFTERSPRO
									</span>
								</a>
							</div>
						</div>
						<div className='header-column justify-content-end'>
							<div className='header-nav'>
								<div className='header-nav-main header-nav-main-effect-1 header-nav-main-sub-effect-1'>
									<nav className='collapse'>
										{!workshop ?
										<>
											{ !workshopPayment ?
											<ul className='nav flex-column flex-lg-row' id='mainNav'>
												<li className='dropdown dropdown-mega'>
													<a
														className={`dropdown-item dropdown-toggle ${
															pathname === '/' ? 'active' : ''
														}`}
														href='/'
														onClick={() => setActiveNav('home')}>
														Home
													</a>
												</li>
												<li className='dropdown dropdown-mega dropdown-mega-style-2'>
													<a
														className={`dropdown-item dropdown-toggle ${
															pathname === '/projects' ? 'active' : ''
														}`}
														href='/projects'
														onClick={() => setActiveNav('projects')}>
														Projects
													</a>
												</li>
												<li className='dropdown'>
													<a
														className={`dropdown-item dropdown-toggle ${
															pathname === '/fonts' ? 'active' : ''
														}`}
														href='/fonts'
														onClick={() => setActiveNav('fonts')}>
														Fonts
													</a>
												</li>
												<li className='dropdown'>
													<a
														className={`dropdown-item dropdown-toggle ${
															pathname === '/stickers' ? 'active' : ''
														}`}
														href='/stickers'
														onClick={() => setActiveNav('cut')}>
														Cut Files
													</a>
												</li>
												{/* <li className='dropdown'>
													<a
														className={`dropdown-item dropdown-toggle ${
															pathname === '/workshop' ? 'active' : ''
														}`}
														href='/workshop'
														onClick={() => setActiveNav('cut')}>
														Live Workshop
													</a>
												</li> */}
												<li className='dropdown dropdown-mega dropdown-mega-style-2'>
													<a
														className={`dropdown-item dropdown-toggle ${
															pathname === '/tutorial' ? 'active' : ''
														}`}
														href='/tutorial'
														onClick={() => setActiveNav('tutorial')}>
														Tutorial
													</a>
												</li>

												{loggedIn == 0 && type == 3 && (
													<li className='dropdown dropdown-mega dropdown-mega-style-2'>
														<a
															className={`dropdown-item dropdown-toggle ${
																pathname === '/payment-upgrade-elite'
																	? 'active'
																	: ''
															}`}
															href='/payment-upgrade-elite'
															onClick={() =>
																setActiveNav('payment-upgrade-elite')
															}>
															Elite
														</a>
													</li>
												)}
												<li className='dropdown dropdown-mega dropdown-mega-style-2'>
													<a
														className={`dropdown-item dropdown-toggle ${
															pathname === '/about' ? 'active' : ''
														}`}
														href='/about'
														onClick={() => setActiveNav('about')}>
														About Us
													</a>
												</li>
												{loggedIn != 1 && (
													<li className='dropdown dropdown-mega dropdown-mega-style-2'>
														<a
															className={`dropdown-item dropdown-toggle ${
																pathname === '/payment' ? 'active' : ''
															}`}
															href='/payment'
															onClick={() => {
																setActiveNav('payment');
															}}>
															Purchase Here
														</a>
													</li>
												)}
												<li className='dropdown' style={{ marginLeft: 60 }}>
													<a
														className={`dropdown-item dropdown-toggle ${
															pathname === '/login' ? 'active' : ''
														}`}
														onClick={handleLogInAndOut}>
														<img
															alt='EZ'
															width='26'
															height='26'
															src='/assets/img/user.png'
														/>
														<span className='wrap' style={{ marginLeft: 10 }}>
															<span>{loggedIn == 1 ? 'Log Out' : 'Log In'}</span>
														</span>
													</a>
												</li>
											</ul> : 
											<ul className='nav flex-column flex-lg-row' id='mainNav'>
												<li className='dropdown' style={{ marginLeft: 60 }}>
													<a
														className={`dropdown-item dropdown-toggle ${
															pathname === '/login' ? 'active' : ''
														}`}
														onClick={handleLogInAndOut}>
														<img
															alt='EZ'
															width='26'
															height='26'
															src='/assets/img/user.png'
														/>
														<span className='wrap' style={{ marginLeft: 10 }}>
															<span>{loggedIn == 1 ? 'Log Out' : 'Log In'}</span>
														</span>
													</a>
												</li>
											</ul> 
											}
										</>
										:
										<ul className='nav flex-column flex-lg-row' id='mainNav'>
											<li className='dropdown dropdown-mega dropdown-mega-style-2'>
												<a
													className='dropdown-item dropdown-toggle '
													onClick={() => handleWorkshopItem(0)}>
													Host
												</a>
											</li>
											<li className='dropdown dropdown-mega dropdown-mega-style-2'>
												<a
													className='dropdown-item dropdown-toggle '
													onClick={() => handleWorkshopItem(1)}>
													Details
												</a>
											</li>
											<li className='dropdown dropdown-mega dropdown-mega-style-2'>
												<a
													className='dropdown-item dropdown-toggle '
													onClick={() => handleWorkshopItem(2)}>
													Materials
												</a>
											</li>
											<li className='dropdown dropdown-mega dropdown-mega-style-2'>
												<a
													className='dropdown-item dropdown-toggle '
													onClick={() => handleWorkshopItem(3)}>
													Purchase
												</a>
											</li>
											<li className='dropdown dropdown-mega dropdown-mega-style-2'>
												<a
													className='dropdown-item dropdown-toggle '
													href="/"
													target="_blank">
													CraftersPro
												</a>
											</li>
											<li className='dropdown' style={{ marginLeft: 60 }}>
												<a
													className={`dropdown-item dropdown-toggle ${
														pathname === '/login' ? 'active' : ''
													}`}
													onClick={handleLogInAndOut}>
													<img
														alt='EZ'
														width='26'
														height='26'
														src='/assets/img/user.png'
													/>
													<span className='wrap' style={{ marginLeft: 10 }}>
														<span>{loggedIn == 1 ? 'Log Out' : 'Log In'}</span>
													</span>
												</a>
											</li>
										</ul> }
									</nav>
								</div>
								<button
									className='header-btn-collapse-nav ml-3'
									data-toggle='collapse'
									data-target='.header-nav-main nav'>
									<span className='hamburguer'>
										<span></span>
										<span></span>
										<span></span>
									</span>
									<span className='close'>
										<span></span>
										<span></span>
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
