import React, { useEffect, useMemo, useState } from "react";
import { PaymentRequestButtonElement, useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import ClipLoader from "react-spinners/ClipLoader";
import axios from 'axios';
import { URL, META_PIXEL_ID } from '../config/api';
import { useHistory } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

const useOptions = () => {
  const fontSize = 16;
  const options = useMemo(
    () => ({
      showIcon: true,
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: 'Source Code Pro, monospace',
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const SplitForm = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);

  const elements = useElements();
  const options = useOptions();
  const [credit, setCredit] = useState(false);
  const [applePay, setApplePay] = useState(false);
  const [commercialAccess, setCommercialAccess] = useState(false);

  useEffect(() => {
    initMetaPixel();
    console.log("AAA_PAYWALL_TYPE=====", props.paywallType);
  }, []);

  const initMetaPixel = () => {
    const advancedMatching = { em: 'info@crafterspro.net' };
    const options = {
        autoConfig: true,
        debug: true,
    };
    ReactPixel.init(META_PIXEL_ID, advancedMatching, options);
  }

  useEffect(() => {
    localStorage.setItem("PAYMENT_EMAIL", props.email);
  }, [props.email]);

  useEffect(() => {
    localStorage.setItem("PAYMENT_PASSWORD", props.password);
  }, [props.password]);

  useEffect(() => {
    localStorage.setItem("PAYMENT_CONFIRM_PASSWORD", props.confirmPassword);
  }, [props.confirmPassword]);
  
  useEffect(() => {
    localStorage.setItem("PAYMENT_RECEIVE_EMAIL", props.receiveEmail);
  }, [props.receiveEmail]);
  
  useEffect(() => {
    if(props.paywallType == 0 || props.paywallType == 1) {
      if(stripe) {
        updatePaymentRequest();
      }
    }
  }, [stripe]);

  const updatePaymentRequest = () => {
    var price = 9700;
    if(props.paywallType == 1) {
      price = Math.round(97 * (1 - props.discount)) * 100;
    }

    const prrr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: props.paymentType == 1 ? '3 Day Trial then $29.99/Year' : 'Crafters Pro Lifetime Purchase',
        amount: props.paymentType == 1? 0 : price,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    prrr.canMakePayment().then(result => {
      if (result) {
        setPaymentRequest(prrr);
        setApplePay(true);
      }});
  }

  useEffect(() => {
    if(paymentRequest) {
      paymentRequest.on('paymentmethod', async (ev) => {
        const email = localStorage.getItem("PAYMENT_EMAIL");
        const password = localStorage.getItem("PAYMENT_PASSWORD");
        const confirmPassword = localStorage.getItem("PAYMENT_CONFIRM_PASSWORD");

        if(email == "") {
          ev.complete('fail');
          alert("Please input email.");
          return;
        }

        if(password == "") {
          ev.complete('fail');
          alert("Please input password.");
          return;
        }
    
        if(password != confirmPassword) {
          ev.complete('fail');
          alert("Please confirm password.");
          return;
        }

        if(props.paymentType == 1) {
          props.showLoading(true);
          props.handleAppleSubscription(ev.paymentMethod.id, email, password);
          ev.complete('success');
          return;
        }

        props.showLoading(true);

        const formData = new FormData();
        formData.append('Email', email);
        formData.append('ApplePay', 1);
        formData.append('LinkId', props.linkId);
        if(props.paywallType == 1) {
          formData.append('Discount', props.discount);
        }
        formData.append('PaywallType', props.paywallType);
        formData.append('CommercialAccess', commercialAccess? 1 : 0);

        const res = await axios.post(`${URL}/stripe/create_payment_intent.php`, formData);
        if(res.data.Success) {
          const paymentIntentId = res.data.PaymentIntentId;
          const customerId = res.data.CustomerId;
          const clientSecret = res.data.ClientSecret;

          const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
            clientSecret,
            {payment_method: ev.paymentMethod.id},
            {handleActions: false}
          );
        
          if (confirmError) {
            props.showLoading(false);
            ev.complete('fail');
            alert("Failed.");
          } else {
            ev.complete('success');
            if (paymentIntent.status === "requires_action") {
              const {error} = await stripe.confirmCardPayment(clientSecret);
              if (error) {
                props.showLoading(false);
                alert(error.message);
              } else {
                registerUser(paymentIntentId, customerId);
                // The payment has succeeded.
              }
            } else {
              registerUser(paymentIntentId, customerId);
              // The payment has succeeded.
            }
          }
        } else {
          props.showLoading(false);
          alert(res.data.Message);
        }
      });
    }
  }, [paymentRequest]);

  const useCredit = async () => {
    setCredit(true);
  }

  const handleConfirmOrder = async () => {
    if (!stripe || !elements) {
      return;
    }

    if(props.paymentType == 1) { //=====Subscription Payment
      props.showLoading(true);
      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement)
      });
      
      props.handleConfirmOrder(payload);
    } else {
      props.showLoading(true);
  
      if(props.email == "") {
        props.showLoading(false);
        alert("Please input email.");
        return;
      }
  
      if(props.password == "") {
        props.showLoading(false);
        alert("Please input password.");
        return;
      }
  
      if(props.password != props.confirmPassword) {
        props.showLoading(false);
        alert("Please confirm password.");
        return;
      }
      
      const formData = new FormData();
      formData.append('Email', props.email);
      formData.append('ApplePay', 0);
      formData.append('LinkId', props.linkId);
      if(props.paywallType == 1) {
        formData.append('Discount', props.discount);
      } else if(props.paywallType == 2 || props.paywallType == 4) {
        formData.append("IsPromo", props.isPromo? 1 : 0);
        formData.append("PromoCodeId", props.promoCodeId);
      }
      formData.append('PaywallType', props.paywallType);
      formData.append('CommercialAccess', commercialAccess? 1 : 0);

      const res = await axios.post(`${URL}/stripe/create_payment_intent.php`, formData);
      if(res.data.Success) {
        const paymentIntentId = res.data.PaymentIntentId;
        const customerId = res.data.CustomerId;
  
        const payload = await stripe.confirmCardPayment(res.data.ClientSecret, {
          setup_future_usage: 'off_session',
          payment_method: {
            card: elements.getElement(CardNumberElement)
          }
        });
        
        if (payload.error) {
          props.showLoading(false);
          alert(payload.error.message);
        } else {
          registerUser(paymentIntentId, customerId);
        }
      } else {
        alert(res.data.Message);
        props.showLoading(false);
      }
    }
  }

  const registerUser = async(paymentIntentId, customerId) => {
    const email = localStorage.getItem("PAYMENT_EMAIL");
    const password = localStorage.getItem("PAYMENT_PASSWORD");
    const receiveEmail = localStorage.getItem("PAYMENT_RECEIVE_EMAIL");

    const formData = new FormData();
    formData.append('CustomerId', customerId);
    formData.append('PaymentIntentId', paymentIntentId);
    formData.append('Email', email);
    formData.append('Password', password);

    if(props.paywallType == 1) {
      formData.append('Discount', props.discount);
    } else if(props.paywallType == 2 || props.paywallType == 4) {
      formData.append('IsPromo', props.isPromo? 1 : 0);
      formData.append("PromoCodeId", props.promoCodeId);
    }

    if(receiveEmail) {
      formData.append('ReceiveEmail', 1);
    } else {
      formData.append('ReceiveEmail', 0);
    }
    formData.append('PaywallType', props.paywallType);
    formData.append('CommercialAccess', commercialAccess? 1 : 0);
    formData.append('HelpUkraine', props.helpUkraine == 1? 1 : 0);

    const res = await axios.post(`${URL}/stripe/create_payment.php`, formData);
    if(res.data.Success) {
      props.showLoading(false);

      var alertMessage = "Your payment is created successfully. Please login to access full features.";
      if(props.helpUkraine == 1) {
        if(props.paywallType == 5) {
          alertMessage = "Purchase successful. You will receive an email confirmation on the 5 meals being donated. Thank you for your support, and enjoy CraftersPro Lifetime Access.";
        } else {
          alertMessage = "Purchase successful. You will receive an email confirmation on the 10 meals being donated. Thank you for your support, and enjoy CraftersPro Lifetime Access.";
        }
      }
      var result = window.confirm(alertMessage);
      if (result) {
        ReactPixel.track('Purchase');
        //history.push('/login');
        handleAutoLogin(email, password);
      }

      if(props.penAdapter || props.paywallType == 4) {
        window.open('https://www.emailmeform.com/builder/form/eNnb2dKdtkmfY', '_blank');
      }
    } else {
      props.showLoading(false);
      alert(res.data.Message);
    }
  }

  const handleAutoLogin = async (email, password) => {
      const bodyFormData = new FormData();
      bodyFormData.append('Email', email);
      bodyFormData.append('Password', password);

      const res = await axios.post(`${URL}/login/login.php`, bodyFormData);

      if(res.data.Success) {
          localStorage.setItem('auth', 1);
          localStorage.setItem('id', res.data.Data.Id);
          localStorage.setItem('token', res.data.Data.Token);
          localStorage.setItem('email', res.data.Data.Email);
          localStorage.setItem('next_billing', res.data.Data.NextBilling);
          localStorage.setItem('premium', res.data.Data.Premium);
          localStorage.setItem('customer_id', res.data.Data.CustomerId);
          localStorage.setItem('type', res.data.Data.Type);
          localStorage.setItem('elite', res.data.Data.Elite);

          window.location.href = '/projects'
      } else {
          alert(res.data.Message);
      }
  }

  return (
    <div>
      {/* {( ((props.paywallType == 0 || props.paywallType == 1 || props.paywallType == 2) && applePay && paymentRequest) ) &&
        <div className="center-horizontal">
          <PaymentRequestButtonElement className="Apple" options={{paymentRequest}} />
          <a className="tp-caption btn btn-primary btn-primary-override font-weight-semibold" style={{ color: "#fff", padding: "14px 70px", marginTop: "1rem", fontSize: 16 }} onClick={useCredit}>Use Credit/Debit Card</a>
        </div>} */}

      {(!applePay || credit) &&
       <div>
        <div className="Text-big" style={{marginTop: "36px"}}>
          Payment Information
        </div>

        <form className="form-back">
          <div>
            <label className="stripe-form-label font-primary-regular">
              Card number
              <CardNumberElement
                options={options}
                onReady={() => {
                }}
                onChange={event => {
                }}
                onBlur={() => {
                }}
                onFocus={() => {
                }}
              />
            </label>
          </div>
          
          <div>
            <label className="stripe-form-label font-primary-regular">
              Expiration date
              <CardExpiryElement
                options={options}
                onReady={() => {
                }}
                onChange={event => {
                }}
                onBlur={() => {
                }}
                onFocus={() => {
                }}
              />
            </label>
          </div>

          <div>
            <label className="stripe-form-label font-primary-regular">
              CVC
              <CardCvcElement
                options={options}
                onReady={() => {
                }}
                onChange={event => {
                }}
                onBlur={() => {
                }}
                onFocus={() => {
                }}
              />
            </label>
          </div>
        </form>

        <div className="center-horizontal" style={{marginTop: "48px"}}>
        <div>
            <ClipLoader color="#25a66a" loading={props.loading} size={24} />
        </div>
        <div>
            {props.paymentType == 2 && props.paywallType != 4 && <div className="d-flex-center">
              <label className="form-label font-primary-regular" >
                <div>
                    <input className="FormInput-check" type="checkbox" onChange={(e) => setCommercialAccess(e.target.checked)}/>
                </div>
                <div className="font-primary-regular" style={{marginLeft: "12px"}}>I want Commercial access to sell on Etsy, Ebay, or any  marketplace. ($9.99) </div>
              </label>
            </div>}

            <a hidden={props.loading} className="tp-caption btn btn-primary btn-primary-override font-weight-semibold" style={{ color: "#fff", padding: "14px 70px", fontSize: 16 }} onClick={handleConfirmOrder}>{props.paymentType == 1? "Start Free Trial" : "Purchase Now"}</a>
            <div className="term-view" style={{marginTop: "1rem"}}>
                {props.paymentType == 1? "By starting your free trial you agree to the" : "By purchasing you agree to the"} <a target="_blank" className="term" href="term">terms of service</a>
            </div>
        </div>

      </div>
      </div>}

    </div>
  );
};

export default SplitForm;