import React, { useEffect, useMemo } from "react";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import ClipLoader from "react-spinners/ClipLoader";
import axios from 'axios';
import { URL } from '../config/api';

const useOptions = () => {
  const fontSize = 16;
  const options = useMemo(
    () => ({
      showIcon: true,
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: 'Source Code Pro, monospace',
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const SplitFormUltimate = (props) => {
  const stripe = useStripe();

  const elements = useElements();
  const options = useOptions();

  useEffect(() => {
    localStorage.setItem("PAYMENT_EMAIL", props.email);
  }, [props.email]);

  useEffect(() => {
    localStorage.setItem("PAYMENT_PASSWORD", props.password);
  }, [props.password]);

  useEffect(() => {
    localStorage.setItem("PAYMENT_CONFIRM_PASSWORD", props.confirmPassword);
  }, [props.confirmPassword]);
  
  useEffect(() => {
    localStorage.setItem("PAYMENT_RECEIVE_EMAIL", props.receiveEmail);
  }, [props.receiveEmail]);

  const handleConfirmOrder = async () => {
    if (!stripe || !elements) {
      return;
    }

    props.showLoading(true);

    if (props.email == "") {
      props.showLoading(false);
      alert("Please input email.");
      return;
    }

    if (props.password == "") {
      props.showLoading(false);
      alert("Please input password.");
    }

    if (props.password != props.confirmPassword) {
      props.showLoading(false);
      alert("Please confirm password.");
      return;
    }

    const formData = new FormData();
    formData.append('Email', props.email);

    const res = await axios.post(`${URL}/stripe/create_payment_intent_ultimate.php`, formData);
    if (res.data.Success) {
      const paymentIntentId = res.data.PaymentIntentId;
      const customerId = res.data.CustomerId;

      const payload = await stripe.confirmCardPayment(res.data.ClientSecret, {
        setup_future_usage: 'off_session',
        payment_method: {
          card: elements.getElement(CardNumberElement)
        }
      });

      if (payload.error) {
        props.showLoading(false);
        alert(payload.error.message);
      } else {
        registerEbookUser(paymentIntentId, customerId);
      }
    } else {
      alert(res.data.Message);
      props.showLoading(false);
    }
  }

  const registerEbookUser = async (paymentIntentId, customerId) => {
    const email = localStorage.getItem("PAYMENT_EMAIL");
    const password = localStorage.getItem("PAYMENT_PASSWORD");
    const receiveEmail = localStorage.getItem("PAYMENT_RECEIVE_EMAIL");

    const formData = new FormData();
    formData.append('CustomerId', customerId);
    formData.append('PaymentIntentId', paymentIntentId);
    formData.append('Email', email);
    formData.append('Password', password);
    if(receiveEmail) {
      formData.append('ReceiveEmail', 1);
    } else {
      formData.append('ReceiveEmail', 0);
    }

    const res = await axios.post(`${URL}/stripe/register_ultimate_user.php`, formData);
    if (res.data.Success) {
      alert("Thank you for your purchase. You will be automatically logged into CraftersPro, and your ebook will be downloaded as well as emailed to you.");
      props.showLoading(false);
      handleAutoLogin(email, password);
    } else {
      props.showLoading(false);
      alert(res.data.Message);
    }
  }

  const handleAutoLogin = async (email, password) => {
    const bodyFormData = new FormData();
    bodyFormData.append('Email', email);
    bodyFormData.append('Password', password);

    const res = await axios.post(`${URL}/login/login.php`, bodyFormData);

    if (res.data.Success) {
      localStorage.setItem('auth', 1);
      localStorage.setItem('id', res.data.Data.Id);
      localStorage.setItem('token', res.data.Data.Token);
      localStorage.setItem('email', res.data.Data.Email);
      localStorage.setItem('next_billing', res.data.Data.NextBilling);
      localStorage.setItem('premium', res.data.Data.Premium);
      localStorage.setItem('customer_id', res.data.Data.CustomerId);
      localStorage.setItem('type', res.data.Data.Type);
      localStorage.setItem('elite', res.data.Data.Elite);

      window.location.href = '/projects'
      window.open(
        `${URL}/stripe/download_ebook.php`,
        '_blank'
      );
    } else {
      alert(res.data.Message);
    }
  }

  return (
    <div>
      <div>
        <div className="Text-big" style={{ marginTop: "36px" }}>
          Payment Information
        </div>

        <form className="form-back">
          <div>
            <label className="stripe-form-label font-primary-regular">
              Card number
              <CardNumberElement
                options={options}
                onReady={() => {
                }}
                onChange={event => {
                }}
                onBlur={() => {
                }}
                onFocus={() => {
                }}
              />
            </label>
          </div>

          <div>
            <label className="stripe-form-label font-primary-regular">
              Expiration date
              <CardExpiryElement
                options={options}
                onReady={() => {
                }}
                onChange={event => {
                }}
                onBlur={() => {
                }}
                onFocus={() => {
                }}
              />
            </label>
          </div>

          <div>
            <label className="stripe-form-label font-primary-regular">
              CVC
              <CardCvcElement
                options={options}
                onReady={() => {
                }}
                onChange={event => {
                }}
                onBlur={() => {
                }}
                onFocus={() => {
                }}
              />
            </label>
          </div>
        </form>

        <div className="center-horizontal" style={{ marginTop: "48px" }}>
          <div>
            <ClipLoader color="#25a66a" loading={props.loading} size={24} />
          </div>
          <div>

            <a hidden={props.loading} className="tp-caption btn btn-primary btn-primary-override font-weight-semibold" style={{ color: "#fff", padding: "14px 70px", fontSize: 16 }} onClick={handleConfirmOrder}>{props.paymentType == 1 ? "Start Free Trial" : "Purchase Now"}</a>
            <div className="term-view" style={{ marginTop: "1rem" }}>
              {props.paymentType == 1 ? "By starting your free trial you agree to the" : "By purchasing you agree to the"} <a target="_blank" className="term" href="term">terms of service</a>
            </div>
          </div>

        </div>
      </div>

    </div>
  );
};

export default SplitFormUltimate;