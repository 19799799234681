import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Layout from './Layout';
import SplitFormEbook from "./SplitFormEbook";
import { STRIPE_TEST_KEY_EBOOK } from '../config/api';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { URL } from '../config/api';

const stripePromise = loadStripe(STRIPE_TEST_KEY_EBOOK);
const fonts = [{ cssSrc: "https://fonts.googleapis.com/css?family=Podkova:400" }]

const Ebook = () => {
	const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [paymentType, setPaymentType] = useState(0);

	const onSignUp = () => {
		var purchaseView = document.getElementById("purchase-view");
		purchaseView.scrollIntoView({behavior: "smooth", block: "start"});
	}

	const goToSignUp = () => {
        /*setPaymentType(1);
    
        setTimeout(function() {
            var purchaseView = document.getElementById("account-info");
            purchaseView.scrollIntoView({behavior: "smooth", block: "start"});
        }, 800);*/
		window.open(
			`${URL}/stripe/download_ebook.php`,
			'_blank'
		);
 		//window.location.href = "/ebook-purchased";
	}

	const showLoading = (loading) => {
        setLoading(loading);
    }

	return (
		<Layout>
			<div role='main' className='main'>
				<div className='slider-container workshop-container-full-height rev_slider_wrapper section-1'>
					<div>
						<div>
							<img
								// id="middle-log"
								alt='EZ'
								width='50'
								src='/assets/img/white-logo.svg'
								className='font-weight-bold mb-20 appear-animation'
								data-appear-animation='fadeInUpShorter'
								data-appear-animation-delay='900'
								style={{ marginTop: '5rem' }}
							/>
						</div>
						<div>
							<img
								id='ebook'
								alt='ebook'
								src='/assets/img/white_title.png'
								className='font-weight-bold mb-32 mt-26 appear-animation'
								data-appear-animation='fadeInUpShorter'
								data-appear-animation-delay='1000'
							/>
						</div>
						<a
							className='btn btn-primary btn-primary-override font-primary-regular text-color-light appear-animation button fs-16'
							data-appear-animation='fadeInUpShorter'
							data-appear-animation-delay='1200'
							style={{ fontFamily: 'Avenir-LT-35-Light' }}
							onClick={() => onSignUp()}>
							Purchase($19.99)
						</a>
					</div>
				</div>
				<section className='workshop-container bg-light pt-3 pb-0 section-2 container'>
					<div className='text-center'>
						<p
							className='primary-color appear-animation'
							data-appear-animation='fadeInDownShorter'
							data-appear-animation-delay='1400'
							style={{ fontSize: 18 }}>
							Introducing
						</p>
						<h2
							className='font-weight-bold center primary-color font-primary-bold appear-animation'
							data-appear-animation='fadeInDownShorter'
							data-appear-animation-delay='1800'>
							The Cricut Master Guide 2023
						</h2>
						<h3
							className='font-weight-bold center primary-color font-primary-bold mb-3 appear-animation'
							data-appear-animation='fadeInDownShorter'
							data-appear-animation-delay='2200'>
							Zero to Hero
						</h3>
					</div>
					<div className='row p-3' style={{ justifyContent: 'space-around' }}>
						<div className='col-12 col-sm-6 item text-center'>
							<img src='/assets/img/book_mockup_1.png' width='80%' />
							<div
								className='font-weight-bold primary-color font-primary-bold fs-26 appear-animation mt-3'
								data-appear-animation='fadeInLeftShorter'
								data-appear-animation-delay='200'>
								The Complete Package
							</div>
							<div
								style={{ fontFamily: 'Open Sans' }}
								className='appear-animation fs-16 mt-3'
								data-appear-animation='fadeInLeftShorter'
								data-appear-animation-delay='800'>
								<p>
									This Ebook has EVERYTHING you need to go from Cricut beginner to expert. In no time you’ll be crafting amazing projects, impressing your friends and family. Expand what you thought was possible to create with your Cricut. 
								</p>
							</div>
						</div>
						<div className='col-12 col-sm-6 item text-center'>
							<img src='/assets/img/book_mockup_2.png' width='80%' />
							<div
								className='font-weight-bold primary-color font-primary-bold fs-26 appear-animation mt-3'
								data-appear-animation='fadeInRightShorter'
								data-appear-animation-delay='200'>
								Become a Cricut & Design Space Expert
							</div>
							<div
								style={{ fontFamily: 'Open Sans' }}
								className='appear-animation fs-16 mt-3'
								data-appear-animation='fadeInRightShorter'
								data-appear-animation-delay='800'>
								<p>
									In this simple yet pratical ebook, we will teach you everything you need to know about Cricut & Design Space. From beginner tutorials like selecting different materials, to more advanced teniques like welding, you will master everything. 
								</p>
							</div>
						</div>
					</div>
				</section>

				<section className='workshop-section section section-height-2 bg-green section-3'>
					<div className='container text-center' style={{ maxWidth: 900 }}>
						<div
							style={{
								fontSize: 30,
								color: '#fff',
								fontFamily: 'Poppins-ExtraLight',
							}}>
							But I'm a beginner is this for me?
						</div>
						<h2
							style={{ fontSize: 36 }}
							className='font-primary-bold appear-animation'
							data-appear-animation='fadeInUpShorter'
							data-appear-animation-delay='200'>
							This Ebook is PERFECT for a beginner to get started in crafting.
						</h2>
					</div>
				</section>
				<section className='workshop-section section-height-2 bg-light pb-5 mt-5 container section-10'>
					<div id="purchase-view" className='part3 text-center'>
						<img src='/assets/img/green_title.png' 
							className='mt-3 mb-5'/>
					</div>
					<div
						id="payment"
						className='rect-container text-center appear-animation'
						data-appear-animation='blurIn'
						data-appear-animation-delay='200'>
	
						<div className="trialback trialback-ebook">
							<img src="assets/img/best_deal.png" />
							<h6 className='font-primary-bold' style={{ color: '#5f5f5f' }}>CraftersPRO EBOOK</h6>
							<h4 className='font-primary-bold' style={{ color: '#38eca6' }}>CRICUT MASTER GUIDE</h4>
							<div style={{ lineHeight: 1, fontSize: 30, margin: 10, display: 'flex', justifyContent: 'center' }}>
								<span style={{ fontWeight: 700, color: '#38eca6', fontSize: 30 }}>
								$19.99
								</span>
							</div>

							<a className="tp-caption btn btn-green font-weight-semibold"
								style={{ color: "#fff", width: "100%", fontSize: 14, marginTop: "12px", justifyContent: "center" }}
								onClick={() => goToSignUp()}>
								Purchase Now
							</a>
							<hr style={{ marginLeft: '-2rem', marginRight: '-2rem', borderTop: '2px solid rgba(0,0,0,.1)' }} />
							
							<img src='/assets/img/cover.png'
								className='w30'/>

							<div className="fs-16 font-primary-regular mt-20">Comprehensive guide for Cricut beginners to experts</div>
							<div className="fs-16 font-primary-regular mt-12">Unlock the potential of your Cricut machine</div>
							<div className="fs-16 font-primary-regular mt-12">Simple and practical ebook</div>
							<div className="fs-16 font-primary-regular mt-12">Covers everything about Cricut and Design Space</div>
							<div className="fs-16 font-primary-regular mt-12">Beginner tutorials on material selection</div>
							<div className="fs-16 font-primary-regular mt-12 mb-16">Master the art of using Cricut and Design Space</div>
						</div>
					</div>
				</section>

				{paymentType == 1 && 
				<section id="account-info" className="section-4 container" style={{marginTop: "16px"}}>
					<div>
						<div className="center-horizontal fs-16 font-primary-regular" style={{marginBottom: "16px"}}>
							$19.99/One time purchase
						</div>

						<h2 className="font-primary-regular fs-20">
							Account Information
						</h2>

						<div className="font-primary-regular fs-12">
							*required
						</div>

						<form className="form-back">
							<label className="stripe-form-label font-primary-regular">
							*Email Address
							<input className="form-input font-primary-regular" type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)}/> 
							</label>
						</form>
					</div>
					<Elements stripe={stripePromise} fonts={{fonts}}>
						<SplitFormEbook
							email={email}
							showLoading={showLoading}
							loading={loading}
						/>
					</Elements>
				</section>
				}
				{paymentType == 1 && 
				<section className='workshop-section section section-height-2 bg-green section-3'>
					<div className='container text-center' style={{ maxWidth: 900 }}>
						<h2
							style={{ fontSize: 36 }}
							className='font-primary-bold'>
							Purchase Protection - Refund Guaranteed
						</h2>
					</div>
				</section>
				}

				<section className='workshop-section section-height-2 bg-light pb-5 mt-5 container section-11'>
					<div className='text-center'>
						<h5 style={{color: '#5f5f5f'}} className='font-primary-regular mb-0'>Make crafts like this with</h5>
						<h3
							className='font-primary-bold primary-color'>
							CRICUT MASTER GUIDE
						</h3>
						<img
							alt='master guide'
							src='/assets/img/pic.png'
							className='mb-32 appear-animation w80'
							data-appear-animation='fadeInUpShorter'
							data-appear-animation-delay='1000'
						/>

						<img
							alt='master guide'
							src='/assets/img/brandonimage.png'
							className='mt-60 mb-32 appear-animation w70'
							data-appear-animation='fadeInUpShorter'
							data-appear-animation-delay='1000'
						/>
						<h3
							className='font-primary-bold primary-color center w70'>
							WORKS WITH ALL SOFTWARE
						</h3>
						<h5 style={{color: '#5f5f5f'}} className='font-primary-regular mb-0 w70 center'>No matter which software you prefer, we provide all the necessary tools for you to create anything your heart wishes for.</h5>
					</div>
				</section>

				<section className='workshop-section section section-height-2 bg-light section-12'>
					<div className='container text-center'>
						<h2 className='font-primary-bold mb-0'>Frequently Asked</h2>
						<p
							className='font-primary-bold primary-color'
							style={{ fontSize: 26 }}>
							Questions
						</p>
						<div className='row'>
							<div className='col-md-6 right-border'>
								<h6
									className='font-primary-bold primary-color'
									style={{ fontSize: 20 }}>
									I am completely new to Cricut. I haven’t used my machine yet. Will this Ebook
help me?
								</h6>
								<p
									className='font-primary-extra-light'
									style={{ fontWeight: 'bold', fontSize: 15 }}>
									Absolutely! In fact, we designed this Ebook specifically for beginners. It has
everything you need from quick reference guides to full tutorials.
								</p>
							</div>
							<div className='col-md-6'>
								<h6
									className='font-primary-bold primary-color'
									style={{ fontSize: 20 }}>
									Does this Ebook also cover Design Space?
								</h6>
								<p
									className='font-primary-extra-light'
									style={{ fontWeight: 'bold', fontSize: 15 }}>
									Yes. In fact, the majority of the content is spent learning the ins and outs of Design
Space. 
								</p>
							</div>
							<div className='col-md-6 right-border'>
								<h6
									className='font-primary-bold primary-color'
									style={{ fontSize: 20 }}>
									Which devices are compatible with the tutorials found in the Ebook?
								</h6>
								<p
									className='font-primary-extra-light'
									style={{ fontWeight: 'bold', fontSize: 15 }}>
									This Ebook is for all iPhones, Macs, and Windows Computers. 
								</p>
							</div>
							<div className='col-md-6'>
								<h6
									className='font-primary-bold primary-color'
									style={{ fontSize: 20 }}>
									I have an older Cricut model. Will this Ebook still be of help?
								</h6>
								<p
									className='font-primary-extra-light'
									style={{ fontWeight: 'bold', fontSize: 15 }}>
									Yes, for sure. Because the majority of the content focuses on Design Space, any
Cricut machine will be of use. 
								</p>
							</div>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default Ebook;
