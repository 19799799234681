const RefundGuarantee = () => {
	return (
        <div id="refund-section" className='guarantee align-items-center w100'>
            <div className='font-primary-extra-light text-center color-white text-5'>
                Purchase Protection
            </div>
            <div className='font-primary-bold text-center color-white text-5'>
                Refund Guarantee
            </div>
        </div>
    )
};

export default RefundGuarantee;
