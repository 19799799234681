import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Layout from './Layout';
import SplitFormUltimate from "./SplitFormUltimate";
import { STRIPE_TEST_KEY_EBOOK } from '../config/api';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useDispatch } from 'react-redux';
import { showLogin } from '../redux/actions/appActions';
import {isMobile} from 'react-device-detect';
import { SalesCountdown } from '../components/SalesCountdown';

const stripePromise = loadStripe(STRIPE_TEST_KEY_EBOOK);
const fonts = [{ cssSrc: "https://fonts.googleapis.com/css?family=Podkova:400" }]

const PaywallUltimate = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [receiveEmail, setReceiveEmail] = useState(true);
    const [isPurchase, setIsPurchase] = useState(false);

    const loggedIn = localStorage.getItem('auth');
    const premium = localStorage.getItem('premium');  
    const history = useHistory();

    useEffect(() => {
        if(premium == 1) {
            history.push('/projects');
            return;
        }
        window.scrollTo(0, 0);
    }, [])

    const showLoading = (loading) => {
        setLoading(loading);
    }
    
    const handleLogin = () => {
        dispatch(showLogin());
    }

    const handlePurchase = () => {
        setIsPurchase(true);
        setTimeout(function() {
            var purchaseView = document.getElementById("account-info");
            purchaseView.scrollIntoView({behavior: "smooth", block: "start"});
        }, 1000);
    }

    return (
        <Layout>
            <div role="main" className="main paywall">
                <section className="section-1" style={{ marginTop: '8rem' }}>
                    <div className='text-center'>
                        <h2
                            className='font-weight-bold center primary-color font-primary-bold appear-animation'
                            data-appear-animation='fadeInDownShorter'
                            data-appear-animation-delay='400'>
                            Ultimate Starter Pack
                        </h2>
                    </div>
                    <img src="/assets/img/master_guide.png" width="240px" height="auto" className="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="800" style={{ animationDuration: '2000ms'}} />
                    <p className='font-weight-semibold font-primary-bold' style={{ fontSize: '17px',  lineHeight: 1.9, color: '#000', margin: '0.5rem auto' }}>Lifetime Membership to CraftersPro<br/>Step by Step Ebook Guide<br/>Projects, Fonts, and Cut Files	</p>
                </section>

                <section id="payment" className="section-2">
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <div className="trialback1">
                            <img className='w-100' src="assets/img/ulti_payment_box_price.png" />
                            <div className="ultimate-item ml-16 mt-32">
                                <div style={{width: "28px"}} className="d-flex-center">
                                    <img src="/assets/img/Checkmark.png" style={{width: '28px', height: '28px'}} />
                                </div>
                                <div className="font-primary-regular color-light-black fs-15 ml-8">Unlimited use of all projects</div>
                            </div>
                            <div className="ultimate-item ml-16">
                                <div style={{width: "28px"}} className="d-flex-center">
                                    <img src="/assets/img/Checkmark.png" style={{width: '28px', height: '28px'}} />
                                </div>
                                <div className="font-primary-regular color-light-black fs-15 ml-8">Speedy support</div>
                            </div>
                            <div className="ultimate-item ml-16">
                                <div style={{width: "28px"}} className="d-flex-center">
                                    <img src="/assets/img/Checkmark.png" style={{width: '28px', height: '28px'}} />
                                </div>
                                <div className="font-primary-regular color-light-black fs-15 ml-8">Access forever</div>
                            </div>
                            <div className="ultimate-item ml-16">
                                <div style={{width: "28px"}} className="d-flex-center">
                                    <img src="/assets/img/Checkmark.png" style={{width: '28px', height: '28px'}} />
                                </div>
                                <div className="font-primary-regular color-light-black fs-15 ml-8">Never pay monthly or yearly</div>
                            </div>
                            <a className="tp-caption btn btn-ultimate font-weight-semibold mb-32"
                                style={{ color: "#fff", width: "80%", fontSize: 14, marginTop: "12px", justifyContent: "center" }}
                                onClick={() => handlePurchase()}
                                >
                                PURCHASE NOW
                            </a>
                        </div>
                    </div>
                </section>

                <div className="row" style={{ justifyContent: 'center' }}>
                    <div className="row" style={{ justifyContent: "center" }}>
                        <img src="/assets/img/lightning.png" style={{width: '24px', height: 'auto', objectFit: 'contain', marginRight: '2px'}} alt="icon" />
                        <p style={{ fontFamily: 'Open Sans', fontSize: 18, fontWeight: 'bold', margin: '1rem 0', textAlign: "center" }}>
                            Limited time offer. Sales ends in
                        </p>
                    </div>
                </div>
                <div className="row" style={{ justifyContent: 'center' }}>
                    <SalesCountdown />
                </div>

                <section className="section-1" style={{ marginTop: '2rem' }}>
                    <div className='text-center'>
                        <h2
                            className='font-weight-bold center primary-color font-primary-bold appear-animation'
                            data-appear-animation='fadeInDownShorter'
                            data-appear-animation-delay='400'>
                            E-Book Included
                        </h2>
                    </div>
                    <img src="/assets/img/book_mockup_1.png" width="220px" height="auto" className="appear-animation mt-12 mb-16" data-appear-animation="fadeInUp" data-appear-animation-delay="800" style={{ animationDuration: '2000ms'}} />
                    <p className='font-weight-semibold font-primary-bold' style={{ fontSize: '17px',  lineHeight: 1.9, color: '#000', margin: '0.5rem auto' }}>Over 100 pages<br/>Master Cricut & Design Space<br/>Specifically made for beginners</p>
                    <hr style={{ marginBottom: '0', marginTop: '1rem', marginLeft: '-2rem', marginRight: '-2rem', borderTop: '2px solid rgba(0,0,0,.1)' }} />
                </section>

                {isPurchase &&
                <section id="account-info" className="section-4 container" style={{marginTop: "16px"}}>
                        { loggedIn != 1 ?
                            <div>
                                <h2 className="font-primary-regular fs-20">
                                    Account Information
                                </h2>

                                <div className="font-primary-regular fs-12">
                                    *required
                                </div>

                                <div style={{float: 'right', marginTop: '-18px'}} className="fs-12">
                                    <a className="font-primary-regular" style={{color:"#747474"}} href="/payment">Back</a>
                                </div>

                                <form className="form-back">
                                    <label className="stripe-form-label font-primary-regular">
                                    *Email Address
                                    <input className="form-input font-primary-regular" type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)}/> 
                                    </label>

                                    <label className="stripe-form-label font-primary-regular">
                                    *Password
                                    <input className="form-input font-primary-regular" type="password" placeholder="Enter password" onChange={(e) => setPassword(e.target.value)}/> 
                                    </label>
                                    
                                    
                                    <label className="stripe-form-label font-primary-regular">
                                    *Confirm Password
                                    <input className="form-input font-primary-regular" type="password" placeholder="Enter confirm password" onChange={(e) => setConfirmPassword(e.target.value)}/> 
                                    </label>

                                    <label className="form-label font-primary-regular" >
                                    <div>
                                        <input className="FormInput-check" type="checkbox" defaultChecked={true} onChange={(e) => setReceiveEmail(e.target.checked)}/>
                                    </div>
                                    <div className="font-primary-regular" style={{marginLeft: "12px"}}>I agree to receive instructional and promotional emails </div>
                                    </label>
                                </form>
                            </div> : null
                        }
                            
                        <Elements stripe={stripePromise} fonts={{fonts}}>
                            <SplitFormUltimate
                                email={email}
                                password={password}
                                confirmPassword={confirmPassword}
                                receiveEmail={receiveEmail}
                                showLoading={showLoading}
                                loading={loading}
                            />
                        </Elements>

                        { loggedIn != 1 ?                 
                        <div className="center-horizontal" style={{marginTop: "24px"}}>
                            <a className="tp-caption btn font-weight-semibold" style={{ color: "#707070", backgroundColor: "#ffffffff", padding: "10px 40px", fontSize: 16 }} onClick={handleLogin}>
                                Login
                            </a>
                        </div> : null
                        }
                </section>}

                <section className="section-2">
                    <div className='text-center mb-32'>
                        <h2
                            className='font-weight-bold center primary-color font-primary-bold appear-animation'
                            data-appear-animation='fadeInDownShorter'
                            data-appear-animation-delay='400'>
                            CraftersPro Lifetime<br/>Membership:
                        </h2>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-4">
                                <img src="/assets/img/tons.png" 
                                className="appear-animation"
                                data-appear-animation="fadeInLeft"
                                data-appear-animation-delay="800" 
                                style={{ animationDuration: '2000ms'}} />
                                <h5 style={{marginTop: "8px"}}>Tons of Projects</h5>
                                <p>Projects ready for<br/>any occasion</p>
                            </div>
                            <div className="col-12 col-sm-4">
                                <img src="/assets/img/stickers.png" 
                                className="appear-animation"
                                data-appear-animation="fadeInUp"
                                data-appear-animation-delay="1200" style={{ animationDuration: '2000ms'}}/>
                                <h5 style={{marginTop: "8px"}}>Cut Files</h5>
                                <p>Hand Crafted & Royalty Free</p>
                            </div>
                            <div className="col-12 col-sm-4">
                                <img src="/assets/img/fonts.png" 
                                className="appear-animation"
                                data-appear-animation="fadeInRight"
                                data-appear-animation-delay="1600" style={{ animationDuration: '2000ms'}}/>
                                <h5 style={{marginTop: "8px"}}>Tons of Fonts</h5>
                                <p>400+ Designer<br/>Fonts </p>
                            </div>
                        </div>
                    </div>
                </section>

                <div style={{marginTop: "16px"}}>
                    <div className="BarView">
                        <div className="Bar" />
                    </div>
                </div>

                <section className="section-2">
                    <div className="container">
                        <div id="meet-section" className="mb-32 mt-20">
                            <div className='font-primary-bold text-center primary-color text-6'>
                                Meet The Team
                            </div>

                            <div className='font-primary-bold text-center text-5 mt-20'>
                                A <span className='primary-color'>Collection</span> of Outstanding Talent
                            </div>

                            <div className='font-primary-regular text-center text-3'>
                                CraftersPro is fortunate to have a group of competent and caring professionals, <br/> working together to provide services to our CraftersPro community
                            </div>
                        </div>

                        <div className="row mb-32">
                            <div className='col-4 col-lg-4 text-left'>
                                <div className="payment-member-container appear-animation"
                                    data-appear-animation="fadeInLeft"
                                    data-appear-animation-delay="200" 
                                    style={{ animationDuration: '2000ms'}}>
                                    <img src={'/assets/img/about_avatar_1.png'} width='100%' />
                                    <div
                                        className='text-1 mt-20 text-left ml-16'
                                        style={{ lineHeight: '14px' }}>
                                        Project Creator
                                    </div>
                                    <div className='font-weight-bold text-3 center font-primary-bold mt-8 ml-16 text-left'>
                                        Kendall Dobbins
                                    </div>
                                </div>
                            </div>

                            <div className='col-4 col-lg-4 text-left'>
                                <div className="payment-member-container appear-animation"
                                    data-appear-animation="fadeInUp"
                                    data-appear-animation-delay="400" 
                                    style={{ animationDuration: '2000ms'}}>
                                    <img src={'/assets/img/about_avatar_2.png'} width='100%' />
                                    <div
                                        className='text-1 mt-20 text-left ml-16'
                                        style={{ lineHeight: '14px' }}>
                                        Cut File Creator
                                    </div>
                                    <div className='font-weight-bold text-3 center font-primary-bold mt-8 ml-16 text-left'>
                                        Natalija
                                    </div>
                                </div>
                            </div>

                            <div className='col-4 col-lg-4 text-left'>
                                <div className="payment-member-container appear-animation"
                                    data-appear-animation="fadeInRight"
                                    data-appear-animation-delay="600" 
                                    style={{ animationDuration: '2000ms'}}>
                                    <img src={'/assets/img/about_avatar_3.png'} width='100%' />
                                    <div
                                        className='text-1 mt-20 text-left ml-16'
                                        style={{ lineHeight: '14px' }}>
                                        Customer Service Pro
                                    </div>
                                    <div className='font-weight-bold text-3 center font-primary-bold mt-8 ml-16 text-left'>
                                        Veronica Santos
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        </Layout>
    );
}

export default PaywallUltimate;