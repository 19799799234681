import {
    SHOW_LOGIN,
    CLOSE_LOGIN,
    RESET_PASSWORD,
    CLOSE_RESET_PASSWORD,
    SHOW_LOGIN_LIVEWORKSHOP
} from '../actions/appActions';

const initialState = {
    loginVisible: false,
    resetVisible: false,
    liveWorkshop: 0,
};

export default function(state = initialState, action) {
    switch(action.type) {
        case SHOW_LOGIN:
            return { ...state, loginVisible: true };
        case CLOSE_LOGIN:
            return { ...state, loginVisible: false, liveWorkshop: false };
        case RESET_PASSWORD:
            return { ...state, loginVisible: false, resetVisible: true };
        case CLOSE_RESET_PASSWORD:
            return { ...state, resetVisible: false };
        case SHOW_LOGIN_LIVEWORKSHOP:
            return { ...state, loginVisible: true, liveWorkshop: true };
        default:
            return state;
    }
}