import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Layout from './Layout';

const LiveWorkshop = () => {
    const userType = localStorage.getItem('type');

	const onSignUp = () => {
		var purchaseView = document.getElementById("purchase-view");
		purchaseView.scrollIntoView({behavior: "smooth", block: "start"});
	}

	const goToSignUp = () => {
		window.open("liveworkshop-payment/", '_blank');
	}

	if (userType == 7) {
		return (
			<Layout>
				<div role="main" className="main" style={{ paddingTop: 140 }}>
					<div className="container text-center" style={{marginBottom: '120px'}}>
						<img className='mt-60' alt="EZ" width="62" height="62" src="/assets/img/logo_green.png" />

						<div className="text-6 center primary-color font-primary-bold mt-48">
							You have already purchased the live workshop!
						</div>

						<div className="text-3 center font-primary-extra-light mt-32" style={{color: '#5f5f5f'}}>
							Details for your live workshop purchase:
						</div>

						<div className="text-6 center font-primary-extra-light" style={{color: '#5f5f5f'}}>
							Saturday May 13th at 12:00PM EST
						</div>

						<div className="text-5 center font-primary-regular" style={{marginTop: '80px'}}>
							Email us if you have any questions
						</div>

						<a className="text-5 center font-primary-bold primary-color" href="mailto:liveworkshop@crafterspro.net" style={{textDecoration: 'underline'}}>
							liveworkshop@crafterspro.net
						</a>
					</div>
				</div>
			</Layout>
		);
	} else {
		return (
			<Layout workshop>
				<div role='main' className='main'>
					<div className='slider-container workshop-container-full-height rev_slider_wrapper section-1'>
						<div>
							<div>
								<img
									// id="middle-log"
									alt='EZ'
									width='50'
									src='/assets/img/white-logo.svg'
									className='font-weight-bold mb-20 appear-animation'
									data-appear-animation='fadeInUpShorter'
									data-appear-animation-delay='900'
									style={{ marginTop: '5rem' }}
								/>
							</div>
							<div>
								<img
									id='workshop'
									alt='workshop'
									src='/assets/img/workshop_title.png'
									className='font-weight-bold mb-32 mt-26 appear-animation'
									data-appear-animation='fadeInUpShorter'
									data-appear-animation-delay='1000'
								/>
							</div>
							<div>
								<img
									id='workshop-date'
									alt='workshop'
									src='/assets/img/may_6th.png'
									className='font-weight-bold mb-20 appear-animation'
									data-appear-animation='fadeInUpShorter'
									data-appear-animation-delay='1100'
								/>
							</div>
							<a
								className='btn btn-primary btn-primary-override font-primary-regular text-color-light appear-animation button fs-16'
								data-appear-animation='fadeInUpShorter'
								data-appear-animation-delay='1200'
								style={{ fontFamily: 'Avenir-LT-35-Light' }}
								onClick={() => onSignUp()}>
								Sign Up Now
							</a>
						</div>
					</div>
					<section className='workshop-container bg-light pt-3 pb-0 section-2 container'>
						<div className='text-center'>
							<p
								className='primary-color appear-animation'
								data-appear-animation='fadeInDownShorter'
								data-appear-animation-delay='1400'
								style={{ fontSize: 18 }}>
								Introducing
							</p>
							<h2
								className='font-weight-bold center primary-color font-primary-bold mb-3 appear-animation'
								data-appear-animation='fadeInDownShorter'
								data-appear-animation-delay='1800'>
								6 Hour Workshop of Cricut Projects, Tutorials, & More
							</h2>
						</div>
						<div className='row p-3' style={{ justifyContent: 'space-around' }}>
							<div className='col-12 col-sm-6 item text-center'>
								<img src='/assets/img/workshop_avatar1.png' width='80%' />
								<div
									className='font-weight-bold primary-color font-primary-bold fs-26 appear-animation'
									data-appear-animation='fadeInLeftShorter'
									data-appear-animation-delay='200'>
									Create Projects with Our CraftersPro Team
								</div>
								<div
									style={{ fontFamily: 'Open Sans' }}
									className='appear-animation fs-16'
									data-appear-animation='fadeInLeftShorter'
									data-appear-animation-delay='800'>
									<p>
										In our workshop, you will be creating new projects curated for
										beginners. This will teach you not only how to use every part
										of your Cricut and Design Space, but how to creatively think
										about new projects to make.
									</p>
								</div>
							</div>
							<div className='col-12 col-sm-6 item text-center'>
								<img src='/assets/img/workshop_avatar2.png' width='80%' />
								<div
									className='font-weight-bold primary-color font-primary-bold fs-26 appear-animation'
									data-appear-animation='fadeInRightShorter'
									data-appear-animation-delay='200'>
									All Materials Sent To You
								</div>
								<div
									style={{ fontFamily: 'Open Sans' }}
									className='appear-animation fs-16'
									data-appear-animation='fadeInRightShorter'
									data-appear-animation-delay='800'>
									<p>
										Everything you need to create our projects will be sent to
										you. From the Vinyl to the Mugs, you will have everything you
										need. All you need is your Cricut Machine.
									</p>
								</div>
							</div>
						</div>
					</section>

					<section className='workshop-section section-height-2 bg-light pb-5 mt-5 container section-10'>
						
						<hr style={{ borderTop: "1px solid #9aaeb8" }} />
						<div className='part2 text-center mt-32'>
							<img src='/assets/img/workshop_green.png' />
							<img
								src='/assets/img/may_6th_bottom.png'
								className='mt-3 mb-5'
							/>
						</div>
						<div
							id="payment"
							className='rect-container text-center appear-animation'
							data-appear-animation='blurIn'
							data-appear-animation-delay='200'>
		
							<div className="trialback">
								<img src="assets/img/best_deal.png" />
								<h2 style={{ marginBottom: 0, color: '#38eca6' }}>Live Workshop Access</h2>
								<div style={{ lineHeight: 1, fontSize: 20, margin: 10, display: 'flex', justifyContent: 'center' }}>
									$<span style={{ fontWeight: 700, color: '#38eca6', fontSize: 45, marginTop: -3 }}>
									<span className="line-through">397</span> $97
									</span>
								</div>

								<div>Lifetime Membership Included</div>
								<a className="tp-caption btn btn-green font-weight-semibold"
									style={{ color: "#fff", width: "100%", fontSize: 14, marginTop: "12px", justifyContent: "center" }}
									onClick={() => goToSignUp()}>
									Purchase Now
								</a>
								<hr style={{ marginLeft: '-2rem', marginRight: '-2rem', borderTop: '2px solid rgba(0,0,0,.1)' }} />
								<div className="item">6 Hours of Crafting, Creating & Learning</div>
								<div className="item">Master Your Cricut & Design Space</div>
								<div className="item">Materials Sent to Your Doorstep</div>
							</div>
						</div>
					</section>

					<section className='workshop-section section section-height-2 bg-green section-3'>
						<div className='container text-center' style={{ maxWidth: 900 }}>
							<div
								style={{
									fontSize: 30,
									color: '#fff',
									fontFamily: 'Poppins-ExtraLight',
								}}>
								But I'm a beginner is this for me?
							</div>
							<h2
								style={{ fontSize: 36 }}
								className='font-primary-bold appear-animation'
								data-appear-animation='fadeInUpShorter'
								data-appear-animation-delay='200'>
								YES! This Workshop is perfect for a beginner to get started in
								crafting.
							</h2>
						</div>
					</section>
					<section id="hosted-by" className='workshop-section section section-height-2 bg-light-5 self-intro'>
						<div className='row bg-light'>
							<div className='col-12 col-sm-5 img'>
								<img src='/assets/img/brandon_img.png' width='100%' />
							</div>
							<div
								className='col-12 col-sm-7'
								style={{ display: 'flex', alignItems: 'center' }}>
								<div
									className='row appear-animation'
									data-appear-animation='fadeInRightShorter'
									data-appear-animation-delay='200'>
									<div className='col-12 col-sm-6 name'>
										<span
											style={{ fontSize: 17 }}
											className='primary-color font-primary-regular'>
											Hosted By:
										</span>
										<div
											style={{ fontSize: 53, lineHeight: '0.8em' }}
											className='font-primary-bold'>
											Brandon <span className='primary-color'>Harris</span>
										</div>
									</div>
									<div className='col-12 col-sm-6 description'>
										<div
											style={{
												lineHeight: 2,
												fontFamily: 'Avenir-LT-35-Light',
												fontSize: 18,
											}}>
											Hey I’m Brandon! I’m passionate about crafting and creating
											projects. As someone who enjoys using their hands and
											creative skills to make unique items, I have created
											countless Cricut projects. As an entrepreneur at heart,
											selling items online is something I’m very familiar with as
											well. Above all, I’m driven by the joy of making something
											with my own hands and the satisfaction of seeing my ideas
											come to life.
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className='workshop-section section-height-2 bg-light pb-5 pt-5 container section-6 mb-3'>
						<div className='row'>
							<div className='col-12 col-sm-6 mb-3 overflow-hidden opacity-8'>
								<p
									style={{
										fontFamily: 'Reklame-Medium',
										fontSize: 43,
										lineHeight: 1.2,
									}}
									className='primary-color mb-3 appear-animation'
									data-appear-animation='fadeInUpShorter'
									data-appear-animation-delay='500'>
									Are you ready to master your Cricut and Create stellar projects?
								</p>
								<div
									style={{ width: 80, height: 3, backgroundColor: '#8e8e8f' }}
									className='mb-3'
								/>
								<div className='font-primary-bold' style={{ fontSize: 18 }}>
									In this workshop you will:
								</div>
								<ul
									className='font-primary-extra-light list'
									style={{ fontSize: 16 }}>
									<li>Learn to Master your Cricut machine & Design Space</li>
									<li>
										Create actual, useful projects that will impress your friends
										& family
									</li>
									<li>Have ALL materials sent to you before the workshop</li>
									<li>Have fun and be inspired to create more & more.</li>
								</ul>
								<div
									style={{ fontFamily: 'Reklame-Medium', fontSize: 22 }}
									className='primary-color appear-animation'
									data-appear-animation='fadeInUpShorter'
									data-appear-animation-delay='800'>
									-CraftersPro Team
								</div>
								<a
									className='btn btn-primary btn-primary-override font-primary-regular text-color-light button fs-16'
									style={{
										fontFamily: 'Avenir-LT-35-Light',
										padding: '10px 80px 3px',
									}}
									onClick={() => onSignUp()}>
									Sign Up Now - Limited Spots
								</a>
							</div>
							<div
								className='col-12 col-sm-6 text-center appear-animation'
								data-appear-animation='fadeInLeftShorter'
								data-appear-animation-delay='2500'>
								<img src='/assets/img/6.webp' width='80%' />
							</div>
						</div>
					</section>
					<section
						id="workshop-details"
						className='workshop-section section-height-2 pb-0 pt-4 section-7'
						style={{ backgroundColor: '#ededee' }}>
						<div className='container'>
							<h2
								className='text-center font-primary-extra-light mb-5 appear-animation'
								style={{ fontStyle: 'italic', fontSize: 36 }}
								data-appear-animation='fadeInDownShorter'
								data-appear-animation-delay='200'>
								Workshop Details
							</h2>
							<div className='row'>
								<div
									className='col-12 col-sm-6 item mt-4 appear-animation'
									data-appear-animation='fadeInLeftShorter'
									data-appear-animation-delay='400'>
									<img
										src='/assets/img/Cricut-machines-group-27de758.webp'
										width='100%'
									/>
								</div>
								<div className='col-12 col-sm-6 item'>
									<div style={{ fontFamily: 'Open Sans' }}>
										<div
											className='primary-color font-primary-extra-light appear-animation'
											style={{ fontWeight: 'bold' }}
											data-appear-animation='fadeInLeftShorter'
											data-appear-animation-delay='400'>
											Part One
										</div>
										<h2
											className='font-primary-bold appear-animation'
											data-appear-animation='fadeInLeftShorter'
											data-appear-animation-delay='400'>
											Master Your Cricut & Design Space
										</h2>
										<p
											className='font-primary-extra-light appear-animation'
											style={{ fontWeight: 'bold', fontSize: 14 }}
											data-appear-animation='fadeInLeftShorter'
											data-appear-animation-delay='600'>
											Unlock the full potential of your Cricut machine and elevate
											your crafting expertise to new heights with our
											comprehensive online workshop. You will gain a mastery of
											every aspect of your Cricut machine, and gain the necessary
											expertise to proficiently navigate Design Space. You'll
											discover new techniques and strategies to craft
											extraordinary designs with ease. Remember, this is for
											beginners! We will provide you with the confidence to create
											intricate, personalized crafts that will leave an indelible
											impression on those who behold them. By joining our online
											workshop, you will unlock the power of your Cricut machine,
											unleash your creativity, and enter into a world of limitless
											crafting possibilities.
										</p>
									</div>
								</div>
							</div>
							<div className='row mobile-hidden'>
								<div className='col-12 col-sm-6 item'>
									<div style={{ fontFamily: 'Open Sans' }}>
										<div
											className='primary-color font-primary-extra-light appear-animation'
											style={{ fontWeight: 'bold' }}
											data-appear-animation='fadeInRightShorter'
											data-appear-animation-delay='200'>
											Part Two
										</div>
										<h2
											className='font-primary-bold appear-animation'
											data-appear-animation='fadeInRightShorter'
											data-appear-animation-delay='200'>
											Create Projects Together
										</h2>
										<p
											className='font-primary-extra-light appear-animation'
											style={{ fontWeight: 'bold', fontSize: 14 }}
											data-appear-animation='fadeInRightShorter'
											data-appear-animation-delay='400'>
											When you purchase our online workshop, you'll have the
											opportunity to join forces with our expert host, Brandon,
											and embark on a journey of crafting excellence with your
											Cricut machine. Together, you'll create innovative,
											personalized projects that showcase your unique style and
											creativity. Don't miss out on the chance to collaborate and
											create!
										</p>
									</div>
								</div>
								<div
									className='col-12 col-sm-6 item mt-4 appear-animation'
									data-appear-animation='fadeInRightShorter'
									data-appear-animation-delay='200'>
									<img src='/assets/img/1632336131.webp' width='100%' />
								</div>
							</div>
							<div className='row desktop-hidden'>
								<div
									className='col-12 col-sm-6 item mt-4 appear-animation'
									data-appear-animation='fadeInRightShorter'
									data-appear-animation-delay='200'>
									<img src='/assets/img/1632336131.webp' width='100%' />
								</div>
								<div className='col-12 col-sm-6 item'>
									<div style={{ fontFamily: 'Open Sans' }}>
										<div
											className='primary-color font-primary-extra-light appear-animation'
											style={{ fontWeight: 'bold' }}
											data-appear-animation='fadeInRightShorter'
											data-appear-animation-delay='200'>
											Part Two
										</div>
										<h2
											className='font-primary-bold appear-animation'
											data-appear-animation='fadeInRightShorter'
											data-appear-animation-delay='200'>
											Create Projects Together
										</h2>
										<p
											className='font-primary-extra-light appear-animation'
											style={{ fontWeight: 'bold', fontSize: 14 }}
											data-appear-animation='fadeInRightShorter'
											data-appear-animation-delay='400'>
											When you purchase our online workshop, you'll have the
											opportunity to join forces with our expert host, Brandon,
											and embark on a journey of crafting excellence with your
											Cricut machine. Together, you'll create innovative,
											personalized projects that showcase your unique style and
											creativity. Don't miss out on the chance to collaborate and
											create!
										</p>
									</div>
								</div>
							</div>
							<div className='row'>
								<div
									className='col-12 col-sm-6 item mt-4 appear-animation'
									data-appear-animation='fadeInLeftShorter'
									data-appear-animation-delay='400'>
									<img
										src='/assets/img/How-to-make-boxes-with-your-Cricut-87-720x720.webp'
										width='100%'
									/>
								</div>
								<div className='col-12 col-sm-6 item'>
									<div style={{ fontFamily: 'Open Sans' }}>
										<div
											className='primary-color font-primary-extra-light appear-animation'
											style={{ fontWeight: 'bold' }}
											data-appear-animation='fadeInLeftShorter'
											data-appear-animation-delay='400'>
											Part Three
										</div>
										<h2
											className='font-primary-bold appear-animation'
											data-appear-animation='fadeInLeftShorter'
											data-appear-animation-delay='400'>
											All Materials Sent to Your Doorstep
										</h2>
										<p
											className='font-primary-extra-light appear-animation'
											style={{ fontWeight: 'bold', fontSize: 14 }}
											data-appear-animation='fadeInLeftShorter'
											data-appear-animation-delay='600'>
											By purchasing our online workshop, you'll receive everything
											you need to participate in the crafting fun from the comfort
											of your own home. We'll send you a package filled with all
											the crafting materials and tools necessary to complete the
											projects featured in the workshop. You won't have to worry
											about gathering supplies or running to the store - we've got
											you covered. With our materials in hand and our expert host,
											you'll be able to jump right into creating fantastic Cricut
											projects with ease. Join us today and let us take care of
											everything, so you can focus on crafting and unleashing your
											creative potential.
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section id="materials" className='workshop-section section-height-2 pb-0 pt-4 container section-8 text-center'>
						<h2 className='font-primary-bold' style={{ fontSize: 36 }}>
							No <span className='primary-color'>Materials</span> No{' '}
							<span className='primary-color'>Problem! Yes, Really!!</span>
						</h2>
						<div className='font-primary-bold' style={{ fontSize: 18 }}>
							Our online workshop provides everything you need to create fantastic
							Cricut projects at home
						</div>
						<div
							className='font-primary-extra-light mb-3'
							style={{ fontSize: 18 }}>
							<div>
								<span>&#x2022;&nbsp;&nbsp;</span> When you purchase the workshop,
								we'll send you a package of crafting materials and tools
							</div>
							<div>
								<span>&#x2022;&nbsp;&nbsp;</span> You won't have to worry about
								gathering supplies or running to the store
							</div>
							<div>
								<span>&#x2022;&nbsp;&nbsp;</span> With all the materials in hand,
								you can jump right into creating amazing projects
							</div>
							<div>
								<span>&#x2022;&nbsp;&nbsp;</span> Our expert host will guide you
								through the process and help you unlock your creative potential
							</div>
						</div>
						<img
							className='appear-animation'
							src='/assets/img/123.webp'
							data-appear-animation='fadeInDownShorter'
							data-appear-animation-delay='200'
						/>
					</section>
					<section className='workshop-section section section-height-2 bg-green section-9 text-center mb-2'>
						<div className='container text-center'>
							<h2 className='font-primary-bold'>
								Every Workshop Purchase Includes
							</h2>
							<img
								src='/assets/img/LIFETIME.webp'
								className='lifetime appear-animation'
								data-appear-animation='fadeInDownShorter'
								data-appear-animation-delay='200'
							/>
							<img
								src='/assets/img/Group 1.webp'
								className='desktop mt-5 appear-animation'
								data-appear-animation='fadeInUpShorter'
								data-appear-animation-delay='300'
							/>
							<div className='row'>
								<div className='col-12 col-sm-4 item text-center'>
									<img src='/assets/img/gf x.webp' width='100%' />
									<div className='font-primary-extra-light fs-18'>
										Commercial Free Licensed fonts by us!
									</div>
								</div>
								<div className='col-12 col-sm-4 item text-center'>
									<img src='/assets/img/1.webp' width='100%' />
									<div className='font-primary-extra-light fs-18'>
										Projects built just for you for any occasion
									</div>
								</div>
								<div className='col-12 col-sm-4 item text-center'>
									<img src='/assets/img/1659468646.webp' width='100%' />
									<div className='font-primary-extra-light fs-18'>
										Hand crafted royalty free stickers to use on your projects!
									</div>
								</div>
							</div>
							<a
								className='btn btn-primary btn-primary-override button font-avenir fs-16'
								style={{
									backgroundColor: '#fff',
									color: '#6dbbad',
									padding: '10px 80px 3px',
								}}
								onClick={() => onSignUp()}>
								Sign Up Now - Limited Spots
							</a>
						</div>
					</section>
					<section className='workshop-section section-height-2 bg-light pb-5 mt-5 container section-10'>
						<div className='part1 mb-5'>
							<div
								className='text-center appear-animation'
								data-appear-animation='fadeInDownShorter'
								data-appear-animation-delay='200'>
								<img src='/assets/img/Group 1.webp' width='350' height='auto' />
							</div>
							<div className='pl-2 pr-2'>
								<h2
									className='font-primary-bold primary-color text-center appear-animation'
									data-appear-animation='fadeInDownShorter'
									data-appear-animation-delay='200'
									style={{ fontSize: 24 }}>
									Are you ready for our online workshop?
								</h2>
								<div
									style={{ display: 'flex', justifyContent: 'center' }}
									className='font-primary-extra-light appear-animation'
									data-appear-animation='fadeInUpShorter'
									data-appear-animation-delay='300'>
									<ul className='list fs-18'>
										<li>
											Master your Cricut machine and unlock your crafting
											potential
										</li>
										<li>
											Create unique and personalized projects alongside an expert
											host
										</li>
										<li>
											Have all necessary materials sent directly to your home
										</li>
										<li>
											Join us for an immersive and engaging online workshop
											experience
										</li>
									</ul>
								</div>
								<div
									className='text-center appear-animation'
									data-appear-animation='fadeInLeftShorter'
									data-appear-animation-delay='500'>
									<a
										className='btn btn-primary btn-primary-override font-primary-regular text-color-light button font-avenir fs-16'
										style={{ padding: '10px 80px 3px' }}
										onClick={() => onSignUp()}>
										I'm Ready!
									</a>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-12 col-sm-4 item text-center'>
								<img src='/assets/img/3.webp' />
								<h2 className='font-primary-bold primary-color'>
									6 Hour Live Workshop
								</h2>
								<p className='font-primary-extra-light'>
									The workshop will be conducted in two parts, with a break in
									between. The first half of the workshop will be an intensive
									3-hour session, where you'll gain mastery of every aspect of
									your Cricut machine and navigate Design Space with ease. This
									will of course involve creating projects as the same time. After
									a 2-hour break, we'll dive into the second half of the workshop,
									where you'll learn new techniques and strategies to craft
									extraordinary designs with confidence. By the end of the
									workshop, you'll be able to create intricate, personalized
									crafts that will leave an indelible impression.
								</p>
							</div>
							<div className='col-12 col-sm-4 item text-center'>
								<img src='/assets/img/1.webp' />
								<h2 className='font-primary-bold primary-color'>
									Master your Cricut
								</h2>
								<p className='font-primary-extra-light'>
									In our comprehensive online workshop, you'll unlock the full
									potential of your Cricut machine and gain mastery of every
									aspect. Discover new techniques and strategies for creating
									extraordinary designs with ease. Join us as a beginner and gain
									the confidence to create intricate, personalized crafts that
									leave an indelible impression.
								</p>
							</div>
							<div className='col-12 col-sm-4 item text-center'>
								<img src='/assets/img/1636747991.webp' />
								<h2 className='font-primary-bold primary-color'>
									All Materials Provided
								</h2>
								<p className='font-primary-extra-light'>
									Get ready to create amazing projects with ease by joining our
									online workshop! We'll send you a complete package filled with
									all the crafting materials and tools you need to participate, so
									you can start crafting right away.
								</p>
							</div>
						</div>
						<div id="purchase-view" className='part2 text-center'>
							<img src='/assets/img/workshop_green.png' />
							<img
								src='/assets/img/may_6th_bottom.png'
								className='mt-3 mb-5'
							/>
						</div>
						<div
							id="payment"
							className='rect-container text-center appear-animation'
							data-appear-animation='blurIn'
							data-appear-animation-delay='200'>
		
							<div className="trialback">
								<img src="assets/img/best_deal.png" />
								<h2 style={{ marginBottom: 0, color: '#38eca6' }}>Live Workshop Access</h2>
								<div style={{ lineHeight: 1, fontSize: 20, margin: 10, display: 'flex', justifyContent: 'center' }}>
									$<span style={{ fontWeight: 700, color: '#38eca6', fontSize: 45, marginTop: -3 }}>
									<span className="line-through">397</span> $97
									</span>
								</div>

								<div>Lifetime Membership Included</div>
								<a className="tp-caption btn btn-green font-weight-semibold"
									style={{ color: "#fff", width: "100%", fontSize: 14, marginTop: "12px", justifyContent: "center" }}
									onClick={() => goToSignUp()}>
									Purchase Now
								</a>
								<hr style={{ marginLeft: '-2rem', marginRight: '-2rem', borderTop: '2px solid rgba(0,0,0,.1)' }} />
								<div className="item">6 Hours of Crafting, Creating & Learning</div>
								<div className="item">Master Your Cricut & Design Space</div>
								<div className="item">Materials Sent to Your Doorstep</div>
							</div>
						</div>

					</section>
					<section className='workshop-section section section-height-2 bg-green section-11'>
						<div className='container text-center'>
							<h2 className='font-primary-bold' style={{ fontSize: 32 }}>
								This Workshop is for Beginners!
							</h2>
							<p
								className='font-primary-extra-light appear-animation'
								style={{ fontSize: 24 }}
								data-appear-animation='fadeInUpShorter'
								data-appear-animation-delay='200'>
								If you’re somebody new to Cricut or Design Space, then this is
								exactly what you are looking for!
							</p>
						</div>
					</section>
					<section className='workshop-section section section-height-2 bg-light section-12'>
						<div className='container text-center'>
							<h2 className='font-primary-bold mb-0'>Frequently Asked</h2>
							<p
								className='font-primary-bold primary-color'
								style={{ fontSize: 26 }}>
								Questions
							</p>
							<div className='row'>
								<div className='col-md-6 right-border'>
									<h6
										className='font-primary-bold primary-color'
										style={{ fontSize: 20 }}>
										When is the live workshop?
									</h6>
									<p
										className='font-primary-extra-light'
										style={{ fontWeight: 'bold', fontSize: 15 }}>
										The live workshop will take place Saturday, May 13th at 12pm.
									</p>
								</div>
								<div className='col-md-6'>
									<h6
										className='font-primary-bold primary-color'
										style={{ fontSize: 20 }}>
										How long will the workshop be?
									</h6>
									<p
										className='font-primary-extra-light'
										style={{ fontWeight: 'bold', fontSize: 15 }}>
										We will be working on a series of projects together for 3
										hours, be taking a 2 hour break, then creating more projects
										for 3 more hours.
									</p>
								</div>
								<div className='col-md-6 right-border'>
									<h6
										className='font-primary-bold primary-color'
										style={{ fontSize: 20 }}>
										Will the materials be delivered to my house?
									</h6>
									<p
										className='font-primary-extra-light'
										style={{ fontWeight: 'bold', fontSize: 15 }}>
										Yes! All materials except your Cricut machine, computer or
										phone, and Vinyl will be sent to your doorstep.
									</p>
								</div>
								<div className='col-md-6'>
									<h6
										className='font-primary-bold primary-color'
										style={{ fontSize: 20 }}>
										Do I need a Cricut Machine and Design Space?
									</h6>
									<p
										className='font-primary-extra-light'
										style={{ fontWeight: 'bold', fontSize: 15 }}>
										Yes. The only thing not included is a Cricut Machine, Design
										Space, and Vinyl.
									</p>
								</div>
								<div className='col-md-6 right-border'>
									<h6
										className='font-primary-bold primary-color'
										style={{ fontSize: 20 }}>
										Is this refundable?
									</h6>
									<p
										className='font-primary-extra-light'
										style={{ fontWeight: 'bold', fontSize: 15 }}>
										Unfortunately due to the nature of the live event, this is NON
										REFUNDABLE.
									</p>
								</div>
								<div className='col-md-6'>
									<h6
										className='font-primary-bold primary-color'
										style={{ fontSize: 20 }}>
										Will I get a recorded copy of everything sent to me after?
									</h6>
									<p
										className='font-primary-extra-light'
										style={{ fontWeight: 'bold', fontSize: 15 }}>
										Yes! We will send you a copy of the livestream if you ever
										want to go back and review how things were made.
									</p>
								</div>
								<div className='col-md-6 right-border'>
									<h6
										className='font-primary-bold primary-color'
										style={{ fontSize: 20 }}>
										What kinds of projects will we be making?
									</h6>
									<p
										className='font-primary-extra-light'
										style={{ fontWeight: 'bold', fontSize: 15 }}>
										A variety of projects will be made catered towards beginners.
									</p>
								</div>
								<div className='col-md-6'>
									<h6
										className='font-primary-bold primary-color'
										style={{ fontSize: 20 }}>
										What is the difficulty level of the projects?
									</h6>
									<p
										className='font-primary-extra-light'
										style={{ fontWeight: 'bold', fontSize: 15 }}>
										They will be the perfect projects for somebody just starting
										out or completely new.
									</p>
								</div>
							</div>
						</div>
					</section>
					{/* <section className='workshop-section section section-height-2 bg-green section-14 pt-5 pb-5'>
						<div className='container bg-light p-4'>
							<h2 className='font-primary-bold'>
								Have a question we didn't answer?
							</h2>
							<span className='font-avenir fs-18'>
								Email us here and we will get back to you *
							</span>
							<div style={{ display: 'flex' }}>
								<input className='input-form' />
								<a
									className='btn btn-primary btn-primary-override font-primary-regular text-color-light button font-avenir fs-16'
									style={{
										margin: 0,
										display: 'flex',
										alignItems: 'center',
										padding: '10px 60px 3px',
									}}>
									Sign Up
								</a>
							</div>
						</div>
					</section> */}
				</div>
			</Layout>
		);
	}
};

export default LiveWorkshop;
