import React, { useMemo } from "react";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import axios from 'axios';
import { URL } from '../config/api';
const { forwardRef, useImperativeHandle } = React;

const useOptions = () => {
	const fontSize = 32;
	const options = useMemo(
	  () => ({
		showIcon: true,
		style: {
		  base: {
			fontSize: '17px',
			color: "#424770",
			letterSpacing: "0.025em",
			fontFamily: 'Source Code Pro, monospace',
			"::placeholder": {
			  color: "#aab7c4"
			}
		  },
		  invalid: {
			color: "#9e2146"
		  }
		}
	  }),
	  [fontSize]
	);
  
	return options;
};

const SplitFormLiveWorkshop = forwardRef((props, ref) => {
  const stripe = useStripe();

  const elements = useElements();
  const options = useOptions();

  useImperativeHandle(ref, () => ({

    async handleSubmitOrder() {
		console.log("AAA_HANDLE_SUBMIT=====");
		if (!stripe || !elements) {
      		props.showLoading(false);
		  	return;
		}

		const formData = new FormData();
		formData.append('Email', props.email);
		const res = await axios.post(`${URL}/stripe/create_payment_intent_workshop.php`, formData);
		if(res.data.Success) {
			const paymentIntentId = res.data.PaymentIntentId;
			const customerId = res.data.CustomerId;
	  
			const payload = await stripe.confirmCardPayment(res.data.ClientSecret, {
				setup_future_usage: 'off_session',
				payment_method: {
					card: elements.getElement(CardNumberElement)
			  	}
			});
			
			if (payload.error) {
			  	props.showLoading(false);
			  	alert(payload.error.message);
			} else {
			  	props.registerUser(paymentIntentId, customerId);
			}
		} else {
			alert(res.data.Message);
			props.showLoading(false);
		}
	}

  }));



  return (
    <div>
		<form>
            <div>
              <CardNumberElement className='stripe-form-input-liveworkshop'
                options={options}
                onReady={() => {
                }}
                onChange={event => {
                }}
                onBlur={() => {
                }}
                onFocus={() => {
                }}
              />
                    
              <CardCvcElement className='stripe-form-input-liveworkshop'
                options={options}
                onReady={() => {
                }}
                onChange={event => {
                }}
                onBlur={() => {
                }}
                onFocus={() => {
                }}
              />

              <CardExpiryElement className='stripe-form-input-liveworkshop width-6'
                options={options}
                onReady={() => {
                }}
                onChange={event => {
                }}
                onBlur={() => {
                }}
                onFocus={() => {
                }}
              />

            </div>
          </form>
    </div>
  );
});

export default SplitFormLiveWorkshop;