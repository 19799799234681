import React from 'react';

import Layout from './Layout';

const Faq = () => {
    return (
        <Layout>
            <div role="main" className="main" style={{ paddingTop: 140 }}>
                <section className='workshop-section section section-height-2 bg-light section-12'>
					<div className='container'>
						<h2 className='font-primary-bold mb-0 text-center'>Frequently Asked</h2>
						<p
							className='font-primary-bold primary-color text-center'
							style={{ fontSize: 26 }}>
							Questions
						</p>
						<div className='row'>
							<div className='col-md-6 right-border'>
								<h6
									className='font-primary-bold primary-color text-center'
									style={{ fontSize: 20 }}>
									How Do I Install Fonts Onto Design Space?
								</h6>
								<p
									className='font-primary-extra-light'
									style={{ fontWeight: 'bold', fontSize: 14 }}>
                                    Step 1) Download the font onto your computer from CraftersPro.Net
                                    <br/><br/>
                                    Step 2)
                                    Install the font onto your computer system.<br/>
                                    ▪ Mac <br/>
                                    Double-click the font in the Finder, then click Install Font in the font preview window that opens. After your Mac validates the font and opens the Font Book app, the font is installed and available for use.<br/>
                                    ▪ Windows <br/>
                                    Open the Windows Control Panel.
                                    Select Appearance and Personalization.
                                    At the bottom, select Fonts. ...
                                    To add a font, simply drag the font file into the font window.
                                    <br/><br/>
                                    Step 3)
                                    If Design space is open, close and restart it.
                                    Open Design Space
                                    New Project
                                    Add Text
                                    Highlight Text
                                    Tap Font Dropdown
                                    Select System Fonts
                                    There you can find your system fonts
								</p>
							</div>

                            <div className='col-md-6'>
								<h6
									className='font-primary-bold primary-color text-center'
									style={{ fontSize: 20 }}>
									How can I unsubscribe or cancel my free trial?
								</h6>
								<p
									className='font-primary-extra-light'
									style={{ fontWeight: 'bold', fontSize: 14 }}>
									You may unsubscribe anytime by going to the receipt that was sent to your email when you signed up for the free trial. At the bottom, you can click on the link to cancel where it says “Manage my Subscription”. Alternatively, you can email us directly here, and we will cancel it for you.
								</p>
							</div>
						</div>
					</div>
				</section>
            </div>
        </Layout>
    );
}

export default Faq;