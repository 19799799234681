import React from 'react';
import { SearchOutlined } from '@ant-design/icons'

const SearchBar = ({ handleSearch, marginLeft }) => {
    return (
        <div className="search-container" style={ marginLeft ? { marginLeft: 'auto' } : null}>
            <div>
                <img src="/assets/img/icons8-search.svg" />
            </div>
            <input style={{width: "100%", boxShadow: "none"}} onChange={handleSearch} />
        </div>
    );
}

export default SearchBar;