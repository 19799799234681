import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from "react-router-dom";
import Layout from './Layout';
import SplitForm from "./SplitForm";
import { STRIPE_TEST_KEY } from '../config/api';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from 'axios';
import firebase from "firebase/compat/app";
import { URL, META_PIXEL_ID } from '../config/api';
import WheelComponent from 'react-wheel-of-prizes'
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { showLogin } from '../redux/actions/appActions';
import { SalesCountdown } from '../components/SalesCountdown';
import ReactPixel from 'react-facebook-pixel';
import {isMobile} from 'react-device-detect';

const stripePromise = loadStripe(STRIPE_TEST_KEY);
const fonts = [{ cssSrc: "https://fonts.googleapis.com/css?family=Podkova:400" }]

const Paywall = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [receiveEmail, setReceiveEmail] = useState(true);
    const [visible, setVisible] = useState(false);
  
    const loggedIn = localStorage.getItem('auth');
    const premium = localStorage.getItem('premium');
    const userType = localStorage.getItem('type');
  
    const [paymentType, setPaymentType] = useState(0);
    const [linkId, setLinkId] = useState(0);
    const [resubscribe, setResubscribe] = useState(false);
  
    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    const modalWidth = window.innerWidth >= 500 ? 500 : window.innerWidth - 16;
    const spinnerWidth = modalWidth - 32;
    console.log("SPINNER_WIDTH", spinnerWidth);
    const [discount, setDiscount] = useState(0);

    const [promoCode, setPromoCode] = useState('');
    const [promoCodeId, setPromoCodeId] = useState(0);
    const [percent, setPercent] = useState(0);
    const [discountedPrice, setDiscountedPrice] = useState(97);
    const [isPromo, setIsPromo] = useState(false);
    const [penAdapter, setPenAdapter] = useState(false);

    const dispatchLogin = () => {
        dispatch(showLogin());
    }

    const segments = [
        '25% OFF',
        '30% OFF',
        '50% OFF',
        '40% OFF',
        '35% OFF',
        '45% OFF',
        '25% OFF',
        '30% OFF'
    ]
    const segColors = [
        '#8DC63F',
        '#FFDD00',
        '#F26522',
        '#ED1C24',
        '#6F2C91',
        '#0072BC',
        '#EC3F3F',
        '#FF9000'
    ]

    useEffect(() => {
        if(props.helpUkraine == 1) {
            if(props.paywallType == 5) {
                alert("Thank you for supporting. For every lifetime purchase bought, CraftersPro will donate 5 meals to the Ukrainian People");
            } else {
                alert("Thank you for supporting. For every lifetime purchase bought, CraftersPro will donate 10 meals to the Ukrainian People");
            }
        }

        if(props.paywallType == 1) {
            setTimeout(
                function() {
                    setVisible(true);
            }, 2500);
        } else if(props.paywallType == 3) {
            if(location.pathname == "/payment-pens") {
                setPromoCode("FREEPENADAPTERS");
                setPenAdapter(true);
                setPromoCodeId(8);
              }
        } else if(props.paywallType == 5) {
            setTimeout(() => {
                alert("Discount applied successfully, enjoy 75% off.");
            }, 1000);
        } else if(props.paywallType == 6) {
            setTimeout(() => {
                alert("Discount applied successfully, enjoy 50% off");
            }, 1000);
        }

        if(props.paywallType == 0 && premium == 0 && userType == 0) {
            setResubscribe(true);
        }
    
        if(params.link) {
            if(params.link == "link3") {
                setLinkId(3);
                addClick(3);
            } else if(params.link == "link4") {
                setLinkId(4);
                addClick(4);
            }
        }
    
        if(premium == 1) {
            history.push('/projects');
            return;
        }
        window.scrollTo(0, 0);
    
        firebase.analytics().logEvent('payment_page_loaded');
        initMetaPixel();
    }, [])

    const initMetaPixel = () => {
        const advancedMatching = { em: 'info@crafterspro.net' };
        const options = {
            autoConfig: true,
            debug: true,
        };
        ReactPixel.init(META_PIXEL_ID, advancedMatching, options);
    }

    const onFinished = (winner) => {
        if(winner == '25% OFF') {
            setDiscount(0.25);
        } else if(winner == '30% OFF') {
            setDiscount(0.3);
        } else if(winner == '35% OFF') {
            setDiscount(0.35);
        } else if(winner == '40% OFF') {
            setDiscount(0.4);
        } else if(winner == '45% OFF') {
            setDiscount(0.45);
        } else {
            setDiscount(0.5);
        }
        
        setVisible(false);
        alert('Promo code applied successfully, enjoy ' + winner.toLowerCase());
    }
    
    const handleStart = (type) => {
        setPaymentType(type);
    
        setTimeout(function() {
            var purchaseView = document.getElementById("account-info");
            purchaseView.scrollIntoView({behavior: "smooth", block: "start"});
        }, 800);
    }
    
    //=====Monthly
    const handleConfirmOrder = async (payload) => {
        if(paymentType == 0) {
            showLoading(false);
            alert("Please select payment type.");
            return;
        }
    
        if(loggedIn != 1) {
            if(email == "") {
                showLoading(false);
                alert("Please input email.");
                return;
            }
        
            if(password == "") {
                showLoading(false);
                alert("Please input password.")
            }
        
            if(password != confirmPassword) {
                showLoading(false);
                alert("Please confirm password.")
                return;
            }
        }
    
        if(payload.error) {
            showLoading(false);
            alert("Please input valid card number");
        } else {
            createSubscription(payload.paymentMethod.id, email, password, linkId);
        }
    }
    
    const handleAppleSubscription = async (paymentMethodId, em, pwd) => {
        createSubscription(paymentMethodId, em, pwd, 5);
    }
    
    const showLoading = (loading) => {
        setLoading(loading);
    }
    
    const createSubscription = async(paymentMethodId, email, password, linkId) => {
        const formData = new FormData();
        if(loggedIn != 1) {
            formData.append('Login', 0);
            formData.append('Email', email);
            formData.append('Password', password);
        } else {
            formData.append('Login', 1);
            formData.append('UserId', localStorage.getItem('id'));
        }
    
        formData.append('PaymentType', paymentType);
        formData.append('LinkId', linkId);
    
        if(receiveEmail) {
            formData.append('ReceiveEmail', 1);
        } else {
            formData.append('ReceiveEmail', 0);
        }
        formData.append('PaymentMethodId', paymentMethodId);
        formData.append('HelpUkraine', props.helpUkraine == 1? 1 : 0);

        const res = await axios.post(`${URL}/stripe/create_subscription.php`, formData);
        if(res.data.Success) {
            showLoading(false);
            if(res.data.Data.status == 'active') {
                if(props.paywallType == 0 && loggedIn == 1) {
                    localStorage.setItem('premium', 1);
                    alert("Subscription payment is created successfully. You can access full features again from now.");
                    history.push('/projects');
                    return;
                }

                var alertMessage = "Subscription payment is created successfully. Please login to access full features.";
                if(props.helpUkraine == 1) {
                    if(props.paywallType == 5) {
                        alertMessage = "Purchase successful. You will receive an email confirmation on the 5 meals being donated. Thank you for your support, and enjoy CraftersPro Lifetime Access.";
                    } else {
                        alertMessage = "Purchase successful. You will receive an email confirmation on the 10 meals being donated. Thank you for your support, and enjoy CraftersPro Lifetime Access.";
                    }
                }
                var result = window.confirm(alertMessage);
                if (result) {
                    ReactPixel.track('Purchase');
                    //dispatchLogin();
                    handleAutoLogin(email, password);
                }
            } else {
                alert("Payment failed and try again.");
            }
        } else {
            showLoading(false);
            alert(res.data.Message);
        }
    }

    const handleAutoLogin = async (email, password) => {
        const bodyFormData = new FormData();
        bodyFormData.append('Email', email);
        bodyFormData.append('Password', password);
  
        const res = await axios.post(`${URL}/login/login.php`, bodyFormData);
  
        if(res.data.Success) {
            localStorage.setItem('auth', 1);
            localStorage.setItem('id', res.data.Data.Id);
            localStorage.setItem('token', res.data.Data.Token);
            localStorage.setItem('email', res.data.Data.Email);
            localStorage.setItem('next_billing', res.data.Data.NextBilling);
            localStorage.setItem('premium', res.data.Data.Premium);
            localStorage.setItem('customer_id', res.data.Data.CustomerId);
            localStorage.setItem('type', res.data.Data.Type);
            localStorage.setItem('elite', res.data.Data.Elite);
  
            window.location.href = '/projects'
        } else {
            alert(res.data.Message);
        }
    }
    
    const handleLogin = () => {
        dispatchLogin();
    }
    
    const addClick = async (linkId) => {
        const bodyFormData = new FormData();
        bodyFormData.append('LinkId', linkId);
        try {
            await axios.post(`${URL}/link/add_click.php`, bodyFormData);
        } catch(error) {
          
        }
    }

    const handleApply = async () => {
        const formData = new FormData();
        formData.append('Code', promoCode);
        formData.append('PageId', 1);
    
        const res = await axios.post(`${URL}/promo/validate_promo_code.php`, formData);
        if(res.data.Success) {
            alert(res.data.Message);

            if(res.data.PromoCode.Type == 0) {
            setIsPromo(true);
            setPercent(res.data.PromoCode.Percent);
            setPromoCodeId(res.data.PromoCode.Id);
            setDiscountedPrice(Math.round(97 * (100 - res.data.PromoCode.Percent) / 100));
            } else {
                setPenAdapter(true);
                setPromoCodeId(res.data.PromoCode.Id);
            }
        } else {
            alert(res.data.Message);
        }
    }    

    return (
        <Layout>
            <div role="main" className="main paywall">
                {!isMobile && <div className="top-image" />}
                {isMobile && <section id="start" className="section-1" style={{ marginTop: '10rem' }}>
                    <img src="https://static.wixstatic.com/media/f1f906_84df04afa53a4d889d2d7df3ebc07847~mv2.png/v1/fill/w_333,h_143,al_c,q_85,usm_0.66_1.00_0.01/Group%201.webp" width="100%" height="auto" className="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="400" style={{ animationDuration: '2000ms'}} />
                    <h2 className="font-weight-bold center" style={{ fontSize: '20px', whiteSpace: 'nowrap', marginTop: '0.5em', color: '#000', fontFamily: 'Oswald-Medium' }}>Crafters Pro Perks</h2>
                    <p style={{ fontSize: '16px',  lineHeight: 1.6, fontFamily: 'Nimbus-Sans', maxWidth: 232, color: '#000', margin: '0.5rem auto' }}>400+ Fonts, Endless Projects, and Stickers Start 3 Day Free Trial</p>
                </section>}
                {isMobile &&
                <><div className="text-center" style={{ fontFamily: 'Poppins-Semi-Bold', fontSize: 22, marginTop: '2rem' }}>Choose the membership you want!</div>
                <section id="payment" className="section-2">
                    <div className="row" style={{ justifyContent: 'center' }}>
                        { penAdapter && 
                            <div className="center-vertical pen-adapter-text">
                                <img src={"/assets/img/pens.gif"} className="pens" />

                                <div className="center-horizontal" style={{marginTop: "1rem"}}>
                                    Get all 12 Pen Adapters for FREE
                                </div>
                            </div>
                        }
                        {((props.paywallType == 0 && !resubscribe) || props.paywallType == 1 || props.paywallType == 2 || props.paywallType == 3 || props.paywallType == 5 || props.paywallType == 6 || props.paywallType == 7) && <div className="trialback">
                            <img src="assets/img/best_deal.png" />
                            <h2 style={{ marginBottom: 0, color: '#38eca6' }}>Lifetime Pro</h2>
                            <div style={{ lineHeight: 1, fontSize: 20, margin: 10, display: 'flex', justifyContent: 'center' }}>
                                $<span style={{ fontWeight: 700, color: '#38eca6', fontSize: 45, marginTop: -3 }}>

                                    {(props.paywallType == 0 || props.paywallType == 1) && <>
                                    {discount != 0 && <span className="line-through">97</span>} {discount != 0 && (Math.round(97 * (1 - discount)) )}
                                    {discount == 0 && '97'}
                                    </>}

                                    {(props.paywallType == 2 || props.paywallType == 3) && <>
                                        {isPromo && <span className="line-through">97</span>} {isPromo && discountedPrice}
                                        {!isPromo && '97'}
                                    </>}

                                    {(props.paywallType == 5 || props.paywallType == 7) && <>
                                        <span className="line-through">97</span> $24
                                    </>}

                                    {(props.paywallType == 6) && <>
                                        <span className="line-through">97</span> 49
                                    </>}
                                </span>
                            </div>

                            <div style={{ fontWeight: 900 }}>No Monthly Payments!</div>
                            <div>One time Fee</div>
                            <a className="tp-caption btn btn-green font-weight-semibold"
                                style={{ color: "#fff", width: "100%", fontSize: 14, marginTop: "12px", justifyContent: "center" }}
                                onClick={() => handleStart(2)}>
                                Purchase Now
                            </a>
                            <hr style={{ marginLeft: '-2rem', marginRight: '-2rem', borderTop: '2px solid rgba(0,0,0,.1)' }} />
                            <div className="item">Unlimited use of all projects</div>
                            <div className="item">Speedy Support</div>
                            <div className="item">Access forever. Never pay monthly or yearly</div>
                        </div>}

                        { (props.paywallType == 2 || props.paywallType == 3) && penAdapter &&
                            <div className="center-vertical pen-adapter-text">
                                <img src={"/assets/img/pens.png"} className="pens" />

                                <div style={{marginTop: "1rem", marginLeft: "2rem"}}>
                                    <div className="Dot" />
                                    <div className="Dot-title">
                                    Includes all 12 Adapters
                                    </div>

                                    <div className="Dot" />
                                    <div className="Dot-title">
                                    Works with Sharpie, Bic and more
                                    </div>

                                    <div className="Dot" />
                                    <div className="Dot-title">
                                    $49.99 Value FREE
                                    </div>
                                </div>
                            </div>
                        }
                        { !penAdapter && <div className="trialback active">
                            {/* <img src="assets/img/free_trial.png" /> */}
                            <h2 style={{ marginBottom: 0 }}>Free Trial / Yearly</h2>
                            <div style={{ fontWeight: 900, fontSize: 11 }}>3 DAY FREE TRIAL THEN</div>
                            <div style={{ lineHeight: 1, fontSize: 20, display: 'flex', justifyContent: 'center' }}>$<span style={{ fontWeight: 700, fontSize: 45, marginTop: -3 }}>29.99</span></div>
                            <div style={{ fontWeight: 900 }}>Every Year</div>
                            <div>Perfect For All Crafting Needs!</div>
                            <a className="tp-caption btn btn-blue font-weight-semibold" style={{ color: "#fff", width: "100%", fontSize: 14, marginTop: "12px", justifyContent: "center" }} onClick={() => handleStart(1)}>Start Free Trial</a>

                            <hr style={{ marginLeft: '-2rem', marginRight: '-2rem', borderTop: '2px solid rgba(0,0,0,.1)' }} />
                            <div className="item">Unlimited use of all projects</div>
                            <div className="item">Speedy Support</div>
                            <div className="item">Free 3 Day Trial</div>
                        </div>}
                    </div>
                </section></>}
                {isMobile && props.paywallType === 5 && 
                    <>
                        <div className="row" style={{ justifyContent: 'center' }}>
                            <div className="row" style={{ justifyContent: "center" }}>
                                <img src="/assets/img/lightning.png" style={{width: '24px', height: 'auto', objectFit: 'contain', marginRight: '2px'}} alt="icon" />
                                <p style={{ fontFamily: 'Open Sans', fontSize: 18, fontWeight: 'bold', margin: '1rem 0', textAlign: "center" }}>
                                    Limited time offer. Sales ends in
                                </p>
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'center' }}>
                            <SalesCountdown />
                        </div>
                    </>
                }
                {isMobile && (props.paywallType == 2 || props.paywallType == 3) && <section className="section-2">
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <div className="code-box" style={{justifyContent: 'center'}}>
                            <input type="text" placeholder="Have a promo code? Add it here" value={promoCode} onChange={(e) => setPromoCode(e.target.value)} />
                        </div>
                    </div>

                    <div className="row" style={{ justifyContent: 'center' }}>
                        <a className="tp-caption btn btn-primary btn-primary-override font-weight-semibold"
                            style={{ color: "#fff", padding: "14px 70px", fontSize: 14, marginTop: "12px" }}
                            onClick={handleApply} >
                            Apply
                        </a>
                    </div>
                </section>}
                {!isMobile && <section id="start" className="section-1">
                    <img src="https://static.wixstatic.com/media/f1f906_84df04afa53a4d889d2d7df3ebc07847~mv2.png/v1/fill/w_333,h_143,al_c,q_85,usm_0.66_1.00_0.01/Group%201.webp" width="100%" height="auto" className="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="400" style={{ animationDuration: '2000ms'}} />
                    <h2 className="font-weight-bold center" style={{ fontSize: '20px', whiteSpace: 'nowrap', marginTop: '0.5em', color: '#000', fontFamily: 'Oswald-Medium' }}>Crafters Pro Perks</h2>
                    <p style={{ fontSize: '16px',  lineHeight: 1.6, fontFamily: 'Nimbus-Sans', maxWidth: 232, color: '#000', margin: '0.5rem auto' }}>400+ Fonts, Endless Projects, and Stickers Start 3 Day Free Trial</p>
                </section>}
                <section className="section-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-4">
                                <img src="/assets/img/tons.png" 
                                className="appear-animation"
                                data-appear-animation="fadeInLeft"
                                data-appear-animation-delay="800" 
                                style={{ animationDuration: '2000ms'}} />
                                <h5 style={{marginTop: "8px"}}>Tons of Projects</h5>
                                <p>Projects ready for<br/>any occasion</p>
                            </div>
                            <div className="col-12 col-sm-4">
                                <img src="/assets/img/stickers.png" 
                                className="appear-animation"
                                data-appear-animation="fadeInUp"
                                data-appear-animation-delay="1200" style={{ animationDuration: '2000ms'}}/>
                                <h5 style={{marginTop: "8px"}}>Cut Files</h5>
                                <p>Hand Crafted & Royalty Free</p>
                            </div>
                            <div className="col-12 col-sm-4">
                                <img src="/assets/img/fonts.png" 
                                className="appear-animation"
                                data-appear-animation="fadeInRight"
                                data-appear-animation-delay="1600" style={{ animationDuration: '2000ms'}}/>
                                <h5 style={{marginTop: "8px"}}>Tons of Fonts</h5>
                                <p>Hand Crafted & Royalty Free</p>
                            </div>
                        </div>
                    </div>
                </section>
                {!isMobile &&
                <><div className="text-center" style={{ fontFamily: 'Poppins-Semi-Bold', fontSize: 22, marginTop: '2rem' }}>Choose the membership you want!</div>
                <section id="payment" className="section-2">
                    <div className="row" style={{ justifyContent: 'center' }}>
                        { penAdapter && 
                            <div className="center-vertical pen-adapter-text">
                                <img src={"/assets/img/pens.gif"} className="pens" />

                                <div className="center-horizontal" style={{marginTop: "1rem"}}>
                                    Get all 12 Pen Adapters for FREE
                                </div>
                            </div>
                        }
                        { !penAdapter && <div className="trialback active">
                            {/* <img src="assets/img/free_trial.png" /> */}
                            <h2 style={{ marginBottom: 0 }}>Free Trial / Yearly</h2>
                            <div style={{ fontWeight: 900, fontSize: 11 }}>3 DAY FREE TRIAL THEN</div>
                            <div style={{ lineHeight: 1, fontSize: 20, display: 'flex', justifyContent: 'center' }}>$<span style={{ fontWeight: 700, fontSize: 45, marginTop: -3 }}>29.99</span></div>
                            <div style={{ fontWeight: 900 }}>Every Year</div>
                            <div>Perfect For All Crafting Needs!</div>
                            <a className="tp-caption btn btn-blue font-weight-semibold" style={{ color: "#fff", width: "100%", fontSize: 14, marginTop: "12px", justifyContent: "center" }} onClick={() => handleStart(1)}>Start Free Trial</a>

                            <hr style={{ marginLeft: '-2rem', marginRight: '-2rem', borderTop: '2px solid rgba(0,0,0,.1)' }} />
                            <div className="item">Unlimited use of all projects</div>
                            <div className="item">Speedy Support</div>
                            <div className="item">Free 3 Day Trial</div>
                        </div>}
                        {((props.paywallType == 0 && !resubscribe) || props.paywallType == 1 || props.paywallType == 2 || props.paywallType == 3 || props.paywallType == 5 || props.paywallType == 6 || props.paywallType == 7) && <div className="trialback">
                            <img src="assets/img/best_deal.png" />
                            <h2 style={{ marginBottom: 0, color: '#38eca6' }}>Lifetime Pro</h2>
                            <div style={{ lineHeight: 1, fontSize: 20, margin: 10, display: 'flex', justifyContent: 'center' }}>
                                $<span style={{ fontWeight: 700, color: '#38eca6', fontSize: 45, marginTop: -3 }}>

                                    {(props.paywallType == 0 || props.paywallType == 1) && <>
                                    {discount != 0 && <span className="line-through">97</span>} {discount != 0 && (Math.round(97 * (1 - discount)) )}
                                    {discount == 0 && '97'}
                                    </>}

                                    {(props.paywallType == 2 || props.paywallType == 3) && <>
                                        {isPromo && <span className="line-through">97</span>} {isPromo && discountedPrice}
                                        {!isPromo && '97'}
                                    </>}

                                    {(props.paywallType == 5 || props.paywallType == 7) && <>
                                        <span className="line-through">97</span> $24
                                    </>}

                                    {(props.paywallType == 6) && <>
                                        <span className="line-through">97</span> 49
                                    </>}
                                </span>
                            </div>

                            <div style={{ fontWeight: 900 }}>No Monthly Payments!</div>
                            <div>One time Fee</div>
                            <a className="tp-caption btn btn-green font-weight-semibold"
                                style={{ color: "#fff", width: "100%", fontSize: 14, marginTop: "12px", justifyContent: "center" }}
                                onClick={() => handleStart(2)}>
                                Purchase Now
                            </a>
                            <hr style={{ marginLeft: '-2rem', marginRight: '-2rem', borderTop: '2px solid rgba(0,0,0,.1)' }} />
                            <div className="item">Unlimited use of all projects</div>
                            <div className="item">Speedy Support</div>
                            <div className="item">Access forever. Never pay monthly or yearly</div>
                        </div>}

                        { (props.paywallType == 2 || props.paywallType == 3) && penAdapter &&
                            <div className="center-vertical pen-adapter-text">
                                <img src={"/assets/img/pens.png"} className="pens" />

                                <div style={{marginTop: "1rem", marginLeft: "2rem"}}>
                                    <div className="Dot" />
                                    <div className="Dot-title">
                                    Includes all 12 Adapters
                                    </div>

                                    <div className="Dot" />
                                    <div className="Dot-title">
                                    Works with Sharpie, Bic and more
                                    </div>

                                    <div className="Dot" />
                                    <div className="Dot-title">
                                    $49.99 Value FREE
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </section></>}
                {!isMobile && props.paywallType === 5 && 
                    <>
                        <div className="row" style={{ justifyContent: 'center' }}>
                            <div className="row" style={{ justifyContent: "center" }}>
                                <img src="/assets/img/lightning.png" style={{width: '24px', height: 'auto', objectFit: 'contain', marginRight: '2px'}} alt="icon" />
                                <p style={{ fontFamily: 'Open Sans', fontSize: 18, fontWeight: 'bold', margin: '1rem 0', textAlign: "center" }}>
                                    Limited time offer. Sales ends in
                                </p>
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'center' }}>
                            <SalesCountdown />
                        </div>
                    </>
                }
                {!isMobile && (props.paywallType == 2 || props.paywallType == 3) && <section className="section-2">
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <div className="code-box" style={{justifyContent: 'center'}}>
                            <input type="text" placeholder="Have a promo code? Add it here" value={promoCode} onChange={(e) => setPromoCode(e.target.value)} />
                        </div>
                    </div>

                    <div className="row" style={{ justifyContent: 'center' }}>
                        <a className="tp-caption btn btn-primary btn-primary-override font-weight-semibold"
                            style={{ color: "#fff", padding: "14px 70px", fontSize: 14, marginTop: "12px" }}
                            onClick={handleApply} >
                            Apply
                        </a>
                    </div>
                </section>}

                <div style={{marginTop: "16px"}}>
                    <div className="BarView">
                        <div className="Bar" />
                    </div>
                </div>

                <div style={{ textAlign: 'center', marginTop: '3rem' }}>
                    <img
                        alt="EZ" 
                        width="80" 
                        height="80" 
                        src="/assets/img/black-logo.svg"
                        className="appear-animation"
                        data-appear-animation="fadeInUp"
                        data-appear-animation-delay="500"
                        style={{ animationDuration: '1000ms'}}
                    />  
                </div>
                <div className="text-center" style={{ fontFamily: 'Open Sans', fontSize: 20, fontWeight: 'bold', margin: '3rem 0' }}>Testimonials</div>
                <section id="review" className="section-4 container">
                    <div className="row">
                        <div className="col-12 col-sm-6 item">
                            <div style={{ display: 'flex' }}>
                                <img src="/assets/img/user1.jpg" />
                                <div style={{ fontFamily: 'Open Sans' }}>
                                    <p>Amazing website! I signed up for the free trial and then I was hooked. I’ve made around 20 projects so far, and have used tons of fonts. My favorite part is how a lot of the projects have video tutorials. I’ll definitely be a long time subscriber.</p>
                                    <p>— Susan Harding</p>  
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 item">
                            <div style={{ display: 'flex' }}>
                                <img src="/assets/img/user2.jpg" />
                                <div style={{ fontFamily: 'Open Sans' }}>
                                    <p>I made the “happiness is a wagging tail” t-shirt for my son. We have a 6 month old German Shepard puppy and he loves it. I’m excited to see the new projects being released :)</p>
                                    <p>— Kelley Harris</p>  
                                </div>
                            </div>
                        </div>
                    </div> 
                </section>

                {(paymentType == 1 || paymentType == 2) &&
                    <section id="account-info" className="section-4 container" style={{marginTop: "16px"}}>
                        { loggedIn != 1 ?
                            <div>
                                <div className="center-horizontal" style={{marginBottom: "16px"}}>
                                {paymentType == 1? 
                                    <div className="fs-16 font-primary-regular">
                                        3 Day Trial then<br/>
                                        $29.99/year<br/>
                                        Cancel Anytime<br/>
                                    </div>: <div className="fs-16 font-primary-regular">
                                        {(props.paywallType == 0 || props.paywallType == 1) && <>
                                            {discount != 0 && <span className="line-through">$97</span>} {discount != 0 && ('$' + Math.round(97 * (1 - discount)) )}
                                            {discount == 0 && '$97'}/One time purchase
                                        </>}

                                        {(props.paywallType == 2 || props.paywallType == 3) && <>
                                        {isPromo && <span className="line-through">$97</span>} {isPromo && ('$' + discountedPrice)}
                                        {!isPromo &&'$97'}/One time purchase
                                        </>}

                                        {(props.paywallType == 5 || props.paywallType == 7) && <>
                                        <span className="line-through">$97</span> $24/One time purchase
                                        </>}

                                        {(props.paywallType == 6) && <>
                                        <span className="line-through">$97</span> $49/One time purchase
                                        </>}
                                    </div>}
                                </div>

                                <h2 className="font-primary-regular fs-20">
                                    Account Information
                                </h2>

                                <div className="font-primary-regular fs-12">
                                    *required
                                </div>

                                <div style={{float: 'right', marginTop: '-18px'}} className="fs-12">
                                    <a className="font-primary-regular" style={{color:"#747474"}} href="/payment">Back</a>
                                </div>

                                <form className="form-back">
                                    <label className="stripe-form-label font-primary-regular">
                                    *Email Address
                                    <input className="form-input font-primary-regular" type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)}/> 
                                    </label>

                                    <label className="stripe-form-label font-primary-regular">
                                    *Password
                                    <input className="form-input font-primary-regular" type="password" placeholder="Enter password" onChange={(e) => setPassword(e.target.value)}/> 
                                    </label>
                                    
                                    
                                    <label className="stripe-form-label font-primary-regular">
                                    *Confirm Password
                                    <input className="form-input font-primary-regular" type="password" placeholder="Enter confirm password" onChange={(e) => setConfirmPassword(e.target.value)}/> 
                                    </label>

                                    <label className="form-label font-primary-regular" >
                                    <div>
                                        <input className="FormInput-check" type="checkbox" defaultChecked={true} onChange={(e) => setReceiveEmail(e.target.checked)}/>
                                    </div>
                                    <div className="font-primary-regular" style={{marginLeft: "12px"}}>I agree to receive instructional and promotional emails </div>
                                    </label>
                                </form>
                            </div> : null
                        }
                            
                        <Elements stripe={stripePromise} fonts={{fonts}}>
                            <SplitForm
                                linkId={linkId}
                                isPromo={isPromo}
                                promoCodeId={promoCodeId}
                                email={email}
                                password={password}
                                confirmPassword={confirmPassword}
                                receiveEmail={receiveEmail}
                                handleConfirmOrder = {handleConfirmOrder}
                                handleAppleSubscription={handleAppleSubscription}
                                showLoading={showLoading}
                                loading={loading}
                                paymentType={paymentType}
                                discount={discount}
                                penAdapter={penAdapter}
                                paywallType={props.paywallType}
                                helpUkraine={props.helpUkraine} />
                        </Elements>

                        { loggedIn != 1 ?                 
                        <div className="center-horizontal" style={{marginTop: "24px"}}>
                            <a className="tp-caption btn font-weight-semibold" style={{ color: "#707070", backgroundColor: "#ffffffff", padding: "10px 40px", fontSize: 16 }} onClick={handleLogin}>
                                Login
                            </a>
                        </div> : null
                        }
                    </section>
                }
            </div>
            <Modal
                visible={visible}
                footer={null}
                centered
                cancelButtonProps={{ style: { display: 'none' } }}
                maskClosable={false}
            >
                <div className="spin-logo-view">
                    <img className="spin-logo" src="/assets/img/spin_logo.png" />
                </div>
                <WheelComponent
                    segments={segments}
                    segColors={segColors}
                    onFinished={(winner) => onFinished(winner)}
                    primaryColor='#555555'
                    contrastColor='white'
                    buttonText='Spin'
                    isOnlyOnce={true}
                    size={spinnerWidth / 2 - 16}
                    upDuration={100}
                    downDuration={1000}
                    centerX={spinnerWidth / 2}
                    centerY={spinnerWidth / 2}
                    width={spinnerWidth}
                    height={spinnerWidth}
                    font={modalWidth >= 500? '18px Poppins-Regular' : '12px Poppins-Regular'}
                />
            </Modal>

        </Layout>
    );
}

export default Paywall;