import React, { useState, useEffect } from 'react';
import { URL, DOWNLOAD_URL } from '../config/api';
import PromoBox from "./common/PromoBox";
import RefundGuarantee from "./common/RefundGuarantee";
import { useParams } from "react-router-dom";
import axios from 'axios';

import Layout from './Layout';

const StickerDetail = () => {
    const params = useParams();
    const stickerId = params.id;
    const [stickers, setStickers] = useState([]);
    const [sticker, setSticker] = useState({});
    const premium = localStorage.getItem('premium');

    useEffect(() => {
        loadStickerDetail();
    }, []);

    const handleStickerClick = (sticker) => {
        window.open('/sticker-detail/' + sticker.Id, "_blank");
    }

    const handleDownloadSticker = (sticker, type) => {
        if(premium == 1) {
            if(type == 0) { //=====PNG
                window.open(`${DOWNLOAD_URL}/download.php?type=2&resource_id=${sticker.Id}`, "_blank")
            } else { //=====SVG
                window.open(`${DOWNLOAD_URL}/download.php?type=3&resource_id=${sticker.Id}`, "_blank")
            }
        } else {
            window.open('/payment', '_blank');
        }
    }

    const loadStickerDetail = async () => {
        try {
            const bodyFormData = new FormData();
            bodyFormData.append('StickerId', stickerId);
            const res = await axios.post(`${URL}/sticker/get_sticker_detail.php`, bodyFormData);
            console.log("AAA_STICKERS", res.data.RandomStickers);
            setStickers(res.data.RandomStickers);
            setSticker(res.data.Sticker);
        } catch(error) {
    
        }
    }

    return (
        <Layout>
            <div id="scroller" role="main" className="main tutorial" style={{ paddingTop: 140 }}>
				<div className="container">
                    <PromoBox />

                    <div className="row mb-5">
                        <h2 className="font-weight-bold text-9 center primary-color font-primary-bold">Cuts</h2>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <img src={sticker.ImageUrl} className="img-fluid1" alt="" onContextMenu={(e) => e.preventDefault()} />
                            <div className="font-weight-bold font-primary-bold text-4 mt-48 color-black">Category: {sticker.CategoryName}</div>
                            <div className="font-primary-extra-light mt-2 text-3 color-black color-black">Tags: {sticker.Tag}</div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="font-weight-bold primary-color font-primary-bold text-7">{sticker.ImageName}</div>
                            <div className="font-primary-extra-light mt-2 text-3 color-black">- Includes Personal and Commerical License</div>
                            <div className="font-primary-extra-light text-3 color-black">- Perfect for Cards, Mugs, T-Shirts, Phone Cases, Tumblrs, Koozies, etc</div>
                            <div className="font-primary-extra-light text-3 color-black">- Compatible with Vinyl and Pens</div>
                            <div className='mt-60' style={{ width: '84%' }}>
                                <button className="row-center col-5 tp-caption btn btn-primary btn-primary-override font-primary-bold text-center font-detail-download" onClick={() => handleDownloadSticker(sticker, 1)}>Download SVG</button>
                                <button className="row-center col-5 tp-caption btn btn-primary btn-primary-override font-primary-bold text-center font-detail-download ml-16" onClick={() => handleDownloadSticker(sticker, 0)}>Download PNG</button>
                            </div>
      
                            <RefundGuarantee />

                            <div className="font-weight-bold font-primary-bold text-4 mt-48 color-black">Works With:</div>
                            <div className="font-primary-extra-light mt-2 text-3 color-black color-black">Cricut Design Space</div>
                            <div className="font-primary-extra-light text-3 color-black">Silhouette Studio</div>
                            <div className="font-primary-extra-light text-3 color-black">Scan N Cut</div>
                            <div className="font-primary-extra-light text-3 color-black">Mac OS & Windows</div>
                            <div className="font-primary-extra-light text-3 color-black">Any Program that accepts TTF/OTF Files</div>
                        </div>
                    </div>

                    <>
                    <h2 className="font-weight-bold text-center font-primary-bold" style={{ fontSize: '1.2rem', marginTop: '5rem', marginBottom: '2rem'}}>You May Also Like:</h2>
                    <div className="row">
                        {stickers.map((item, index) => (
                            <div className="col-6 col-lg-4" key={index}>
                            <div className="item-box" onClick={() => handleStickerClick(item)}>
                                <div className='sticker-image'>
                                    <a>
                                        <img src={item.ImageUrl} className="img-fluid" alt="" />
                                    </a>
                                </div>
                                <div className="item-bottom-box">
                                    <div className="sticker-item-title">
                                        {item.ImageName}
                                    </div>
                                    {premium != 1 && <img className="download" src="/assets/img/new.png" />}
                                </div>
                            </div>
                        </div>      
                        ))}
                    </div>
                    </>
                </div>
            </div>
        </Layout>
    );
}

export default StickerDetail;