import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { URL } from '../../config/api';
import axios from 'axios';
import { closeResetPassword } from '../../redux/actions/appActions';
import './styles.css';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [linkSent, setLinkSent] = useState(false);
    const [resetMessage, setResetMessage] = useState("");

    function validateForm() {
        return email.length > 0 && !linkSent;
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const bodyFormData = new FormData();
        bodyFormData.append('Email', email);
    
        const res = await axios.post(`${URL}/login/forgot_password.php`, bodyFormData);
    
        if(res.data.Success) {
            setResetMessage(res.data.Message);
            setLinkSent(true);
        } else {
          alert(res.data.Message);
        }
    }

    const handleLogin = () => {
        window.location.href = '/login';
    }
    

    return (
        <div className="login-container">
            <img src="/assets/img/cancel.svg" width="15" style={{ position: 'absolute', top: 50, right: 50, cursor: 'pointer' }} onClick={() => {
                dispatch(closeResetPassword());
            }} />
            <form className="login-form" onSubmit={handleSubmit}>
                <h2>Forgot Password?</h2>
                <p>Please enter your email address</p>
                <div>Email</div>
                <input onChange={(e) => setEmail(e.target.value)} disabled={linkSent} />   
                {linkSent? <div className="Text-small">{resetMessage}</div> : null}
                <button type="submit" className="tp-caption btn btn-secondary font-primary-regular" style={{ width: '100%', justifyContent: 'center', marginTop: 25 }} disabled={!validateForm()}>Reset Your Password</button>
            </form>            
        </div>
    );
}

export default ForgotPassword;