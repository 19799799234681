import React, { useState } from 'react';
import { URL } from '../config/api';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';

const ResetPassword = () => {
    const params = useParams();
    const history = useHistory();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [updating, setUpdating] = useState(false);

    function validateForm() {
        return password.length > 0 && confirmPassword.length > 0 && confirmPassword == password;
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        setUpdating(true);
        const bodyFormData = new FormData();
        bodyFormData.append('Token', params.token);
        bodyFormData.append('Password', password);

        const res = await axios.post(`${URL}/login/reset_password.php`, bodyFormData);
    
        if(res.data.Success) {
            history.push('/login')
        } else {
            alert(res.data.Message);
        }
        setUpdating(false);
    }

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <h2>Reset Password</h2>
                <div>Password</div>
                <input type="password" onChange={(e) => setPassword(e.target.value)} />   
                <div style={{marginTop: "25px"}}>Confirm Password</div>
                <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} />   
                <button type="submit" className="tp-caption btn btn-secondary font-primary-regular" style={{ width: '100%', justifyContent: 'center', marginTop: 25 }} disabled={!validateForm() || updating}>Reset Your Password</button>
            </form>            
        </div>
    );
}

export default ResetPassword;