export const SHOW_LOGIN = 'SHOW_LOGIN';
export const CLOSE_LOGIN = 'CLOSE_LOGIN';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CLOSE_RESET_PASSWORD = 'CLOSE_RESET_PASSWORD';
export const SHOW_LOGIN_LIVEWORKSHOP = 'SHOW_LOGIN_LIVEWORKSHOP';

export function showLogin() {
    return {
        type: SHOW_LOGIN
    };
}

export function closeLogin() {
    return {
        type: CLOSE_LOGIN
    };
}

export function showResetPassword() {
    return {
        type: RESET_PASSWORD
    };
}

export function closeResetPassword() {
    return {
        type: CLOSE_RESET_PASSWORD
    };
}

export function showLoginLiveWorkshop() {
    return {
        type: SHOW_LOGIN_LIVEWORKSHOP
    };
}
