import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { URL } from '../../config/api';
import { closeLogin, showResetPassword } from '../../redux/actions/appActions';
import './styles.css';

const Login = () => {
    const liveWorkshop = useSelector(state => state.app.liveWorkshop);

    const location = useLocation();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    const handlePurchase = () => {
        removeLogin();
        window.location.href = '/payment';
    }

    const goToResetPassword = () => {
        dispatch(showResetPassword());
    }

    const removeLogin = () => {
        dispatch(closeLogin());
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const bodyFormData = new FormData();
        bodyFormData.append('Email', email);
        bodyFormData.append('Password', password);
    
        const res = await axios.post(`${URL}/login/login.php`, bodyFormData);
    
        if(res.data.Success) {
            removeLogin();
            localStorage.setItem('auth', 1);
            localStorage.setItem('id', res.data.Data.Id);
            localStorage.setItem('token', res.data.Data.Token);
            localStorage.setItem('email', res.data.Data.Email);
            localStorage.setItem('next_billing', res.data.Data.NextBilling);
            localStorage.setItem('premium', res.data.Data.Premium);
            localStorage.setItem('customer_id', res.data.Data.CustomerId);
            localStorage.setItem('type', res.data.Data.Type);
            localStorage.setItem('elite', res.data.Data.Elite);

            if (liveWorkshop) {
                window.location.href = '/liveworkshop-payment/';
            } else {
                if(location.state) {
                    if (location.state.detail) {
                        var token = location.state.detail;
                        window.location.href = '/cancel/' + token;
                    }
                } else {
                    window.location.href = '/projects'
                }
            }
        } else {
            alert(res.data.Message);
        }
    }

    return (
        <div className="login-container">
            <img src="/assets/img/cancel.svg" width="15" style={{ position: 'absolute', top: 50, right: 50, cursor: 'pointer' }} onClick={removeLogin} />
            <form className="login-form" onSubmit={handleSubmit}>
                <h2>Log In</h2>
                <p>New to this site?<span style={{ color: '#00e689', cursor: 'pointer' }} onClick={handlePurchase}>Purchase Here</span></p>
                <div>Email</div>
                <input onChange={(e) => setEmail(e.target.value)}/>
                <div style={{ marginTop: 25 }}>Password</div>
                <input type='password' onChange={(e) => setPassword(e.target.value)}/>
                <div style={{ marginTop: 35, marginBottom: 10, textDecoration: 'underline', cursor: 'pointer' }} onClick={goToResetPassword}>Forgot password?</div>
                <button className="tp-caption btn btn-secondary font-primary-regular" style={{ width: '100%', justifyContent: 'center' }} disabled={!validateForm()} type="submit">Log In</button>
            </form>            
        </div>
    );
}

export default Login;