const PromoBox = (props) => {
    const premium = localStorage.getItem('premium');
	return (
        premium != 1 && <div className='d-flex promo align-items-center'>
            <div className='promo-text'>
                ​Become a CraftersPro Member
            </div>
            <a className="btn btn-white font-weight-semibold primary-color join" href="/payment" target="_blank">
                Join
            </a>
        </div>
    )
};

export default PromoBox;
