import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Drawer, Input, Modal } from 'antd';
import { URL, DOWNLOAD_URL } from '../config/api';
import firebase from "firebase/compat/app";
import ReactPlayer from 'react-player';

import Layout from './Layout';

const Detail = () => {
    const [visible, setVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const location = useLocation();
    const [project, setProject] = useState({});
    const [photoProjects, setPhotoProjects] = useState([]);
    const premium = localStorage.getItem('premium');
    const [info, setInfo] = useState('');

    const openModal = () => {
        setVisible(true);
    }

    useEffect(() => {
        if(location.state) {
            let photoProject1 = location.state.detail.photoProject1;
            let photoProject2 = location.state.detail.photoProject2;
            let photoProject3 = location.state.detail.photoProject3;
            let photoProject4 = location.state.detail.photoProject4;
            window.tempProjectPhotos = [];

            if(photoProject1 != "" && photoProject1 != "none") {
                window.tempProjectPhotos.push({photoProject: photoProject1});
            }
            if(photoProject2 != "" && photoProject2 != "none") {
                window.tempProjectPhotos.push({photoProject: photoProject2});
            }
            if(photoProject3 != "" && photoProject3 != "none") {
                window.tempProjectPhotos.push({photoProject: photoProject3});
            }
            if(photoProject4 != "" && photoProject4 != "none") {
                window.tempProjectPhotos.push({photoProject: photoProject4});
            }

            let tempProject = location.state.detail;
            setProject(tempProject);
        
            var tempInfo = "";
            if(tempProject.Size != "") {
                tempInfo = tempInfo + "\n\nSize: " + tempProject.Size;
            }

            if(tempProject.Materials != "") {
                tempInfo = tempInfo + "\n\nMaterials Needed: " + tempProject.Materials;
            }

            if(tempProject.Preparation != "") {
                tempInfo = tempInfo + "\n\nPreparation: " + tempProject.Preparation;
            }

            if(tempProject.Cut != "") {
                tempInfo = tempInfo + "\n\nCut: " + tempProject.Cut;
            }

            if(tempProject.Assemble != "") {
                tempInfo = tempInfo + "\n\nAssemble: " + tempProject.Assemble;
            }

            if(tempProject.Instruction != "") {
                tempInfo = tempInfo + "\n\nInstruction: " + tempProject.Instruction;
            }
            
            setPhotoProjects(window.tempProjectPhotos);
            setInfo(tempInfo);
        } else {
            window.location.href = '/projects';
        }
    
        firebase.analytics().logEvent('project_detail_page_loaded');
    }, []);
        
    useEffect(() => {
        window.topFunction();
    }, []);

    const handleMakeCraftClick = () => {
        if(premium == 1) {
            window.open(`${DOWNLOAD_URL}/download.php?type=0&resource_id=${project.Id}`, "_blank")
        } else {
            window.open('/payment', '_blank');
        }
    }

    const handleInsturctionClick = () => {
        if(premium == 1) {
            setModalVisible(true);
          } else {
            window.open('/payment', '_blank');
          }
    }

    return (
        <Layout>
            <div id="scroller" role="main" className="main tutorial" style={{ paddingTop: 140 }}>
				<div className="mid-container">
                    <div className="row">
                        <div className="col-12 col-md-7" style={{display: 'flex'}}>
                            <img src={project.photoExample} className="img-fluid1" alt="" />
                            {project.Elite == 1 && <img className="elite-mark1" src="/assets/img/elite.png" />}
                        </div>
                        <div className="col-12 col-md-5">
                            <h2 className="font-weight-bold center primary-color font-primary-bold" style={{ fontSize: '1.2rem'}}>{project.Name}</h2>
                            <p className="mt-2" style={{ lineHeight: '1.2', fontWeight: 500 }}>{project.Description}</p>
                            <button className="tp-caption btn btn-primary btn-primary-override font-primary-regular text-center" style={{ width: '100%', justifyContent: 'center' }} onClick={handleMakeCraftClick}>Make Craft</button>
                            <button className="tp-caption btn btn-secondary font-primary-regular text-center" style={{ width: '100%', justifyContent: 'center' }} onClick={handleInsturctionClick}>Instructions</button>
                       
                            {project.Elite == 1 && <>
                                <h2 className="font-weight-bold primary-color font-primary-bold" style={{ fontSize: '1.2rem', marginTop: '.2rem', marginBottom: '.2rem'}}>Assets</h2>
                                <div className="projects-assets" style={{ marginBottom: '.2rem' }}>
                                    {photoProjects.map((item, index) => (
                                        <div key={index}>
                                            <img src={premium == 1 ? item.photoProject : "/assets/img/padlock.svg"} />
                                        </div>
                                    ))}

                                    {project.Font && <div style={{fontFamily: "Font" + project.Font.Id}}>
                                        {project.Font.Name}
                                    </div>}
                            </div></>}
                        </div>
                    </div>

                    {project.Elite == 0 && <>
                    <h2 className="font-weight-bold text-center primary-color font-primary-bold" style={{ fontSize: '1.2rem', marginTop: '5rem', marginBottom: '4rem'}}>Assets</h2>
                    <div className={premium == 1 ? "projects-assets" : "projects-assets-lock"} style={{ marginBottom: '5rem' }}>
                        {photoProjects.map((item, index) => (
                            <div key={index}>
                                <img src={premium == 1 ? item.photoProject : "/assets/img/padlock.svg"} />
                            </div>
                        ))}
                        
                    </div></>}
                </div>

                {project.Elite == 1 && <div style={{ backgroundImage: 'url(/assets/img/elite_banner.png)'}}>
                    <div className="mid-container">
                        <div className="row" style={{paddingTop: "36px", paddingBottom: "24px"}}>
                            <div className="col-12 col-md-7" style={{marginBottom: "12px"}}>
                                <div className="elite-tut-video" onClick={openModal}>
                                    <img src={project.photoExample} className="img-elite-tut-video"/>
                                    <img src="/assets/img/play.png" className="play"/>
                                    <img src="/assets/img/elite_mark.png" className="elite-mark-sm"/>
                                </div>
                            </div>
                            <div className="col-12 col-md-5">
                                <div style={{marginBottom: "0.5rem"}}>
                                    <img src="/assets/img/elite_tut_text.png" className="img1" style={{marginLeft: "0.5rem"}} />
                                    <img src="/assets/img/watchnow.png" className="img2" style={{marginLeft: "0.5rem"}} onClick={openModal} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <Modal
                    visible={modalVisible}
                    title={<div style={{ display: 'flex', justifyContent: 'space-between' }}><span>Instruction</span><span>X</span></div>}
                    footer={null}
                    onCancel={() => setModalVisible(false)} 
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <h5 class="project-info">{info}</h5>
                </Modal>

                <Modal
                    visible={visible}
                    footer={null}
                    onCancel={() => { setVisible(false) }} 
                    centered
                    width={'80%'}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <ReactPlayer className="Video-player1" url={project.EliteVideo} width="100%" height="100%" loop={true} controls={true}/>
                </Modal>
            </div>
        </Layout>
    );
}

export default Detail;