import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import Header from './Header';
import Footer from './Footer';
import Login from './auth/Login';
import ForgotPassword from './auth/ForgotPassword';
import { closeLogin, closeResetPassword } from '../redux/actions/appActions';

const Layout = ({ children, noHeader, workshop, workshopPayment }) => {
    const loginVisible = useSelector(state => state.app.loginVisible);
    const resetVisible = useSelector(state => state.app.resetVisible);

    useEffect(() => {
        if(loginVisible) {
            window.showModal('loginModal');
        } else {
            window.hideModal('loginModal');
        }
    }, [loginVisible]);

    useEffect(() => {
        if(resetVisible) {
            window.showModal('resetModal');
        } else {
            window.hideModal('resetModal');
        }
    }, [resetVisible]);

    // if(loginVisible) return <Login />

    // if(resetVisible) return <ForgotPassword />

    return (
        <>
            {/* {loginVisible && <Login />}
            {resetVisible && <ForgotPassword />} */}
            <div className="body">
                {!noHeader && !loginVisible && !resetVisible && <Header workshop={workshop} workshopPayment={workshopPayment} />}
                {children}
                <Footer />
            </div>
            {/* <Modal
                visible={loginVisible}
                footer={null}
                onCancel={() => dispatch(closeLogin())} 
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Login />
            </Modal>
            <Modal
                visible={resetVisible}
                footer={null}
                onCancel={() => dispatch(closeResetPassword())} 
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <ForgotPassword />
            </Modal> */}
            <div className="modal fade" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ overflow: 'hidden' }}>
                <div className="modal-dialog text-left" role="document">
                    <div className="modal-content">
                        <Login />
                    </div>
                </div>
            </div>
            <div className="modal fade" id="resetModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ overflow: 'hidden' }}>
                <div className="modal-dialog text-left" role="document">
                    <div className="modal-content">
                        <ForgotPassword />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Layout;