import { useParams, useHistory } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { URL } from '../config/api';
import axios from 'axios';

function Cancel() {
  const params = useParams();
  const history = useHistory();
  const userId = localStorage.getItem('id');
  const loggedIn = localStorage.getItem('auth');

  useEffect(() => {
    if(loggedIn != 1) {
        history.push({
            pathname: '/login',
            state: {detail: params.token}
        });
    }
  }, []);

  const handleCancelClick = () => {
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
        cancelSubscription();
    } else {
        history.push('/projects');
    }
  }

  const cancelSubscription = async (e) => {
    const bodyFormData = new FormData();
    bodyFormData.append('UserId', userId);
    bodyFormData.append('Token', params.token);
  
    const res = await axios.post(`${URL}/stripe/cancel_subscription.php`, bodyFormData);
  
    if(res.data.Success) {
        alert(res.data.Message);
    } else {
        alert(res.data.Message);
    }

    history.push('/projects');
  }

  return (
    <div role="main" className="main" style={{ paddingTop: 140 }}>
		<div className="mid-container" style={{ justifyContent: "center", textAlign: "center"}}>
            <a className="tp-caption btn btn-primary btn-primary-override font-weight-semibold" style={{ color: "#fff", padding: "14px 70px", fontSize: 16 }} onClick={handleCancelClick}>Cancel Subscription</a>
            <div>
                <p>If you would like to request a refund you can do so <a href="/contact" target="_blank" style={{textDecoration: "underline"}}>here</a></p>
            </div>
        </div>
    </div>
  );
}

export default Cancel;
