import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import './App.css';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Detail from './pages/Detail';
import FontDetail from './pages/FontDetail';
import StickerDetail from './pages/StickerDetail';
import Paywall from './pages/Paywall';
import PaywallStandard from './pages/PaywallStandard';
import PaymentDetail from './pages/PaymentDetail';
import PaywallElite from './pages/PaywallElite';
import UpgradeElite from './pages/UpgradeElite';
import Fonts from './pages/Fonts';
import Stickers from './pages/Stickers';
import Tutorial from './pages/Tutorial';
import Privacy from './pages/Privacy';
import Faq from './pages/Faq';
import Terms from './pages/Terms';
import Contact from './pages/Contact';
import Cancel from './pages/Cancel';
import ResetPassword from './pages/ResetPassword';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import About from './pages/About';
import LiveWorkshop from './pages/LiveWorkshop';
import LiveWorkshopPayment from './pages/LiveWorkshopPayment';
import Workshop from './pages/Workshop';
import WorkshopPayment from './pages/WorkshopPayment';
import Ebook from './pages/Ebook';
import EbookPurchased from './pages/EbookPurchased';
import ReactPixel from 'react-facebook-pixel';
import PaywallUltimate from './pages/PaywallUltimate';

const wrappedRoutes = () => (
	<div>
		{/* <Header /> */}
		<Route exact path='/' component={Home} />
		<Route exact path='/projects' component={Projects} />
		<Route exact path='/project-detail/:id' component={Detail} />
		<Route exact path='/font-detail/:id' component={FontDetail} />
		<Route exact path='/sticker-detail/:id' component={StickerDetail} />
		<Route
			exact
			path='/payment'
			component={() => <PaywallStandard />}
		/>
		<Route
			exact
			path='/starterpack'
			component={() => <PaywallUltimate />}
		/>
		<Route exact path='/payment-detail/:type' component={PaymentDetail} />
		<Route
			exact
			path='/payment-wheel'
			component={() => <Paywall paywallType={1} />}
		/>
		<Route
			exact
			path='/payment-promo'
			component={() => <Paywall paywallType={2} />}
		/>
		<Route
			exact
			path='/payment-pens'
			component={() => <Paywall paywallType={3} />}
		/>
		<Route
			exact
			path='/payment/:link'
			component={() => <Paywall paywallType={0} />}
		/>
		<Route exact path='/payment-elite' component={() => <PaywallElite />} />
		<Route
			exact
			path='/payment-upgrade-elite'
			component={() => <UpgradeElite />}
		/>
		<Route
			exact
			path='/payment-75off'
			component={() => <Paywall paywallType={5} />}
		/>
		<Route
			exact
			path='/payment-helpukraine'
			component={() => <Paywall paywallType={0} helpUkraine={1} />}
		/>
		<Route
			exact
			path='/payment-helpukraine-75off'
			component={() => <Paywall paywallType={5} helpUkraine={1} />}
		/>
		<Route
			exact
			path='/payment-promo50off'
			component={() => <Paywall paywallType={6} />}
		/>
		<Route
			exact
			path='/liveworkshop'
			component={LiveWorkshop}
		/>
		<Route
			exact
			path='/workshop'
			component={Workshop}
		/>
		<Route
			exact
			path='/liveworkshop-payment'
			component={LiveWorkshopPayment}
		/>
		<Route
			exact
			path='/workshop-payment'
			component={WorkshopPayment}
		/>
		<Route
			exact
			path='/ebook'
			component={Ebook}
		/>
		<Route
			exact
			path='/ebook-purchased'
			component={EbookPurchased}
		/>
		<Route exact path='/login' component={() => <Home pageType={1} />} />
		<Route exact path='/fonts' component={Fonts} />
		<Route exact path='/stickers' component={Stickers} />
		<Route exact path='/stickers/:id' component={Stickers} />
		<Route exact path='/tutorial' component={Tutorial} />
		<Route exact path='/privacy' component={Privacy} />
		<Route exact path='/faq' component={Faq} />
		<Route exact path='/term' component={Terms} />
		<Route exact path='/about' component={About} />
		<Route exact path='/contact' component={Contact} />
		<Route exact path='/cancel/:token' component={Cancel} />
		<Route exact path='/reset_password/:token' component={ResetPassword} />
	</div>
);

const initMetaPixel = () => {
	const advancedMatching = { em: 'info@crafterspro.net' };
	const options = {
		autoConfig: true,
		debug: true,
	};
	ReactPixel.init("910809040228411", advancedMatching, options);
}

function App() {
	const firebaseConfig = {
		apiKey: 'AIzaSyASoO_m3J0qvpWafC5yR6RrXJZkkkqHLqg',
		authDomain: 'crafters-pro-64edd.firebaseapp.com',
		projectId: 'crafters-pro-64edd',
		storageBucket: 'crafters-pro-64edd.appspot.com',
		messagingSenderId: '785451129629',
		appId: '1:785451129629:web:6ab2446a62884d67b51ff4',
		measurementId: 'G-PERMN33GG3',
	};
	// Initialize Firebase
	if (!firebase.apps.length) {
		firebase.initializeApp(firebaseConfig);
	}
	firebase.analytics();
	console.log("AAA_INIT_META_PIXEL");
	initMetaPixel();

	return (
		<BrowserRouter>
			<Switch>
				{/* <Route exact path='/login' component={Login} />
        <Route exact path='/signup' component={Signup} />
        <Route exact path='/forgotpassword' component={ForgotPassword} /> */}
				<Route path='/' component={wrappedRoutes} />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
