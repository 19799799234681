import React, { useState } from 'react';
import Layout from './Layout';

const Contact = () => {
    const openModal = () => {
        window.showModal('exampleModal');
    }

    return (
        <Layout>
			<div role='main' className='main' style={{ paddingTop: 140 }}>
                <div className='row mb-4'>
                    <h3
                        className='font-weight-bold text-6 center primary-color font-primary-bold'
                        data-appear-animation='fadeInDown'
                        data-appear-animation-delay='100'>
                        Contact Us
                    </h3>
                </div>

				<div className='container about'>
                    <div
                        className='row'
                        data-appear-animation='fadeInLeft'
                        data-appear-animation-delay='800'>
                        <div className='col-4'>
                            <img src={'/assets/img/about_avatar_3.png'} width='100%' />
                        </div>
                        <div className='col-8 d-flex-center-notext'>
                            <p
                                className='text-3'
                                style={{ lineHeight: '20px' }}>
                                Hey. I'm <b>Veronica Satos</b>, the CraftersPro customer support agent.
                                Please let me know about your inquiry below, and I will respond within 1 business day. Thanks!
                            </p>
                        </div>
                    </div>
                </div>
  
                <div className='contact mt-32'>
                    <iframe frameborder="0" src="https://www.emailmeform.com/builder/embed/08z39jTFDfmNde3g1sU6C"><a href="https://www.emailmeform.com/builder/embed/08z39jTFDfmNde3g1sU6C">Fill out form.</a></iframe>
                </div>
            </div>
        </Layout>
    );
}

export default Contact;