import React, { useEffect, useState } from 'react';

import Layout from './Layout';

const Privacy = () => {
  return (
    <Layout noHeader>
        <div role="main" className="main" style={{ paddingTop: 40 }}>
          <div style={{ padding: '0 5rem', fontFamily: `'Open Sans', sans-serif`}}>
            <div className="row mb-5">
              <h2 className="font-weight-bold text-9 center primary-color font-primary-bold">Privacy Policy</h2>
            </div>
            <div>
            Protecting your private information is our priority. This Statement of Privacy applies to https://www.crafterspro.net/, and Crafters Pro, LLC and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Crafters Pro, LLC include https://www.crafterspro.net/ and CraftersPro.Net. The Crafters Pro, LLC website is a To provide Cricut Projects, Fonts, Images, & More! site. By using the Crafters Pro, LLC website, you consent to the data practices described in this statement.
            <br/>
            Collection of your Personal Information<br/>
            In order to better provide you with products and services offered, Crafters Pro, LLC may collect personally identifiable information, such as your:<br/>
            <br/>
            - First and Last Name<br/>
            - E-mail Address<br/>
            <br/>
            If you purchase Crafters Pro, LLC's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.<br/>
            <br/>
            We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.<br/>
            <br/>
            Use of your Personal Information<br/>
            Crafters Pro, LLC collects and uses your personal information to operate and deliver the services you have requested.<br/>
            <br/>
            Crafters Pro, LLC may also use your personally identifiable information to inform you of other products or services available from Crafters Pro, LLC and its affiliates.<br/>
            <br/>
            Sharing Information with Third Parties<br/>
            Crafters Pro, LLC does not sell, rent or lease its customer lists to third parties.<br/>
            <br/>
            Crafters Pro, LLC may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. Crafters Pro, LLC may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Crafters Pro, LLC, and they are required to maintain the confidentiality of your information.<br/>
            <br/>
            Crafters Pro, LLC may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Crafters Pro, LLC or the site; (b) protect and defend the rights or property of Crafters Pro, LLC; and/or (c) act under exigent circumstances to protect the personal safety of users of Crafters Pro, LLC, or the public.<br/>
            <br/>
            Tracking User Behavior<br/>
            Crafters Pro, LLC may keep track of the websites and pages our users visit within Crafters Pro, LLC, in order to determine what Crafters Pro, LLC services are the most popular. This data is used to deliver customized content and advertising within Crafters Pro, LLC to customers whose behavior indicates that they are interested in a particular subject area.<br/>
            <br/>
            Automatically Collected Information<br/>
            Information about your computer hardware and software may be automatically collected by Crafters Pro, LLC. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Crafters Pro, LLC website.<br/>
            <br/>
            Links<br/>
            This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.<br/>
            <br/>
            Security of your Personal Information<br/>
            Crafters Pro, LLC secures your personal information from unauthorized access, use, or disclosure. Crafters Pro, LLC uses the following methods for this purpose:<br/>
            <br/>
            - SSL Protocol<br/>
            <br/>
            When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.<br/>
            <br/>
            We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.<br/>
            <br/>
            Right to Deletion<br/>
            Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:<br/>
            • Delete your personal information from our records; and<br/>
            • Direct any service providers to delete your personal information from their records.<br/>
            <br/>
            Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:<br/>
            • Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;<br/>
            • Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;<br/>
            • Debug to identify and repair errors that impair existing intended functionality;<br/>
            • Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;<br/>
            • Comply with the California Electronic Communications Privacy Act;<br/>
            • Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;<br/>
            • Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;<br/>
            • Comply with an existing legal obligation; or<br/>
            • Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.<br/>
            <br/>
            Children Under Thirteen<br/>
            Crafters Pro, LLC does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.<br/>
            <br/>
            E-mail Communications<br/>
            From time to time, Crafters Pro, LLC may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from Crafters Pro, LLC or click on a link therein.<br/>
            <br/>
            If you would like to stop receiving marketing or promotional communications via email from Crafters Pro, LLC, you may opt out of such communications by Clicking on the UNSUBSCRIBE button within the email..<br/>
            <br/>
            External Data Storage Sites<br/>
            We may store your data on servers provided by third party hosting vendors with whom we have contracted.<br/>
            <br/>
            Changes to this Statement<br/>
            Crafters Pro, LLC reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.<br/>
            <br/>
            Contact Information<br/>
            Crafters Pro, LLC welcomes your questions or comments regarding this Statement of Privacy. If you believe that Crafters Pro, LLC has not adhered to this Statement, please contact Crafters Pro, LLC at:<br/>
            <br/>
            Crafters Pro, LLC<br/>
            PO Box 8348<br/>
            Greensboro, North Carolina 27419<br/>
            <br/>
            Email Address:<br/>
            support@crafterspro.zendesk.com<br/>
            <br/>
            Effective as of February 16, 2021<br/>
            <br/>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Privacy;
