import React, { useState, useEffect } from 'react';
import { URL, DOWNLOAD_URL } from '../config/api';
import PromoBox from "./common/PromoBox";
import RefundGuarantee from "./common/RefundGuarantee";
import { useParams } from "react-router-dom";
import axios from 'axios';

import Layout from './Layout';

const FontDetail = () => {
    const params = useParams();
    const fontId = params.id;
    const [preview, setPreview] = useState('');
    const [tempPreview, setTempPreview] = useState('');
    const [fonts, setFonts] = useState([]);
    const [font, setFont] = useState({});
    const premium = localStorage.getItem('premium');
    const elite = localStorage.getItem('elite');
    const userType = localStorage.getItem('type');

    useEffect(() => {
        loadFontDetail();
    }, []);

    const handlePreviewChange = (value) => {
        setTempPreview(value);
    }

    const handleDownloadClick = () => {
        if(premium == 1) {
            window.open(`${DOWNLOAD_URL}/download.php?type=1&resource_id=${fontId}`, "_blank")
        } else {
            window.open('/payment', '_blank');
        }
    }

    const handleFontClick = (font) => {
        window.open('/font-detail/' + font.Id, "_blank");
    }

    const handlePreviewClick = () => {
        setPreview(tempPreview);
    }

    const loadFontDetail = async () => {
        try {
            const bodyFormData = new FormData();
            bodyFormData.append('FontId', fontId);
            bodyFormData.append('Elite', (userType == 6 || elite == 1)? 1 : 0);
            const res = await axios.post(`${URL}/font/get_font_detail.php`, bodyFormData);
            setFonts(res.data.RandomFonts);
            setFont(res.data.Font);
            setPreview(res.data.Font.Name);
        } catch(error) {
    
        }
    }

    return (
        <Layout>
            <div id="scroller" role="main" className="main tutorial" style={{ paddingTop: 140 }}>
				<div className="container">
                    <PromoBox />

                    <div className="row mb-5">
                        <h2 className="font-weight-bold text-9 center primary-color font-primary-bold">Fonts</h2>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="font-item text-15 text-center" style={{fontFamily: "Font" + fontId}}>
                                {preview}
                            </div>

                            <div className="font-weight-bold font-primary-bold text-4 mt-48 color-black">Preview with Custom Text</div>
                            <input className="form-input-font-preview font-primary-extra-light" type="text" placeholder="Enter Text" onChange={(e) => handlePreviewChange(e.target.value)}/>
                            <button className="tp-caption btn btn-primary btn-primary-override font-primary-bold text-center font-detail-preview text-3" onClick={handlePreviewClick}>Preview</button>

                        </div>

                        <div className="col-12 col-md-6">
                            <div className="font-weight-bold primary-color font-primary-bold text-7">{font.Name}</div>
                            <div className="font-primary-extra-light mt-2 text-3 color-black">- Includes Personal and Commerical License</div>
                            <div className="font-primary-extra-light text-3 color-black">- Perfect for Cards, Mugs, T-Shirts, Phone Cases, Tumblrs, Koozies, etc</div>
                            <div className="font-primary-extra-light text-3 color-black">- Compatible with Vinyl and Pens</div>
                            <button className="tp-caption btn btn-primary btn-primary-override font-primary-bold text-center mt-60 font-detail-download text-3" style={{ width: '80%', justifyContent: 'center' }} onClick={handleDownloadClick}>Download Font</button>
                            <RefundGuarantee />

                            <div className="font-weight-bold font-primary-bold text-4 mt-48 color-black">Works With:</div>
                            <div className="font-primary-extra-light mt-2 text-3 color-black color-black">Cricut Design Space</div>
                            <div className="font-primary-extra-light text-3 color-black">Silhouette Studio</div>
                            <div className="font-primary-extra-light text-3 color-black">Scan N Cut</div>
                            <div className="font-primary-extra-light text-3 color-black">Mac OS & Windows</div>
                            <div className="font-primary-extra-light text-3 color-black">Any Program that accepts TTF/OTF Files</div>
                        </div>
                    </div>

                    <>
                    <h2 className="font-weight-bold text-center font-primary-bold" style={{ fontSize: '1.2rem', marginTop: '5rem', marginBottom: '2rem'}}>Other Fonts You May Like</h2>
                    <div className="row">
                        {fonts.map((item, index) => (
                            <div className="col-6 col-lg-4" key={index} onClick={() => handleFontClick(item)}>
                                    <div className="item-box">
                                        <div className="font-item-title">
                                            {item.Name}
                                        </div>
                                        <div className="font-item" style={{fontFamily: "Font" + item.Id}}>
                                            {item.Name}
                                        </div>
                                        <div className="font-item-custom" style={{fontFamily: "Font" + item.Id}}>
                                            Your Custom Text Here
                                        </div>

                                        <div className="item-bottom-box">
                                            <img className="download" src="/assets/img/download.png"/>
                                        </div>
                                    </div>
                            </div>  
                        ))}
                    </div>
                    </>
                </div>
            </div>
        </Layout>
    );
}

export default FontDetail;