import React, { useEffect, useState } from 'react';
import SearchBar from '../components/SearchBar';
import Layout from './Layout';
import { URL, DOWNLOAD_URL } from '../config/api';
import firebase from "firebase/compat/app";
import axios from 'axios';
import PromoBox from "./common/PromoBox";

const Fonts = () => {
    const [fonts, setFonts] = useState([]);
    const premium = localStorage.getItem('premium');
    const [loadedAll, setLoadedAll] = useState(false);
    const userType = localStorage.getItem('type');
    const elite = localStorage.getItem('elite');
    const [sortBy, setSortBy] = useState(0); //1: Most Popular, 2: Recently Released

    useEffect(() => {
        window.searchText = '';
        window.tempFonts = [];
    
        loadFonts(true);
        firebase.analytics().logEvent('font_page_loaded');
    }, []);

    useEffect(() => {
        searchFonts();
    }, [sortBy]);

    const loadFonts = async (isInitial) => {
        try {
            const bodyFormData = new FormData();
            bodyFormData.append('Elite', (userType == 6 || elite == 1)? 1 : 0);
            bodyFormData.append('LoadAll', isInitial? 0 : 1);
            const res = await axios.post(`${URL}/font/get_font_web.php`, bodyFormData);
            window.tempFonts = res.data;
            
            searchFonts();

            if(!isInitial) {
                setLoadedAll(true);
            }
        } catch(error) {
    
        }
    }

    function searchFonts() {
        let searchedFonts = window.tempFonts.filter(item => item.Name.toLowerCase().includes(window.searchText.toLowerCase()));
        if (sortBy == 1) {
            searchedFonts = searchedFonts.sort(function(a,b){return b.Downloads - a.Downloads});
        } else if (sortBy == 2){
            searchedFonts = searchedFonts.sort(function(a,b){
                if(a.Created < b.Created) { return 1; }
                if(a.Created > b.Created) { return -1; }
                return 0;
            });
        }
        setFonts(searchedFonts);
    }

    const handleSearch = (event) => {
        if (!loadedAll) {
            loadFonts(false);
            console.log("AAA_LOADING_ALL=====");
            return;
        }

        window.searchText = event.target.value;
        searchFonts();
    }
    
    const handleFontClick = (font) => {
        window.open('/font-detail/' + font.Id, "_self");
    }

    const handleSortByChange = (e) => {
        switch(e.target.value) {
            case "sort_by":
                setSortBy(0);
                break;
            case "most_popular":
                setSortBy(1);
                break;
            case "recently_released":
                setSortBy(2);
                break;
        }
    }

    return (
        <Layout>
			<div role="main" className="main" style={{ paddingTop: 140 }}>
                <div className="container font-container">
                    <PromoBox />

                    <div className="row mb-5">
                        <h2 className="font-weight-bold text-9 center primary-color font-primary-bold">Fonts</h2>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 20 }}>
                        <div style={{ width: 200 }}>
                            <div className="mr-3 mb-3 mb-sm-0">
                                <div className="custom-select-1">
                                    <select className="form-control border" onChange={handleSortByChange}>
                                        <option value="sort_by">Sort by</option>
                                        <option value="most_popular">Most popular</option>
                                        <option value="recently_released">Recently released</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <SearchBar marginLeft handleSearch={handleSearch} />
                    </div>

                    <div style={{ fontWeight: 'bold'}}>Fonts:</div>
                    <div className="row">
                        {fonts.map((item, index) => (
                          <div className="col-12 col-lg-6" key={index} onClick={() => handleFontClick(item)}>
                                <div className="item-box">
                                    <div className="font-item-title">
                                        {item.Name}
                                    </div>
                                    <div className="font-item" style={{fontFamily: "Font" + item.Id}}>
                                        {item.Name}
                                    </div>
                                    <div className="font-item-custom" style={{fontFamily: "Font" + item.Id}}>
                                        Your Custom Text Here
                                    </div>

                                    <div className="item-bottom-box">
                                        <img className="download" src="/assets/img/download.png"/>
                                    </div>
                                </div>
                        </div>  
                        ))}
                    </div>

                    {!loadedAll && <div className="row-center">
                        <a className="tp-caption btn font-weight-semibold" style={{ color: "#707070", backgroundColor: "#ffffffff", padding: "10px 40px", fontSize: 16 }} onClick={()=>loadFonts(false)}>
                            Load All
                        </a>
                    </div>}
                </div>
            </div>
        </Layout>
    );
}

export default Fonts;