import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SearchBar from '../components/SearchBar';
import { URL } from '../config/api';
import firebase from "firebase/compat/app";
import axios from 'axios';
import Layout from './Layout';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const Projects = () => {
    const [visible, setVisible] = useState(true);
	const history = useHistory();
    const location = useLocation();
    const userType = localStorage.getItem('type');
    const elite = localStorage.getItem('elite');

    const [projects, setProjects] = useState([]);
    const [categories, setCategories] = useState([]);
    const [curCategory, setCurCategory] = useState({});
    const [sortBy, setSortBy] = useState(0); //0: Normal, 1: A-Z, 2: Z-A
    const [curPage, setCurPage] = useState(0);
    const [projectCount, setProjectCount] = useState(0);

    useEffect(() => {
        window.searchText = '';
        window.tempProjects = [];

        loadProjects();
        firebase.analytics().logEvent('project_page_loaded');
    }, []);

    useEffect(() => {
        searchProjects();
    }, [curCategory, sortBy, curPage]);

    const loadProjects = async () => {
        try {
            const bodyFormData = new FormData();
            bodyFormData.append('Elite', (userType == 6 || elite == 1)? 1:0);
            const res = await axios.post(`${URL}/project/get_project_new.php`, bodyFormData);
            window.tempProjects = res.data.Data;
            let tempCategories = res.data.Data1;
            var selectedCategory;
            for(var i = 0; i < tempCategories.length; i++) {
                let category = tempCategories[i];
                category.Selected = false;

                if (location.state) {
                    if(category.Name.toLowerCase() == location.state.category.toLowerCase()) {
                        category.Selected = true;
                        selectedCategory = category;
                    }
                } else {
                    //Category restore for back from other page
                    const storedCategoryId = localStorage.getItem('category_id');
                    if (storedCategoryId != null) {
                        if(category.Id == storedCategoryId) {
                            category.Selected = true;
                            selectedCategory = category;
                        }
                    }
                }
  
            }

            var allCategory = {Id: 0, Name: 'All', Selected: true};
            if(selectedCategory) {
                allCategory.Selected = false;
            }

            tempCategories = [allCategory, ...tempCategories];
            setCategories(tempCategories);
      
            if(selectedCategory) {
                setCurCategory(selectedCategory);
            } else {
                setCurCategory(allCategory);
            }
        } catch(error) {

        }
    }

    function searchProjects() {
        var searchedProjects;
        if(curCategory.Id == 0) {
            searchedProjects = window.tempProjects.filter(item => item.Name.toLowerCase().includes(window.searchText.toLowerCase()));
        } else {
            searchedProjects = window.tempProjects.filter(item => item.Name.toLowerCase().includes(window.searchText.toLowerCase()) && item.CategoryId==curCategory.Id );
        }

        if(sortBy == 0) {
            searchedProjects = searchedProjects.sort(function(a,b){return a.CategorySortIndex - b.CategorySortIndex});
        } else if(sortBy == 1) {
            searchedProjects = searchedProjects.sort(function(a,b){
                if(a.Name < b.Name) { return -1; }
                if(a.Name > b.Name) { return 1; }
                return 0;
            });
        } else {
            searchedProjects = searchedProjects.sort(function(a,b){
                if(a.Name < b.Name) { return 1; }
                if(a.Name > b.Name) { return -1; }
                return 0;
            });
        }

        setProjectCount(searchedProjects.length);
        setProjects(searchedProjects.slice(curPage * 20, curPage * 20 + 20));
    }

    const handleCategoryClick = (item) => {
        console.log("AAA_ITEM", item.Id);
        localStorage.setItem('category_id', item.Id);
        setCurPage(0);
        let tempCategories = [...categories];
        for(var i = 0; i < tempCategories.length; i++) {
            let category = tempCategories[i];
            if(category.Id == item.Id) {
                category.Selected = true;
            } else {
                category.Selected = false;
            }
        }
    
        setCategories(tempCategories);
        setCurCategory(item);
        searchProjects();
    }

    const handleLeftArrowClick = () => {
        if(curPage > 0) {
          setCurPage(curPage - 1);
          window.scrollTo(0, 0)
        }
    }
    
    const handleRightArrowClick = () => {
        if(curPage < Math.ceil(projectCount / 20) - 1) {
          setCurPage(curPage + 1);
          window.scrollTo(0, 0)
        }
    }

    const handleSortByChange = (e) => {
        switch(e.target.value) {
            case "sort_by":
                setSortBy(0);
                break;
            case "name_asc":
                setSortBy(1);
                break;
            case "name_desc":
                setSortBy(2);
                break;
        }
    }

	const goToDetail = (project) => {
        history.push({
            pathname: `/project-detail/${project.Id}`,
            state: {detail: project}
        });
	}

    const handleSearch = (event) => {
        window.searchText = event.target.value;
        searchProjects();
    }

    return (
        <Layout>
            <div role="main" className="main" style={{ paddingTop: 140 }}>
				<div className="container">
                    <div className="row mb-5">
                        <h2 className="font-weight-bold text-9 center primary-color font-primary-bold">Projects</h2>
                    </div>
					<div className="row">
						<aside className="sidebar col-md-4 col-lg-3 order-1 order-md-1">
                            <div style={{display: 'flex'}}>
                                <span className="text-4 center font-secondary" style={{ padding: '1rem 1.5rem'}}>Filter by</span>
                                <div className="pagination">
                                    <FaChevronLeft color="#c1c6c7" onClick={() => handleLeftArrowClick()} className="page-arrow-left" />
                                    <div className="page-text">
                                        {(curPage + 1) + " / " + Math.ceil(projectCount / 20)}
                                    </div>
                                    <FaChevronRight color="#c1c6c7" onClick={() => handleRightArrowClick()} className="page-arrow-right"/>
                                </div>
                            </div>
                            <hr />
							<div className="accordion accordion-default accordion-toggle accordion-style-1" role="tablist">
								<div className="card">
									<div className="card-header accordion-header" role="tab" id="categories">
										<h5 className="mb-0">
											{/* <a data-toggle="collapse" data-target="#toggleCategories" >Categories</a> */}
                                            <a className="collapse" data-toggle="collapse" data-target="#toggleCategories" aria-expanded="false" aria-controls="accordionDefaultSmCollapse2" style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => setVisible(!visible)}>
                                                <span>Categories</span>
                                                <img src={`/assets/img/${visible ? 'icons8-minus-14' : 'icons8-plus-math-14'}.png`} style={{ opacity: 0.4 }}/>
                                            </a>
										</h5>
									</div>
									<div id="toggleCategories" className="accordion-body collapse show" role="tabpanel" aria-labelledby="categories">
										<div className="card-body">
											<ul className="list list-unstyled mb-0">
                                                
                                                {categories.map((item, index) => (
                                                    <li key={index}>
                                                        <a href="#" className={item.Selected ? 'active' : ''} onClick={() => handleCategoryClick(item)}>{item.Name}</a>
                                                    </li>
                                                ))}
					
											</ul>
										</div>
									</div>
								</div>
                            </div>
						</aside>
						<div className="col-md-8 col-lg-9 order-2 order-md-2 mb-5 mb-md-0">
							<div style={{ display: 'flex', justifyContent: 'end', marginBottom: 20 }}>
                                <div style={{ width: 200 }}>
                                    <div className="col-auto mb-3 mb-sm-0">
                                        <form method="get">
                                            <div className="custom-select-1">
                                                <select className="form-control border" onChange={handleSortByChange}>
                                                    <option value="sort_by">Sort by</option>
                                                    <option value="name_asc">Name A-Z</option>
                                                    <option value="name_desc">Name Z-A</option>
                                                </select>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <SearchBar handleSearch={handleSearch} />
                            </div>
							<div className="row">
								{projects.map((item, index) => (
									<div className="col-6 col-md-3 mb-4" key={index}>
										<div className="product portfolio-item portfolio-item-style-2" onClick={()=>goToDetail(item)}>
											<div className="image-frame image-frame-style-1 image-frame-effect-2 mb-3">
												<span className="image-frame-wrapper image-frame-wrapper-overlay-bottom image-frame-wrapper-overlay-light image-frame-wrapper-align-end">
													<a style={{width: '100%', display: 'flex'}}>
														<img src={item.photoExampleSm} className="img-fluid" alt="" />
                                                        {item.Elite == 1 && <img className="elite-mark" src="/assets/img/elite.png" />}
													</a>
													<span className="image-frame-action">
														<a className="font-weight-semibold text-color-light">Quick View</a>
													</span>
												</span>
											</div>
											<div className="product-info d-flex flex-column flex-lg-row justify-content-between">
												<div className="product-info-title">
													<a>{item.Name}</a>
												</div>
											</div>
										</div>
									</div>									
								))}
							</div>
                            <div className="pagination float-right">
                                <FaChevronLeft color="#c1c6c7" onClick={() => handleLeftArrowClick()} className="page-arrow-left" />
                                <div className="page-text">
                                    {(curPage + 1) + " / " + Math.ceil(projectCount / 20)}
                                </div>
                                <FaChevronRight color="#c1c6c7" onClick={() => handleRightArrowClick()} className="page-arrow-right"/>
                            </div>
						</div>
					</div>
				</div>
 			</div>
        </Layout>
    );
}

export default Projects;