import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player'
import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import axios from 'axios';
import Layout from './Layout';
import { showLogin } from '../redux/actions/appActions';
import { useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";
import { URL, META_PIXEL_ID } from '../config/api';
import ReactPixel from 'react-facebook-pixel';

const Home = (props) => {
    const dispatch = useDispatch();
    const premium = localStorage.getItem('premium');
    const params = useParams();
    const [linkId, setLinkId] = useState(0);
    const [fonts, setFonts] = useState([]);

    const handlePurchase = () => {
        ReactPixel.track('Initiate Checkout');
        window.open('/payment', '_blank');
    }

    const dispatchLogin = () => {
        dispatch(showLogin());
    }

    useEffect(() => {
        console.log("AAA_PAGE_TYPE", params.pageType);
        if(props.pageType && props.pageType == 1) {
            dispatchLogin();
        }

        if(params.link) {
            if(params.link == "link1") {
                setLinkId(1);
                addClick(1);
            } else if(params.link == "link2") {
                setLinkId(2);
                addClick(2);
            }
        }
    
        window.scrollTo(0, 0);
        firebase.analytics().logEvent('home_page_loaded');
    
        validateUser();
        loadNewFonts();

        initMetaPixel();
    }, [])

    const initMetaPixel = () => {
        const advancedMatching = { em: 'info@crafterspro.net' };
        const options = {
            autoConfig: true,
            debug: true,
        };
        ReactPixel.init(META_PIXEL_ID, advancedMatching, options);
        ReactPixel.track('View Content');
    }

    const loadNewFonts = async () => {
        console.log("AAA_NEW_FONTS_LOADING");

        try {
          const res = await axios.get(`${URL}/font/get_new_font.php`);
          console.log("AAA_NEW_FONTS", res.data);
          setFonts(res.data);
        } catch(error) {
    
        }
    }

    const validateUser = async() => {
        if(localStorage.getItem('auth') == 1) {
            const bodyFormData = new FormData();
            bodyFormData.append('UserId', localStorage.getItem('id'));
            const res = await axios.post(`${URL}/login/validate_user.php`, bodyFormData);
        
            if(res.data.Success) {
                const premium = res.data.Premium;
                const userType = res.data.UserType;

                const originalPremium = localStorage.getItem('premium');
                localStorage.setItem('premium', premium);
                localStorage.setItem('type', userType);
                localStorage.setItem('elite', res.data.Elite);

                if(userType == 0 && premium == 0) {
                    if(originalPremium == 1) {
                        if (window.confirm("Your subscription is not active now. Please resubscribe.")) {
                            window.open('/payment', '_blank');
                        }
                    }
                }
            }
        }
    }
    
    const addClick = (linkId) => {
        const bodyFormData = new FormData();
        bodyFormData.append('LinkId', linkId);
        try {
            axios.post(`${URL}/link/add_click.php`, bodyFormData);
        } catch(error) {
          
        }
    }

    return (
        <Layout>
			<div role="main" className="main">
				<div className="slider-container slider-container-full-height rev_slider_wrapper">
                    <div>
                        <img 
                            id="middle-log" 
                            alt="EZ" 
                            width="30" 
                            height="30"
                            src="/assets/img/white-logo.svg" 
                            className="font-weight-bold mb-0 appear-animation primary-color font-primary-bold img-logo" 
                            data-appear-animation="fadeInUpShorter" 
                            data-appear-animation-delay="900" />
                        <div 
                            className="text-color-light font-primary font-weight-bold font-primary-bold appear-animation welcome" 
                            data-appear-animation="fadeInUpShorter"
                            data-appear-animation-delay="600">
                            Welcome to <br /> Crafters Pro
                        </div>
                        <div 
                            className="text-color-light font-primary-regular appear-animation list"
                            data-appear-animation="fadeInUpShorter"
                            data-appear-animation-delay="1300"
                        >
                            Projects, Fonts, Stickers, Tutorials & More!
                        </div>
                        { premium != 1 &&               
                            <>
                                <a
                                    className="btn btn-primary btn-primary-override font-primary-regular text-color-light appear-animation button"
                                    data-appear-animation="fadeInDown"
                                    data-appear-animation-delay="1200"
                                    onClick={handlePurchase}>
                                    Start 3 Day Free Trial(Then $29.99/year)
                                </a>
                                <div 
                                    className="text-color-light font-primary font-primary-bold text-underline appear-animation login"
                                    data-appear-animation="fadeInUpShorter"
                                    data-appear-animation-delay="1350"
                                    onClick={dispatchLogin}
                                >
                                    Already have an account? Login Here
                                </div>
                            </>
                        }                        
                    </div>

					{/* <div id="revolutionSlider" className="slider rev_slider" data-version="5.4.7" data-plugin-revolution-slider data-plugin-options="{'delay': 0, 'sliderLayout': 'fullscreen', 'gridwidth': [1140,960,720,540], 'gridheight': [870,870,870,870], 'disableProgressBar': 'on', 'responsiveLevels': [4096,1200,992,576], 'navigation' : {'arrows': { 'enable': false, 'style': 'slider-arrows-style-1 slider-arrows-dark' }, 'bullets': {'enable': false, 'style': 'bullets-style-1', 'h_align': 'center', 'v_align': 'bottom', 'space': 7, 'v_offset': 35, 'h_offset': 0}}}">
						<ul>
                            <li data-transition="slidevertical">
                                <img src="https://static.wixstatic.com/media/f1f906_43a77f91d9f14b0dacc39d0bc1ceb28f~mv2.png/v1/fill/w_1414,h_1001,al_c,q_90,usm_0.66_1.00_0.01/f1f906_43a77f91d9f14b0dacc39d0bc1ceb28f~mv2.webp"  
									alt=""
									data-bgposition="center center" 
									data-bgfit="cover" 
									data-bgrepeat="no-repeat" 
									className="rev-slidebg" />

								<div 
                                    className="tp-caption text-color-light font-primary font-weight-bold"
									data-x="center"
									data-y="center" data-voffset="['-140','-140','-140','-140']"
									data-start="18"
									data-transform_in="y:[100%];opacity:0;s:500;"
									data-transform_out="y:[100%];opacity:0;s:500;"
									data-mask_in="x:0px;y:0px;"
                                >
                                    <img id="middle-log" alt="EZ" width="30" height="30" src="/assets/img/white-logo.svg" />
                                </div>

								<div className="tp-caption text-color-light font-primary font-weight-bold font-primary-bold"
									data-x="center"
									data-y="center" data-voffset="['-30','-30','-30','-30']"
									data-start="1000"
									data-lineheight="['75','75','75','52']"
									data-transform_in="y:[100%];opacity:0;s:500;"
									data-transform_out="y:[100%];opacity:0;s:500;"
									data-mask_in="x:0px;y:0px;"
                                    style={{ fontSize: 60 }}
                                >Welcome to <br /> Crafters Pro</div>

								<div className="tp-caption text-color-light font-primary-regular"
									data-x="center"
									data-y="center" data-voffset="['50','50','50','50']"
									data-start="1300"
									data-lineheight="['33','33','33','33']"
									data-transform_in="y:[100%];opacity:0;s:500;"
									data-transform_out="y:[100%];opacity:0;s:500;"
									data-mask_in="x:0px;y:0px;"
                                    style={{ fontSize: 17 }}
                                >Projects, Fonts, Stickers, Tutorials & More!</div>

                                { premium != 1 &&               
                                <>
                                <a className="tp-caption btn btn-primary btn-primary-override font-primary-regular text-color-light"
									data-x="center" 
									data-y="center" data-voffset="['120','120','120','120']"
									data-start="1600"
									data-whitespace="nowrap"
									data-fontsize="['16','16','16','16']"
									data-paddingtop="['13','14','14','14']"
									data-paddingbottom="['13','13','13','16']"
									data-paddingleft="['65','65','65','65']"
									data-paddingright="['65','65','65','65']"	 
									data-transform_in="y:[-50%];opacity:0;s:500;"
									data-transform_out="y:[50%];opacity:0;s:500;" onClick={handlePurchase}>Start 3 Day Free Trial(Then $29.99/year)</a>

                                <a className="tp-caption text-color-light font-primary font-primary-bold text-underline"
									data-x="center"
									data-y="center" data-voffset="['160','160','160','160']"
									data-start="1300"
									data-fontsize="['15','15','15','15']"
									data-lineheight="['33','33','33','33']"
									data-transform_in="y:[100%];opacity:0;s:500;"
									data-transform_out="y:[100%];opacity:0;s:500;"
									data-mask_in="x:0px;y:0px;" onClick={dispatchLogin}>Already have an account? Login Here</a>
                                </>
                                }
							</li>
                        </ul>
					</div> */}
				</div>
				<section className="section section-content-pull-top-3 pull-top-always bg-light appear-animation" data-appear-animation="fadeInUpShorter" data-plugin-options="{'accY': 150}">
					<div className="container">
						<div className="row">
                            <h2 className="font-weight-bold text-5 center primary-color font-primary-bold fs-28">New Fonts Weekly</h2>
						</div>
                        <hr style={{ borderTop: "1px solid #9aaeb8" }} />
					</div>
				</section>
				<section className="section section-height-2 bg-light pb-0" style={{ paddingTop: 0 }}>
					<div className="container">
						<div className="row text-center">
                            {fonts.map((item, index) => (
                                <div className="col-6 col-pt-20 p-0 font-item-home" style={{fontFamily: "Font" + item.Id}} key={index}>
                                    {item.Name}
                                </div>
                            ))}

						</div>
					</div>
				</section>
				<section id="start" className="section section-height-2 bg-light pb-0">
                    <div className="container">
                        <div className="row">
                            <h2 className="font-weight-bold text-5 center primary-color font-primary-bold fs-28 mb-3">Craft by Categories</h2>
                        </div>
                        <div className="row portfolio-list">
                            <div className="col-12 col-md-3 col-lg-3 p-0 isotope-item brands">
                                <div className="portfolio-item hover-effect-3d appear-animation" data-appear-animation="fadeInUpShorter" data-plugin-options="{'accY' : -50}">
                                    <div className="image-frame image-frame-style-1 image-frame-effect-1">
                                        <div className="image-frame-wrapper">
                                            <img src="https://static.wixstatic.com/media/f1f906_8a16181613034ae9959572c59f9b80df~mv2.jpeg/v1/fill/w_354,h_471,q_90/f1f906_8a16181613034ae9959572c59f9b80df~mv2.jpeg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <h2 className="text-4 font-weight-bold mt-3 mb-0 text-center primary-highlight-color font-primary-bold">Halloween Decorations</h2>
                                    <a href="/projects"><p className="mb-4 pb-3 text-center text-underline" style={{ fontSize: 15 }}>View Halloween Crafts</p></a>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3 p-0 isotope-item brands">
                                <div className="portfolio-item hover-effect-3d appear-animation" data-appear-animation="fadeInUpShorter" data-plugin-options="{'accY' : -50}">
                                    <div className="image-frame image-frame-style-1 image-frame-effect-1">
                                        <div className="image-frame-wrapper">
                                            <img src="https://static.wixstatic.com/media/f1f906_9787ef78e1e74ddbb4e90e783923e7f8~mv2.jpeg/v1/fill/w_354,h_471,q_90/f1f906_9787ef78e1e74ddbb4e90e783923e7f8~mv2.jpeg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <h2 className="text-4 font-weight-bold mt-3 mb-0 text-center primary-highlight-color font-primary-bold">Recently Updated</h2>
                                    <a href="/projects"><p className="mb-4 pb-3 text-center text-underline" style={{ fontSize: 15 }}>View Recent Crafts</p></a>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3 p-0 isotope-item brands">
                                <div className="portfolio-item hover-effect-3d appear-animation" data-appear-animation="fadeInUpShorter" data-plugin-options="{'accY' : -50}">
                                    <div className="image-frame image-frame-style-1 image-frame-effect-1">
                                        <div className="image-frame-wrapper">
                                            <img src="/assets/img/pride.jpeg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <h2 className="text-4 font-weight-bold mt-3 mb-0 text-center primary-highlight-color font-primary-bold">Animals/Pets</h2>
                                    <a href="/projects"><p className="mb-4 pb-3 text-center text-underline" style={{ fontSize: 15 }}>View Animals/Pets Crafts</p></a>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3 p-0 isotope-item brands">
                                <div className="portfolio-item hover-effect-3d appear-animation" data-appear-animation="fadeInUpShorter" data-plugin-options="{'accY' : -50}">
                                    <div className="image-frame image-frame-style-1 image-frame-effect-1">
                                        <div className="image-frame-wrapper">
                                            <img src="https://static.wixstatic.com/media/f1f906_eebed49a3a2b4b85a227abe2e9d57cd1~mv2.jpeg/v1/fill/w_353,h_471,q_90/f1f906_eebed49a3a2b4b85a227abe2e9d57cd1~mv2.jpeg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <h2 className="text-4 font-weight-bold mt-3 mb-0 text-center primary-highlight-color font-primary-bold">Children</h2>
                                    <a href="/projects"><p className="mb-4 pb-3 text-center text-underline" style={{ fontSize: 15 }}>View Children Crafts</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section-height-4 bg-light" style={{ paddingTop: 0, paddingBottom: 30 }}>
					<div className="container">
						<div className="row text-center">
							<div className="col">
                                <img 
                                    alt="EZ" 
                                    width="101" 
                                    height="101" 
                                    src="/assets/img/black-logo.svg"
                                    className="appear-animation"
                                    data-appear-animation="fadeInDown"
                                    data-appear-animation-delay="900"
                                />
								<div className="mb-3 mt-4">
									<h2 className="font-weight-bold mb-0 appear-animation primary-color font-primary-bold" data-appear-animation="maskUp" data-appear-animation-delay="200">Be Inspired with Crafters Pro</h2>
								</div>
								<div className="mb-3">
									<h5 className="lead mb-0 appear-animation center" data-appear-animation="maskUp" data-appear-animation-delay="300" style={{ maxWidth: 546, fontWeight: 500 }}>All fonts, cuts, projects, tutorials and graphics included. Just import into your favorite software and follow our simple step by step guide.</h5>
								</div>
                                <a href={premium == 1 ? "/tutorial" : "/payment"} className="tp-caption btn btn-primary btn-primary-override font-weight-semibold" style={{ color: "#fff", padding: "14px 70px", fontSize: 16 }}>
                                    Learn More
                                </a>
							</div>
						</div>
					</div>
				</section>
                <section className="section bg-light">
                    <div className="text-center" style={{ padding: '1rem', maxWidth: 640, margin: 'auto' }}>
                        <ReactPlayer
                            className="center"
                            url={'assets/video/ad.mp4'}
                            playing={true}
                            loop={true}
                            controls={true}
                            muted={true}
                            width="100%"
                            height="100%"
                        />
                        <div className="center" style={{ maxWidth: 650 }}>
                            <div className="mb-3 mt-3">
                                <h2 className="font-weight-bold mb-0 appear-animation primary-color font-primary-bold text-uppercase" data-appear-animation="fadeInLeft" data-appear-animation-delay="200">Works with All Software</h2>
                            </div>
                            <div className="mb-3">
                                <p className="lead mb-0 appear-animation center" data-appear-animation="fadeInLeft" data-appear-animation-delay="300"  style={{ fontWeight: 500 }}>Whatever software of your choosing, we give you everything you need to craft whatever your heart desires.</p>
                            </div>
                        </div>
                    </div>
                </section>
 			</div>          
            {linkId != 0 && 
                <div>
                    <Helmet>
                    <script async data-uid="5b1ac176f7" src="https://dedicated-writer-7464.ck.page/5b1ac176f7/index.js"></script>
                    </Helmet>
                </div>}  
        </Layout>
    );
}

export default Home;