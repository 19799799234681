import React, { useMemo, useState } from "react";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import ClipLoader from "react-spinners/ClipLoader";

const useOptions = () => {
  const fontSize = 16;
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: 'Source Code Pro, monospace',
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const SplitForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  
  const handleConfirmOrder = async () => {
    if (!stripe || !elements) {
      return;
    }

    props.showLoading(true);
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)
    });
    
    props.handleConfirmOrder(payload);
  }

  return (
    <div>
       <div>
        <div className="Text-big" style={{marginTop: "36px"}}>
          Payment Information
        </div>

        <form className="form-back">
          <div>
            <label className="stripe-form-label font-primary-regular">
              Card number
              <CardNumberElement
                options={options}
                onReady={() => {
                }}
                onChange={event => {
                }}
                onBlur={() => {
                }}
                onFocus={() => {
                }}
              />
            </label>
          </div>
          
          <div>
            <label className="stripe-form-label font-primary-regular">
              Expiration date
              <CardExpiryElement
                options={options}
                onReady={() => {
                }}
                onChange={event => {
                }}
                onBlur={() => {
                }}
                onFocus={() => {
                }}
              />
            </label>
          </div>

          <div>
            <label className="stripe-form-label font-primary-regular">
              CVC
              <CardCvcElement
                options={options}
                onReady={() => {
                }}
                onChange={event => {
                }}
                onBlur={() => {
                }}
                onFocus={() => {
                }}
              />
            </label>
          </div>
        </form>

        <div className="center-horizontal" style={{marginTop: "48px"}}>
        <div>
            <ClipLoader color="#25a66a" loading={props.loading} size={24} />
        </div>
        <div>
            <a hidden={props.loading} className="tp-caption btn btn-primary btn-primary-override font-weight-semibold" style={{ color: "#fff", padding: "14px 70px", fontSize: 16 }} onClick={handleConfirmOrder}>Upgrade Now</a>
        </div>

      </div>
      </div>
    </div>
  );
};

export default SplitForm;