import Countdown from 'react-countdown';

export function SalesCountdown() {
    const TwoDigitBox = ({ value, label }) => {
        const digitOne = parseInt(value / 10);
        const digitTwo = value % 10;
        return (
            <div style={styles.salescontainer}>
                <div style={styles.digitRow}>
                    <div style={styles.box}>{digitOne}</div>
                    <div style={styles.box}>{digitTwo}</div>
                </div>
                <div style={styles.label}>
                    {label}
                </div>
            </div>
        )
    }

    const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
        return (
            <span style={styles.row}>
                <TwoDigitBox value={0} label="Days" />
                <TwoDigitBox value={hours} label="Hours" />
                <TwoDigitBox value={minutes} label="Minutes" />
                <TwoDigitBox value={seconds} label="Seconds" />
            </span>
        );
    };

      
    return (
        <Countdown
            date={Date.now() + 900 * 1000}
            renderer={countdownRenderer}
        />
    )
}

const styles={
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    digitRow: {
        display: 'flex',
        flexDirection: 'row',
        margin: 5
    },
    box: {
        width: 28,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#6dbbad',
        margin: 0.5,
        color: 'white',
        fontSize: 22,
        fontWeight: 'bold',
        borderRadius: 2,
    },
    label: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#cccccc'
    },
    salescontainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
}
