import React from 'react';
import { useDispatch } from 'react-redux';
import { showLogin } from '../redux/actions/appActions';

const Footer = () => {
    const dispatch = useDispatch();

    const dispatchLogin = () => {
        dispatch(showLogin());
    }

    return (
        <footer id="footer" className="footer-hover-links-light mt-0 bg-light" style={{ margin: "0 4rem" }}>
            <hr style={{ borderTop: "1px solid #9aaeb8" }} />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-1 col-lg-1" />

                    <div className="col-12 col-md-2 col-lg-2 text-center center-vertical logo">
                        <img alt="EZ" width="90" height="90" src="/assets/img/black-logo.svg" />
                    </div>
                    <div className="col-12 col-md-2 col-lg-2 text-center">
                        <span className="text-5 mb-5 menu-section">Membership</span>
                        <ul className="list list-icon list-unstyled">
                            <li className="mb-2"><a onClick={dispatchLogin}>Login</a></li>
                            <li className="mb-2"><a href="/payment">Signup</a></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-2 col-lg-2 text-center">
                        <span className="text-5 mb-3 menu-section">Browse</span>
                        <ul className="list list-icon list-unstyled">
                            <li className="mb-2"><a href="/projects">Projects</a></li>
                            <li className="mb-2"><a href="/fonts">Fonts</a></li>
                            <li className="mb-2"><a href="/stickers">SVG / PNG</a></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-2 col-lg-2 text-center">
                        <span className="text-5 mb-3 menu-section">Resources</span>
                        <ul className="list list-icon list-unstyled">
                            <li className="mb-2"><a href="/tutorial">How To</a></li>
                            <li className="mb-2"><a href="/faq">FAQ</a></li>
                            <li className="mb-2"><a href="/contact">Contact Us</a></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-2 col-lg-2 text-center">
                        <span className="text-5 mb-3 menu-section">Company</span>
                        <ul className="list list-icon list-unstyled">
                            <li className="mb-2"><a href="/about">About Us</a></li>
                            <li className="mb-2"><a href="/term" target="_blank">Terms and Conditions</a></li>
                            <li className="mb-2"><a href="/privacy" target="_blank">Privacy Policy</a></li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-1 col-lg-1" />
                </div>
            </div>
            <hr style={{ borderTop: "1px solid #9aaeb8" }} />
            <div className="footer-copyright bg-light">
                <div className="container">
                    <span>CraftersPro.Net 2023 All Rights Reserved</span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;