import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Layout from './Layout';
import { STRIPE_TEST_KEY_LIVEWORKSHOP } from '../config/api';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SplitFormLiveWorkshop from "./SplitFormLiveWorkshop";
import axios from 'axios';
import { URL } from '../config/api';
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import { showLoginLiveWorkshop, showLogin } from '../redux/actions/appActions';

const { useRef } = React;
const stripePromise = loadStripe(STRIPE_TEST_KEY_LIVEWORKSHOP);
const fonts = [{ cssSrc: "https://fonts.googleapis.com/css?family=Podkova:400" }]

const LiveWorkshopPayment = (props) => {
	const dispatch = useDispatch();
	const childRef = useRef();
    const userType = localStorage.getItem('type');
	const premium = localStorage.getItem('premium');
	const userEmail = localStorage.getItem('email');
	const history = useHistory();

	const dispatchLoginLiveWorkshop = () => {
        dispatch(showLoginLiveWorkshop());
    }

	const dispatchLogin = () => {
        dispatch(showLogin());
    }

    useEffect(() => {
		if (userType == 7) {
			window.location.href = "/";
		}
	})

	const handleCricutMachine = (e) => {
		switch(e.target.value) {
			case "cricut_explore_1":
				setCricutMachine("Cricut Explore 1");
				break;
			case "cricut_explore_air":
				setCricutMachine("Cricut Explore Air");
				break;
			case "cricut_explore_air_2":
				setCricutMachine("Cricut Explore Air 2");
				break;
			case "cricut_explore_air_3":
				setCricutMachine("Cricut Explore Air 3");
				break;
			case "cricut_maker_1":
				setCricutMachine("Cricut Maker 1");
				break;
			case "cricut_maker_2":
				setCricutMachine("Cricut Maker 2");
				break;
			case "cricut_maker_3":
				setCricutMachine("Cricut Maker 3");
				break;
			case "cricut_joy":
				setCricutMachine("Cricut Joy");
				break;
		}
	}

	const second = new Date();
	const third = new Date();
	second.setDate(second.getDate() + 30);
	third.setDate(third.getDate() + 60);
	const secondDay = second.toLocaleDateString('en-us', {  year:"numeric", month:"short", day:"numeric"});
	const thirdDay = third.toLocaleDateString('en-us', {  year:"numeric", month:"short", day:"numeric"});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm1, setConfirm1] = useState(false);
	const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('');
    const [confirm2, setConfirm2] = useState(false);
    const [confirm3, setConfirm3] = useState(false);
    const [cricutMachine, setCricutMachine] = useState("Cricut Explore 1");
    const [loading, setLoading] = useState(false);
    const [emailExist, setEmailExist] = useState(false);

	const onSubmitOrder = async () => {
		if(firstName == "") {
			alert("Please input first name.");
			return;
		}
		if(lastName == "") {
			alert("Please input last name.");
			return;
		}
		if(phoneNumber == "") {
			alert("Please input phone number.");
			return;
		}
		if (premium != 1) {
			if(email == "") {
				alert("Please input email.");
				return;
			}
			if(password == "") {
				alert("Please input password.")
				return;
			}
		}
		if(address == "") {
			alert("Please input address.")
			return;
		}
		if(city == "") {
			alert("Please input city.")
			return;
		}
		if(state == "") {
			alert("Please input state.")
			return;
		}
		if(zip == "") {
			alert("Please input zip code.")
			return;
		}
		if(country == "") {
			alert("Please input country.")
			return;
		}

		/*if(!confirm1 || !confirm2 || !confirm3) {
			alert("You must check all boxes to purchase!")
			return;
		}*/

		setLoading(true);

		if (!premium) {
			const bodyFormData = new FormData();
			bodyFormData.append('Email', email);		
			const res = await axios.post(`${URL}/login/validate_email.php`, bodyFormData);
			if(res.data.Exist) {
				setLoading(false);
				if (res.data.Type == 7) {
					const message = "You are already live workshop user! Please login.";
					alert(message);
					dispatchLogin();
				} else {
					const message = "The email " + email +  " is already exists. Please login and try again.";
					var result = window.confirm(message);
					if (result) {
						handleExistLogin();
					}
				}
	
				return;
			}
		}

		childRef.current.handleSubmitOrder()
	}

	const showLoading = (loading) => {
        setLoading(loading);
    }

	const handleEmailChange = async (value) => {
		setEmail(value);
		const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if (regex.test(value)) {
			const timeOutId = setTimeout(() => validateEmail(value), 500);
			return () => clearTimeout(timeOutId);
		}
	}

	const validateEmail = async(value) => {
		const bodyFormData = new FormData();
		bodyFormData.append('Email', value);		
		const res = await axios.post(`${URL}/login/validate_email.php`, bodyFormData);
		setEmailExist(res.data.Exist);
	}

	const handleExistLogin = () => {
		dispatchLoginLiveWorkshop();
	}

	const handleAutoLogin = async (email, password) => {
		if (premium == 1) {
			window.open('/projects', '_self');
			return;
		}
		
		const bodyFormData = new FormData();
		bodyFormData.append('Email', email);
		bodyFormData.append('Password', password);
  
		const res = await axios.post(`${URL}/login/login.php`, bodyFormData);
  
		if(res.data.Success) {
			localStorage.setItem('auth', 1);
			localStorage.setItem('id', res.data.Data.Id);
			localStorage.setItem('token', res.data.Data.Token);
			localStorage.setItem('email', res.data.Data.Email);
			localStorage.setItem('next_billing', res.data.Data.NextBilling);
			localStorage.setItem('premium', res.data.Data.Premium);
			localStorage.setItem('customer_id', res.data.Data.CustomerId);
			localStorage.setItem('type', res.data.Data.Type);
			localStorage.setItem('elite', res.data.Data.Elite);
  
			window.location.href = '/projects'
		} else {
			alert(res.data.Message);
		}
	}  

	const registerUser = async(paymentIntentId, customerId) => {
		const formData = new FormData();
		formData.append('PaymentIntentId', paymentIntentId);
        formData.append('CustomerId', customerId);
		formData.append('FirstName', firstName);
        formData.append('LastName', lastName);
        formData.append('Email', premium == 1 ? userEmail : email);
        formData.append('Password', password);
		formData.append('PhoneNumber', phoneNumber);
		formData.append('Address', address);
		formData.append('Adress2', address2);
		formData.append('City', city);
		formData.append('State', state);
		formData.append('Zip', zip);
		formData.append('Country', country);
		formData.append('CricutMachine', cricutMachine);

        const res = await axios.post(`${URL}/stripe/create_payment_workshop.php`, formData);
        if(res.data.Success) {
			var alertMessage = "Live Workshop payment is created successfully.";
			var result = window.confirm(alertMessage);
			if (result) {
				handleAutoLogin(email, password);
			}

			setLoading(false);
        } else {
			setLoading(false);
            alert(res.data.Message);
        }
	}

	return (
		<Layout workshopPayment>
			<div role='main' className='main'>
				<section className='workshop-section section section-height-2 bg-light section-12 workshop-top'>
					<div className='container text-center'>
						<h2 className='font-primary-bold primary-color text-align-left mb-0'>CraftersPro Live Workshop</h2>
						<div className='row'>
							<div className='col-md-7'>
								<p
									className='font-primary-bold mb-0 workshop-payment-section text-align-left'
									style={{ fontSize: 26 }}>
									Account Information
								</p>
								<form>
									{premium == 1 && <p className="primary-color font-primary-bold text-align-left">Logged in as {userEmail}</p>}
									<input className="form-input-liveworkshop font-primary-regular" type="text" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)}/>
									<input className="form-input-liveworkshop font-primary-regular" type="text" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)}/>
									{premium != 1 &&
									<><input className="form-input-liveworkshop font-primary-regular" type="email" placeholder="Email Address" onChange={(e) => handleEmailChange(e.target.value)}/>
									{emailExist && <p className="text-align-left width-max-500 red">
										It looks like that email already is a CraftersPro account. Please login or use a  new email :)
										<a	className="blue"
											onClick={() => handleExistLogin()}>
											{" login"}
										</a>
									</p>}
									<input className="form-input-liveworkshop font-primary-regular" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
									</>
									}
									<input className="form-input-liveworkshop font-primary-regular" type="text" placeholder="Phone Number" onChange={(e) => setPhoneNumber(e.target.value)}/> 
								</form>

								<p
									className='font-primary-bold mb-0 workshop-payment-section text-align-left mt-48'
									style={{ fontSize: 26 }}>
									Billing Information
								</p>
								<Elements stripe={stripePromise} fonts={{fonts}}>
									<SplitFormLiveWorkshop
										ref={childRef}
										registerUser = {registerUser}
										showLoading={showLoading}
										email={premium == 1 ? userEmail : email}
									/>
								</Elements>

								<p
									className='font-primary-bold mb-0 workshop-payment-section text-align-left mt-48'
									style={{ fontSize: 26 }}>
									Shipping Address
								</p>
								<form>
									<input className="form-input-liveworkshop font-primary-regular" type="text" placeholder="Address" onChange={(e) => setAddress(e.target.value)}/>
									<input className="form-input-liveworkshop font-primary-regular" type="text" placeholder="Address2" onChange={(e) => setAddress2(e.target.value)}/>
									<input className="form-input-liveworkshop font-primary-regular" type="text" placeholder="City" onChange={(e) => setCity(e.target.value)}/>
									<div className='d-flex'>
										<input className="form-input-liveworkshop font-primary-regular width-6" type="text" placeholder="State" onChange={(e) => setState(e.target.value)}/>
										<input className="form-input-liveworkshop font-primary-regular width-6 ml-3" type="text" placeholder="Zip" onChange={(e) => setZip(e.target.value)}/>
									</div>
									<input className="form-input-liveworkshop font-primary-regular" type="text" placeholder="Country" onChange={(e) => setCountry(e.target.value)}/> 
								</form>
							</div>
	
							<div className='col-md-5 mt-60'>
								<p
									className='font-primary-bold workshop-payment-section text-align-left price-box'
									style={{ fontSize: 18 }}>
									Total Price: $97
								</p>

								<p
									className='font-primary-bold primary-color text-align-left mt-60'
									style={{ fontSize: 21 }}>
									Select Your Cricut Machine
								</p>
								
								<form method="get">
									<div className="custom-select-1">
										<select className="form-input-liveworkshop" onChange={handleCricutMachine}>
											<option value="cricut_explore_1">Cricut Explore 1</option>
											<option value="cricut_explore_air">Cricut Explore Air</option>
											<option value="cricut_explore_air_2">Cricut Explore Air 2</option>
											<option value="cricut_explore_air_3">Cricut Explore Air 3</option>
											<option value="cricut_maker_1">Cricut Maker 1</option>
											<option value="cricut_maker_2">Cricut Maker 2</option>
											<option value="cricut_maker_3">Cricut Maker 3</option>
											<option value="cricut_joy">Cricut Joy</option>
										</select>
									</div>
								</form>

								{/* <label className="form-label-liveworkshop font-primary-regular mt-60" >
                                    <div>
                                        <input className="checkbox-liveworkshop" type="checkbox" defaultChecked={false} onChange={(e) => setConfirm1(e.target.checked)}/>
                                    </div>
                                    <div className="font-primary-regular text-align-left" style={{marginLeft: "12px", fontSize: 15}}>I confirm that all of this information is true and accurate including the machine I have.</div>
                                </label>

								<label className="form-label-liveworkshop font-primary-regular" >
                                    <div>
                                        <input className="checkbox-liveworkshop" type="checkbox" defaultChecked={false} onChange={(e) => setConfirm2(e.target.checked)}/>
                                    </div>
                                    <div className="font-primary-regular text-align-left" style={{marginLeft: "12px", fontSize: 15}}>I confirm that I have the Cricut machine I selected, grip mat, vinyl, and the original cutting blade</div>
                                </label>

								<label className="form-label-liveworkshop font-primary-regular" >
                                    <div>
                                        <input className="checkbox-liveworkshop" type="checkbox" defaultChecked={false} onChange={(e) => setConfirm3(e.target.checked)}/>
                                    </div>
                                    <div className="font-primary-regular text-align-left" style={{marginLeft: "12px", fontSize: 15}}>I understand that due to the nature of the live event, this purchase is NON REFUNDABLE</div>
                                </label> */}

								<div className="center-horizontal">
									<div>
										<ClipLoader color="#25a66a" loading={loading} size={24} />
									</div>

									<a hidden={loading}
										className='btn btn-primary btn-primary-override font-primary-regular text-color-light button fs-20 mt-20 submit-order'
										style={{ fontFamily: 'Avenir-LT-35-Light', paddingBottom: '3px', paddingTop: '10px'}}
										onClick={() => onSubmitOrder()}>
										Submit Order
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default LiveWorkshopPayment;
