import React, { useEffect, useState } from 'react';
import SearchBar from '../components/SearchBar';
import Layout from './Layout';
import { URL, DOWNLOAD_URL } from '../config/api';
import firebase from 'firebase/compat/app';
import axios from 'axios';

const About = () => {
	const [fonts, setFonts] = useState([]);
	const premium = localStorage.getItem('premium');
	const [loadedAll, setLoadedAll] = useState(false);
	const userType = localStorage.getItem('type');
	const elite = localStorage.getItem('elite');

	useEffect(() => {
		window.searchText = '';
		window.tempFonts = [];

		loadFonts(true);
		firebase.analytics().logEvent('font_page_loaded');
	}, []);

	const loadFonts = async (isInitial) => {
		try {
			const bodyFormData = new FormData();
			bodyFormData.append('Elite', userType == 6 || elite == 1 ? 1 : 0);
			bodyFormData.append('LoadAll', isInitial ? 0 : 1);
			const res = await axios.post(
				`${URL}/font/get_font_web.php`,
				bodyFormData
			);
			window.tempFonts = res.data;

			searchFonts();

			if (!isInitial) {
				setLoadedAll(true);
			}
		} catch (error) {}
	};

	function searchFonts() {
		let searchedFonts = window.tempFonts.filter((item) =>
			item.Name.toLowerCase().includes(window.searchText.toLowerCase())
		);
		setFonts(searchedFonts);
	}

	const handleSearch = (event) => {
		window.searchText = event.target.value;
		searchFonts();
	};

	const handleFontClick = (font) => {
		if (premium == 1) {
			window.open(
				`${DOWNLOAD_URL}/download.php?type=1&resource_id=${font.Id}`,
				'_blank'
			);
		} else {
			window.open('/payment', '_blank');
		}
	};

	return (
		<Layout>
			<div role='main' className='main' style={{ paddingTop: 140 }}>
				<div className='container about'>
					<div className='row mb-5'>
						<h2
							className='font-weight-bold text-9 center primary-color font-primary-bold'
							data-appear-animation='fadeInDown'
							data-appear-animation-delay='100'>
							Meet our Team
						</h2>
					</div>
					<div className='mb-5 text-center'>
						<h2
							className='appear-animation primary-color'
							data-appear-animation='fadeInUpShorter'
							data-appear-animation-delay='300'>
							A Collection of Outstanding Talent
						</h2>
						<p
							className='primary-color mt-2 mb-4'
							style={{ lineHeight: '14px' }}
							data-appear-animation='fadeInUpShorter'
							data-appear-animation-delay='400'>
							CraftersPro is fortunate to have a group of competent and caring
							professionals,
							<br /> working together to provide services to our CraftersPro
							community
						</p>
						<div
							style={{
								maxWidth: 350,
								borderBottom: 'dashed 2px #6dbbad',
								margin: 'auto',
							}}
							data-appear-animation='fadeInLeft'
							data-appear-animation-delay='500'
						/>
					</div>
					<div
						className='row'
						data-appear-animation='fadeInLeft'
						data-appear-animation-delay='800'>
						<div className='col-4'>
							<img src={'/assets/img/about_avatar_1.png'} width='100%' />
						</div>
						<div className='col-8'>
							<h2 className='font-weight-bold text-4 center primary-color font-primary-bold'>
								Kendall Dobbins
							</h2>
							<p
								className='primary-color text-1'
								style={{ lineHeight: '14px' }}>
								Ever since she was little she enjoyed make crafts and art for
								people. She has turned that passion into CraftersPro.Net and
								loves creating fonts, projects, & images for people to craft.
							</p>
							<h2 className='font-weight-bold text-2 primary-color font-primary-bold mt-4'>
								Project Creator
							</h2>
							<div className='row'>
								{/* <img
									src='https://www.crafterspro.net/cricut/stickers/1644595849.png'
									width={120}
								/>
								<img
									src='https://www.crafterspro.net/cricut/stickers/1644595849.png'
									width={120}
								/>
								<img
									src='https://www.crafterspro.net/cricut/stickers/1644595849.png'
									width={120}
								/> */}
							</div>
						</div>
					</div>
					<div
						className='row mt-4'
						data-appear-animation='fadeInLeft'
						data-appear-animation-delay='1000'>
						<div className='col-4'>
							<img src={'/assets/img/about_avatar_2.png'} width='100%' />
						</div>
						<div className='col-8'>
							<h2 className='font-weight-bold text-4 center primary-color font-primary-bold'>
								Natalija
							</h2>
							<p
								className='primary-color text-1'
								style={{ lineHeight: '14px' }}>
								Hello! My name is Natalija and I’m a full-time freelance
								designer from Serbia. I have a Bachelor’s degree in graphic
								design. With more than 9 years of experience, I now specialize
								in brand design and consider myself an enthusiast with a passion
								for visual design. I build clean, appealing, and functional
								brands that connect with customers. But that’s just a part of
								it. Design is my life. It’s something I do before going to bed,
								and something I can’t wait to do in the mornings. Without it, my
								world would be black and white.
							</p>
							<h2 className='font-weight-bold text-2 primary-color font-primary-bold  mt-4'>
								Cut File Creator
							</h2>
							<div className='row'>
								{/* <img
									src='https://www.crafterspro.net/cricut/stickers/1644595849.png'
									width={120}
								/>
								<img
									src='https://www.crafterspro.net/cricut/stickers/1644595849.png'
									width={120}
								/>
								<img
									src='https://www.crafterspro.net/cricut/stickers/1644595849.png'
									width={120}
								/> */}
							</div>
						</div>
					</div>
					<div
						className='row mt-4'
						data-appear-animation='fadeInLeft'
						data-appear-animation-delay='200'>
						<div className='col-4'>
							<img src={'/assets/img/about_avatar_3.png'} width='100%' />
						</div>
						<div className='col-8'>
							<h2 className='font-weight-bold text-4 center primary-color font-primary-bold'>
								Veronica Santos
							</h2>
							<p
								className='primary-color text-1'
								style={{ lineHeight: '14px' }}>
								Travel enthusiast. Mother to 2 boys and 2 fur babies. Loves to
								watch Kdramas. Lives to solve customer problems.
							</p>
							<h2 className='font-weight-bold text-2 primary-color font-primary-bold  mt-4'>
								Customer Service Pro
							</h2>
							<div className='row'>
								{/* <img
									src='https://www.crafterspro.net/cricut/stickers/1644595849.png'
									width={120}
								/>
								<img
									src='https://www.crafterspro.net/cricut/stickers/1644595849.png'
									width={120}
								/>
								<img
									src='https://www.crafterspro.net/cricut/stickers/1644595849.png'
									width={120}
								/> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default About;
