import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Layout from './Layout';

const EbookPurchased = () => {
	return (
		<Layout>
			<div role="main" className="main" style={{ paddingTop: 140 }}>
				<div className="container text-center" style={{marginBottom: '120px'}}>
					<img className='mt-60' alt="EZ" width="62" height="62" src="/assets/img/logo_green.png" />

					<div className="text-6 center primary-color font-primary-bold mt-48">
						Thank you for your purchase! We value you as a customer of our CraftersPro Ebook. Your Ebook is in your email inbox. Thank you so much!
					</div>

					{/* <div className="text-3 center font-primary-extra-light mt-32" style={{color: '#5f5f5f'}}>
						Details for your live workshop purchase:
					</div>

					<div className="text-6 center font-primary-extra-light" style={{color: '#5f5f5f'}}>
						Saturday May 13th at 12:00PM EST
					</div>

					<div className="text-5 center font-primary-regular" style={{marginTop: '80px'}}>
						Email us if you have any questions
					</div>

					<a className="text-5 center font-primary-bold primary-color" href="mailto:liveworkshop@crafterspro.net" style={{textDecoration: 'underline'}}>
						liveworkshop@crafterspro.net
					</a> */}
				</div>
			</div>
		</Layout>
	);
	
};

export default EbookPurchased;
