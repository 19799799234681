import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Layout from './Layout';
import SplitForm from "./SplitForm";
import { STRIPE_TEST_KEY } from '../config/api';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import firebase from "firebase/compat/app";
import { useDispatch } from 'react-redux';
import { showLogin } from '../redux/actions/appActions';
import ReactPlayer from 'react-player'
import axios from 'axios';
import { URL } from '../config/api';

const stripePromise = loadStripe(STRIPE_TEST_KEY);
const fonts = [{ cssSrc: "https://fonts.googleapis.com/css?family=Podkova:400" }]

const PaywallElite = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [receiveEmail, setReceiveEmail] = useState(true);
  
    const loggedIn = localStorage.getItem('auth');
    const premium = localStorage.getItem('premium');  
    const [paymentType, setPaymentType] = useState(0);
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeId, setPromoCodeId] = useState(0);
    const [isPromo, setIsPromo] = useState(false);
    const [discountedPrice, setDiscountedPrice] = useState(97);

    const history = useHistory();

    const dispatchLogin = () => {
        dispatch(showLogin());
    }

    const segments = [
        '25% OFF',
        '30% OFF',
        '50% OFF',
        '40% OFF',
        '35% OFF',
        '45% OFF',
        '25% OFF',
        '30% OFF'
    ]
    const segColors = [
        '#8DC63F',
        '#FFDD00',
        '#F26522',
        '#ED1C24',
        '#6F2C91',
        '#0072BC',
        '#EC3F3F',
        '#FF9000'
    ]

    useEffect(() => {
        if(premium == 1) {
            history.push('/projects');
            return;
        }
        window.scrollTo(0, 0);
        firebase.analytics().logEvent('payment_elite_page_loaded');
    }, [])
    
    const handleStart = (type) => {
        setPaymentType(type);
    
        setTimeout(function() {
            var purchaseView = document.getElementById("account-info");
            purchaseView.scrollIntoView({behavior: "smooth", block: "start"});
        }, 800);
    }

    const showLoading = (loading) => {
        setLoading(loading);
    }
 
    const handleLogin = () => {
        dispatchLogin();
    }

    const handleApply = async () => {
        const formData = new FormData();
        formData.append('Code', promoCode);
        formData.append('PageId', 1);
    
        const res = await axios.post(`${URL}/promo/validate_elite_promo_code.php`, formData);
        if(res.data.Success) {
            alert(res.data.Message);

            setIsPromo(true);
            setPromoCodeId(res.data.PromoCode.Id);
            setDiscountedPrice(Math.round(197 * (100 - res.data.PromoCode.Percent) / 100));
        } else {
            alert(res.data.Message);
        }
    }
    
    return (
        <Layout>
            <div role="main" className="main paywall">
                <div className="top-section">
                    <div className="mid-container1 mt-32">
                        <div className="row mt-12">
                            <div className="col-12 col-md-6">
                                <h1 className="color-white mb-0 fw-600 fs-34">Join CraftersELITE</h1>
                                <p className="color-white">Upgrade your normal membership to CraftersElite<br/>For even more bonus crafting content and accessories!</p>
                                <a className="tp-caption btn btn-white font-weight-semibold" style={{ color: "#000", padding: "8px 30px", fontSize: 14 }} onClick={() => handleStart(2)}>
                                    Purchase Now
                                </a>
                            </div>
                        </div>

                        <div className="row mt-12">
                            <div className="col-12 col-md-6" />
                            <div className="col-12 col-md-6">
                                <img className="w100" src="assets/img/printer.webp" />
                            </div>
                        </div>

                    </div>
                </div>

                <section className="section-7" style={{textAlign: "center"}}>
                    <div className="mid-container1 mt-32 mb-32">
                        <h1 className="mb-0 fw-700 fs-34">Whats Included in ELITE?</h1>
                        <div className="row mt-12">
                            <div className="col-12 col-md-5 mt-20">
                                <div className="elite-item">
                                    <div style={{width: "52px"}} className="d-flex-center">
                                        <img src="/assets/img/unlimited_grey.svg" style={{width: '52px', height: '25px'}} />
                                    </div>
                                    <div className="v-separator ml-20" />
                                    <div className="color-light-black fs-16 ml-32">Unlimited use of all projects</div>
                                </div>

                                <div className="elite-item">
                                    <div style={{width: "52px"}} className="d-flex-center">
                                        <img src="/assets/img/email_grey.svg" style={{width: '44px', height: '31px'}} />
                                    </div>
                                    <div className="v-separator ml-20" />
                                    <div className="color-light-black fs-16 ml-32">Speedy Support</div>
                                </div>

                                <div className="elite-item">
                                    <div style={{width: "52px"}} className="d-flex-center">
                                        <img src="/assets/img/calendar_grey.svg" style={{width: '44px', height: '44px'}} />
                                    </div>
                                    <div className="v-separator ml-20" />
                                    <div className="color-light-black fs-16 ml-32">Never pay for a subscription</div>
                                </div>

                                <div className="elite-item">
                                    <div style={{width: "52px"}} className="d-flex-center">
                                        <img src="/assets/img/pencil_grey.svg" style={{width: '40px', height: '40px'}} />
                                    </div>
                                    <div className="v-separator ml-20" />
                                    <div className="color-light-black fs-16 ml-32">Set of 12 Pen Adapter Set</div>
                                </div>

                                <div className="elite-item">
                                    <div style={{width: "52px"}} className="d-flex-center">
                                        <img src="/assets/img/lamp_grey.svg" style={{width: '26px', height: '52px'}} />
                                    </div>
                                    <div className="v-separator ml-20" />
                                    <div className="color-light-black fs-16 ml-32">Custom Elite Projects</div>
                                </div>

                                <div className="elite-item">
                                    <div style={{width: "52px"}} className="d-flex-center">
                                        <img src="/assets/img/aa_grey.svg" style={{width: '52px', height: '38px'}} />
                                    </div>
                                    <div className="v-separator ml-20" />
                                    <div className="color-light-black fs-16 ml-32">New Fonts Everyday</div>
                                </div>
                            </div>
                            <div className="col-12 col-md-7">
                                <img className="pencils" src="/assets/img/pencils.png" />
                                <ReactPlayer
                                    url="https://video.wixstatic.com/video/f1f906_ed8fe7041741430ba98df6f7407a6862/720p/mp4/file.mp4"
                                    playing={true}
                                    loop={true}
                                    controls={true}
                                    muted={true}
                                    width="100%"
                                    height="auto"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-7" style={{textAlign: "center"}}>
                    <div className="mid-container1 mt-32 mb-32">
                        <h1 className="mb-0 fw-700 fs-34">Every Project has a Video Tutorial</h1>
                        <p>Tons of Projects Ready Now!</p>
                        <div className="row mt-32">
                            <div className="col-12 col-md-5">
                                <img style={{width: '64%'}} src="/assets/img/turkey.png" />
                            </div>
                            <div className="col-12 col-md-7">
                                <ReactPlayer
                                    url="https://video.wixstatic.com/video/f1f906_eb4bff6798cc4cf2bae17d937b6f7dfb/1080p/mp4/file.mp4"
                                    playing={true}
                                    loop={true}
                                    controls={true}
                                    muted={true}
                                    width="100%"
                                    height="auto"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-6">
                    <div className="mid-container1 pt-32" style={{textAlign: "center"}}>
                        <h1 className="color-white mb-0 fw-600">New Fonts Everyday!</h1>
                        <p className="color-white">Become a Member to Receive your free fonts</p>
                         
                        <div className="row mt-12">
                            <div className="col-12 col-md-5">
                                <img className="w100" src="assets/img/elfy.png" />
                            </div>

                            <div className="col-12 col-md-7">
                                <img className="w100" src="assets/img/elfy.gif" />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex-center">
                        <img className="mb-60" src="/assets/img/aa.svg" style={{width: '52px'}} />
                    </div>
                </section>

                <section className="section-5">
                    <div className="row">
                        <div className="col-12 col-sm-4 paywall-elite-vt-back">
                            <div>
                                <img src="/assets/img/lamp.svg" 
                                className="appear-animation mt-26"
                                data-appear-animation="fadeInDown"
                                data-appear-animation-delay="800" 
                                style={{ animationDuration: '2000ms', width: '49px'}} />
                                <h5 className="color-white ls-5 mt-32">VIDEO TUTORIALS</h5>
                                <p className="color-white mt-32 mb-60">Every Project has a Video Tutorial</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 paywall-elite-fe-back">
                            <div>
                                <img src="/assets/img/aa.svg" 
                                className="appear-animation mt-60"
                                data-appear-animation="fadeInDown"
                                data-appear-animation-delay="1200" style={{ animationDuration: '2000ms', width: '87px'}}/>
                                <h5 className="color-white ls-5 mt-32" style={{marginTop: "8px"}}>FONTS EVERYDAY</h5>
                                <p className="color-white mt-32 mb-60">Enjoy a new font every single day!</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 paywall-elite-pas-back">
                            <div>
                                <img src="/assets/img/pencil.svg" 
                                className="appear-animation mt-60"
                                data-appear-animation="fadeInDown"
                                data-appear-animation-delay="1600" style={{ animationDuration: '2000ms', width: '72px'}}/>
                                <h5 className="ls-5 mt-32" style={{marginTop: "8px"}}>PEN ADAPTER SET</h5>
                                <p className="mt-20 mb-22">Simply remove the Cricut stock pen<br /> holder, then insert the pen adapter of<br /> your choice and insert the pen</p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="text-center" style={{ fontFamily: 'Poppins-Semi-Bold', fontSize: 22, marginTop: '2rem' }}>Get CraftersElite Now!</div>

                <section id="payment" className="section-2">
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <div className="trialback elite">
                            <img src="assets/img/elite_payment.png" />
                            <h3 className="ls-2" style={{ marginBottom: 0, color: '#36e4e9' }}>CRAFTERS<b>ELITE</b></h3>
                            <div style={{ lineHeight: 1, fontSize: 20, margin: 10, display: 'flex', justifyContent: 'center' }}>
                                $<span style={{ fontWeight: 700, color: '#36e4e9', fontSize: 45, marginTop: -3 }}>
                                    {isPromo && <span className="line-through">197</span>} {isPromo && discountedPrice}
                                    {!isPromo && '197'}
                                </span>
                            </div>

                            <div style={{ fontWeight: 900 }}>No Monthly Payments!</div>
                            <div>One time Fee</div>
                            <a className="tp-caption btn btn-elite font-weight-semibold"
                                style={{ color: "#fff", width: "100%", fontSize: 14, marginTop: "12px", justifyContent: "center" }}
                                onClick={() => handleStart(2)}>
                                Purchase Now
                            </a>
                            <hr style={{ marginLeft: '-2rem', marginRight: '-2rem', borderTop: '2px solid rgba(0,0,0,.1)' }} />
                            <div className="item1"><b>All Projects Contain a Custom Video Tutorial</b></div>
                            <div className="item1">New Font Every Day</div>
                            <div className="item1">12 Pen Adapters Sent to you</div>
                            <div className="item1">Unlimited use of all projects</div>
                            <div className="item1">Speedy Support</div>
                            <div className="item1">Access Forever. Never pay monthly or yearly</div>
                        </div>
                    </div>
                </section>

                <section className="section-2">
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <div className="code-box" style={{justifyContent: 'center'}}>
                            <input type="text" placeholder="Have a promo code? Add it here" value={promoCode} onChange={(e) => setPromoCode(e.target.value)} />
                        </div>
                    </div>

                    <div className="row" style={{ justifyContent: 'center' }}>
                        <a className="tp-caption btn btn-primary btn-primary-override font-weight-semibold"
                            style={{ color: "#fff", padding: "14px 70px", fontSize: 14, marginTop: "12px" }}
                            onClick={handleApply} >
                            Apply
                        </a>
                    </div>
                </section>

                <div style={{marginTop: "16px"}}>
                    <div className="BarView">
                        <div className="Bar" />
                    </div>
                </div>

                <div style={{ textAlign: 'center', marginTop: '3rem' }}>
                    <img
                        alt="EZ" 
                        width="80" 
                        height="80" 
                        src="/assets/img/black-logo.svg"
                        className="appear-animation"
                        data-appear-animation="fadeInUp"
                        data-appear-animation-delay="500"
                        style={{ animationDuration: '1000ms'}}
                    />  
                </div>

                {(paymentType == 1 || paymentType == 2) &&
                    <section id="account-info" className="section-4 container" style={{marginTop: "16px"}}>
                        { loggedIn != 1 ?
                            <div>
                                <div className="center-horizontal" style={{marginBottom: "16px"}}>
                                    <div className="fs-16 font-primary-regular">
                                        <>
                                        {isPromo && <span className="line-through">$197</span>} {isPromo && ('$' + discountedPrice)}
                                        {!isPromo &&'$197'}/One time purchase
                                        </>
                                    </div>
                                </div>

                                <h2 className="font-primary-regular fs-20">
                                    Account Information
                                </h2>

                                <div className="font-primary-regular fs-12">
                                    *required
                                </div>

                                <div style={{float: 'right', marginTop: '-18px'}} className="fs-12">
                                    <a className="font-primary-regular" style={{color:"#747474"}} href="/payment">Back</a>
                                </div>

                                <form className="form-back">
                                    <label className="stripe-form-label font-primary-regular">
                                    *Email Address
                                    <input className="form-input font-primary-regular" type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)}/> 
                                    </label>

                                    <label className="stripe-form-label font-primary-regular">
                                    *Password
                                    <input className="form-input font-primary-regular" type="password" placeholder="Enter password" onChange={(e) => setPassword(e.target.value)}/> 
                                    </label>
                                    
                                    
                                    <label className="stripe-form-label font-primary-regular">
                                    *Confirm Password
                                    <input className="form-input font-primary-regular" type="password" placeholder="Enter confirm password" onChange={(e) => setConfirmPassword(e.target.value)}/> 
                                    </label>

                                    <label className="form-label font-primary-regular" >
                                    <div>
                                        <input className="FormInput-check" type="checkbox" defaultChecked={true} onChange={(e) => setReceiveEmail(e.target.checked)}/>
                                    </div>
                                    <div className="font-primary-regular" style={{marginLeft: "12px"}}>I agree to receive instructional and promotional emails </div>
                                    </label>
                                </form>
                            </div> : null
                        }
                            
                        <Elements stripe={stripePromise} fonts={{fonts}}>
                            <SplitForm
                                isPromo={isPromo}
                                promoCodeId={promoCodeId}
                                email={email}
                                password={password}
                                confirmPassword={confirmPassword}
                                receiveEmail={receiveEmail}
                                showLoading={showLoading}
                                loading={loading}
                                paymentType={paymentType}
                                paywallType={4} />
                        </Elements>

                        { loggedIn != 1 ?                 
                        <div className="center-horizontal" style={{marginTop: "24px"}}>
                            <a className="tp-caption btn font-weight-semibold" style={{ color: "#707070", backgroundColor: "#ffffffff", padding: "10px 40px", fontSize: 16 }} onClick={handleLogin}>
                                Login
                            </a>
                        </div> : null
                        }
                    </section>
                }
            </div>

        </Layout>
    );
}

export default PaywallElite;