import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Col, Row, Modal } from 'antd';
import { URL, DOWNLOAD_URL } from '../config/api';
import firebase from "firebase/compat/app";
import axios from 'axios';
import Layout from './Layout';
import SearchBar from '../components/SearchBar';
import ClipboardJS from 'clipboard'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ClipLoader from "react-spinners/ClipLoader";
import PromoBox from "./common/PromoBox";

const Stickers = () => {
    const params = useParams();
    const history = useHistory();
    const premium = localStorage.getItem('premium');

    const [visible, setVisible] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [sticker, setSticker] = useState({});
    const [stickers, setStickers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [curCategory, setCurCategory] = useState({});
    const [sortBy, setSortBy] = useState(0); //0: Normal, 1: A-Z, 2: Z-A, 3: Most Popular, 4: Recently Released
    const [defaultStickerId, setDefaultStickerId] = useState(0);
    const [curPage, setCurPage] = useState(0);
    const [stickerCount, setStickerCount] = useState(0);
    const allCategory = {'Id': 0, 'Name': 'All', 'Selected': true};
    const clipboard = new ClipboardJS('.Share');
    const [loading, setLoading] = useState(false);
    const [pageNum, setPageNum] = useState(24);

    console.log('sticker: ', sticker);

    useEffect(() => {
        window.searchText = '';
        window.tempStickers = [];

        if(params.id) {
            setDefaultStickerId(params.id);
        } else {
            setDefaultStickerId(0);
        }

        loadStickers();
        firebase.analytics().logEvent('cut_files_page_loaded');
    }, []);

    useEffect(() => {
        searchStickers();
    }, [curCategory, sortBy, curPage, pageNum]);

    const loadStickers = async (stickerId) => {
        try {
            setLoading(true);
            const res = await axios.get(`${URL}/sticker/get_sticker_new.php`);
            window.tempStickers = res.data.Data;
            let tempCategories = res.data.Data1;
            for(var i = 0; i < tempCategories.length; i++) {
            let category = tempCategories[i];
            category.Selected = false;
            }
            
            tempCategories = [allCategory, ...tempCategories];
            setCategories(tempCategories);
            setCurCategory(allCategory);
            setLoading(false);
        } catch(error) {
            
        }
    }

    function searchStickers() {
        var searchedStickers;
        if(curCategory.Id == 0) {
            searchedStickers = window.tempStickers.filter(
                item => (item.ImageName.toLowerCase().includes(window.searchText.toLowerCase()) || item.Tag.toLowerCase().includes(window.searchText.toLowerCase()))
            );
            searchedStickers = searchedStickers.sort(function(a,b){return a.CategorySortIndex - b.CategorySortIndex});
        } else {
            searchedStickers = window.tempStickers.filter(item => item.ImageName.toLowerCase().includes(window.searchText.toLowerCase()) && item.CategoryId==curCategory.Id );
        }

        if(sortBy == 1) {
            searchedStickers = searchedStickers.sort(function(a,b){
                if(a.ImageName.toLowerCase() < b.ImageName.toLowerCase()) { return -1; }
                if(a.ImageName.toLowerCase() > b.ImageName.toLowerCase()) { return 1; }
                return 0;
            });
        } else if(sortBy == 2) {
            searchedStickers = searchedStickers.sort(function(a,b){
                if(a.ImageName.toLowerCase() < b.ImageName.toLowerCase()) { return 1; }
                if(a.ImageName.toLowerCase() > b.ImageName.toLowerCase()) { return -1; }
                return 0;
            });
        } else if(sortBy == 3) {
            searchedStickers = searchedStickers.sort(function(a,b){
                return b.Downloads - a.Downloads
            });
        } else if(sortBy == 4) {
            searchedStickers = searchedStickers.sort(function(a,b){
                if(a.Created < b.Created) { return 1; }
                if(a.Created > b.Created) { return -1; }
                return 0;
            });
        }
        console.log("AAA_STICKERS", searchedStickers);
        setStickerCount(searchedStickers.length);
        setStickers(searchedStickers.slice(curPage * pageNum, curPage * pageNum + pageNum));

        if(defaultStickerId != 0) {
            for(var i = 0; i < window.tempStickers.length; i++) {
                const tempSticker = window.tempStickers[i];
                if(tempSticker.Id == defaultStickerId) {
                    setSticker(tempSticker);
                    setModalVisible(true);
                    break;
                }
            }
      
            setDefaultStickerId(0);
        }
    }

    const handleCategoryClick = (item) => {
        setCurPage(0);

        let tempCategories = [...categories];
        for(var i = 0; i < tempCategories.length; i++) {
          let category = tempCategories[i];
          if(category.Id == item.Id) {
            category.Selected = true;
          } else {
            category.Selected = false;
          }
        }
    
        setCategories(tempCategories);
    
        setCurCategory(item);
        searchStickers();
    }

    const handleStickerClick = (sticker) => {
        window.open('/sticker-detail/' + sticker.Id, "_self");
    }

    const handleSortByChange = (e) => {
        switch(e.target.value) {
            case "sort_by":
                setSortBy(0);
                break;
            case "name_asc":
                setSortBy(1);
                break;
            case "name_desc":
                setSortBy(2);
                break;
            case "most_popular":
                setSortBy(3);
                break;
            case "recently_released":
                setSortBy(4);
                break;
        }
    }

    const handlePerPageChange = (e) => {
        switch(e.target.value) {
            case "per_page":
                setPageNum(24);
                break;
            case "page_24":
                setPageNum(24);
                break;
            case "page_50":
                setPageNum(50);
                break;
            case "page_100":
                setPageNum(100);
                break;
        }
    }

    const handleDownloadSticker = (sticker, type) => {
        if(premium == 1) {
            if(type == 0) { //=====PNG
                window.open(`${DOWNLOAD_URL}/download.php?type=2&resource_id=${sticker.Id}`, "_blank")
            } else { //=====SVG
                window.open(`${DOWNLOAD_URL}/download.php?type=3&resource_id=${sticker.Id}`, "_blank")
            }
        } else {
            window.open('/payment', '_blank');
        }
    }

    const handleLeftArrowClick = () => {
        if(curPage > 0) {
          setCurPage(curPage - 1);
        }
    }
    
    const handleRightArrowClick = () => {
        if(curPage < Math.ceil(stickerCount / pageNum) - 1) {
            setCurPage(curPage + 1);
        }
    }

    const handleSearch = (event) => {
        window.searchText = event.target.value;
        searchStickers();
    }
    return (
        <Layout>
            <div role="main" className="main" style={{ paddingTop: 140 }}>
                <div className="container">
                    <PromoBox />
                    <div className="row mb-5">
                        <h2 className="font-weight-bold text-9 center primary-color font-primary-bold">Cut Files</h2>
                    </div>
                    <div className="row">
                        <aside className="sidebar col-md-4 col-lg-3 order-1 order-md-1">
                            <div style={{display: 'flex'}}>
                                <span className="text-4 center font-secondary" style={{ padding: '1rem 1.5rem'}}>Filter by</span>
                                <div className="pagination">
                                    <FaChevronLeft color="#c1c6c7" onClick={() => handleLeftArrowClick()} className="page-arrow-left" />
                                    <div className="page-text">
                                        {(curPage + 1) + " / " + Math.ceil(stickerCount / pageNum)}
                                    </div>
                                    <FaChevronRight color="#c1c6c7" onClick={() => handleRightArrowClick()} className="page-arrow-right"/>
                                </div>
                            </div>
      
                            <hr />
                            <div className="accordion accordion-default accordion-toggle accordion-style-1" role="tablist">
                                <div className="card">
                                    <div className="card-header accordion-header" role="tab" id="categories">
                                        <h5 className="mb-0">
                                            <a className="collapse" data-toggle="collapse" data-target="#toggleCategories" aria-expanded="false" aria-controls="accordionDefaultSmCollapse2" style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => setVisible(!visible)}>
                                                <span>Categories</span>
                                                <img src={`/assets/img/${visible ? 'icons8-minus-14' : 'icons8-plus-math-14'}.png`} style={{ opacity: 0.4 }}/>
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="toggleCategories" className="accordion-body collapse show" role="tabpanel" aria-labelledby="categories">
                                        <div className="card-body">
                                            <ul className="list list-unstyled mb-0">
                                                {categories.map((item, index) => (
                                                    <li>
                                                        <a href="#" className={item.Selected ? 'active' : ''} onClick={() => handleCategoryClick(item)}>{item.Name}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                        <div className="col-md-8 col-lg-9 order-2 order-md-2 mb-5 mb-md-0">
                            <div className="row">
                                <div className="col-6 col-lg-3 mb-3 mb-sm-0">
                                    <div className="custom-select-1">
                                        <select className="form-control border" onChange={handlePerPageChange}>
                                            <option value="per_page">Per page</option>
                                            <option value="page_24">24</option>
                                            <option value="page_50">50</option>
                                            <option value="page_100">100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 mb-3 mb-sm-0">
                                    <div className="custom-select-1">
                                        <select className="form-control border" onChange={handleSortByChange}>
                                            <option value="sort_by">Sort by</option>
                                            <option value="name_asc">Name A-Z</option>
                                            <option value="name_desc">Name Z-A</option>
                                            <option value="most_popular">Most popular</option>
                                            <option value="recently_released">Recently released</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mb-3 mb-sm-0">
                                    <SearchBar handleSearch={handleSearch} />
                                </div>
                            </div>

                            <div className="center-horizontal">
                                <ClipLoader color="#25a66a" loading={loading} size={60} />
                            </div>

                            <div className="row">
                                {stickers.map((item, index) => (
                                    <div className="col-6 col-lg-4" key={index} oncontextmenu="return false;">
                                        <div className="item-box" onClick={() => handleStickerClick(item)} onContextMenu={(e) => e.preventDefault()}>
                                            <div className='sticker-image'>
                                                <a>
                                                    <img src={item.ImageUrl} className="img-fluid" alt="" />
                                                </a>
                                            </div>
                                            <div className="item-bottom-box">
                                                <div className="sticker-item-title">
                                                    {item.ImageName}
                                                </div>
                                                {premium != 1 && <img className="download" src="/assets/img/new.png" />}
                                            </div>
                                        </div>
                                    </div>                                    
                                ))}
                            </div>

                            <div className="pagination float-right">
                                <FaChevronLeft color="#c1c6c7" onClick={() => handleLeftArrowClick()} className="page-arrow-left" />
                                <div className="page-text">
                                    {(curPage + 1) + " / " + Math.ceil(stickerCount / 20)}
                                </div>
                                <FaChevronRight color="#c1c6c7" onClick={() => handleRightArrowClick()} className="page-arrow-right"/>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    visible={modalVisible}
                    title={<div style={{ display: 'flex', justifyContent: 'space-between' }}><span>{sticker.ImageName}</span><span>X</span></div>}
                    footer={null}
                    onCancel={() => { setModalVisible(false) }} 
                    centered
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Row style={{justifyContent: 'center'}}>
                        <img src={sticker.ImageUrl} width="180" />
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <button className="tp-caption btn btn-primary btn-primary-override font-primary-regular text-center" style={{ width: '100%', justifyContent: 'center' }} onClick={() => handleDownloadSticker(sticker, 0)}>Download PNG</button>
                        </Col>
                        <Col span={12}>
                            <button className="tp-caption btn btn-primary btn-primary-override font-primary-regular text-center" style={{ width: '100%', justifyContent: 'center' }} onClick={() => handleDownloadSticker(sticker, 1)}>Download SVG</button>
                        </Col>
                    </Row>
                    <p style={{ fontSize: 18, lineHeight: 1.5, marginTop: 10 }}>Tags: {sticker.Tag}</p>
                    <Row>
                        <Col span={5}>Share Link:</Col>
                        <Col span={16}><input id="share-link" className="share-input" value={"https://crafterspro.net/stickers/" + sticker.Id} onChange={(e) => {}} /></Col>
                        <Col span={3}><img className="Share" src="/assets/img/icons8-copy-30.png" data-clipboard-target="#share-link"/></Col>
                    </Row>
                </Modal>
            </div>
        </Layout>
    );
}

export default Stickers;