import React, { useMemo } from "react";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import axios from 'axios';
import { URL } from '../config/api';
const { forwardRef, useImperativeHandle } = React;

const useOptions = () => {
	const fontSize = 32;
	const options = useMemo(
	  () => ({
		showIcon: true,
		style: {
		  base: {
			fontSize: '17px',
			color: "#424770",
			letterSpacing: "0.025em",
			fontFamily: 'Source Code Pro, monospace',
			"::placeholder": {
			  color: "#aab7c4"
			}
		  },
		  invalid: {
			color: "#9e2146"
		  }
		}
	  }),
	  [fontSize]
	);
  
	return options;
};

const SplitFormPayment = forwardRef((props, ref) => {
  const stripe = useStripe();

  const elements = useElements();
  const options = useOptions();

  useImperativeHandle(ref, () => ({

    async handleSubmitOrder() {
		console.log("AAA_HANDLE_SUBMIT=====");
		if (!stripe || !elements) {
      		props.showLoading(false);
		  	return;
		}

        if(props.paymentType == 1) { //=====Subscription Payment
            props.showLoading(true);
            const payload = await stripe.createPaymentMethod({
              type: "card",
              card: elements.getElement(CardNumberElement)
            });
            
            props.handleConfirmOrder(payload);
        } else {
            props.showLoading(true);
        
            if(props.email == "") {
                props.showLoading(false);
                alert("Please input email.");
                return;
            }
        
            if(props.password == "") {
                props.showLoading(false);
                alert("Please input password.");
            }
        
            if(props.password != props.confirmPassword) {
                props.showLoading(false);
                alert("Please confirm password.");
                return;
            }
            
            const formData = new FormData();
            formData.append('Email', props.email);
            formData.append('ApplePay', 0);
            formData.append('LinkId', props.linkId);
            formData.append('PaywallType', 7);
            formData.append('CommercialAccess', 0);
      
            const res = await axios.post(`${URL}/stripe/create_payment_intent.php`, formData);
            if(res.data.Success) {
                const paymentIntentId = res.data.PaymentIntentId;
                const customerId = res.data.CustomerId;
        
                const payload = await stripe.confirmCardPayment(res.data.ClientSecret, {
                    setup_future_usage: 'off_session',
                    payment_method: {
                    card: elements.getElement(CardNumberElement)
                    }
                });
              
                if (payload.error) {
                    props.showLoading(false);
                    alert(payload.error.message);
                } else {
                    props.registerUser(paymentIntentId, customerId);
                }
            } else {
                alert(res.data.Message);
                props.showLoading(false);
            }
          }
	}

  }));

  return (
    <div>
		<form>
            <div>
              <CardNumberElement className='stripe-form-input-liveworkshop'
                options={options}
                onReady={() => {
                }}
                onChange={event => {
                }}
                onBlur={() => {
                }}
                onFocus={() => {
                }}
              />
                    
              <CardCvcElement className='stripe-form-input-liveworkshop'
                options={options}
                onReady={() => {
                }}
                onChange={event => {
                }}
                onBlur={() => {
                }}
                onFocus={() => {
                }}
              />

              <CardExpiryElement className='stripe-form-input-liveworkshop width-6'
                options={options}
                onReady={() => {
                }}
                onChange={event => {
                }}
                onBlur={() => {
                }}
                onFocus={() => {
                }}
              />

            </div>
          </form>
    </div>
  );
});

export default SplitFormPayment;